// Dependency
import React from "react"
import Image from "../../ui/image/image"
import cx from "classnames"
import { Link } from "gatsby"
import ReactPlayer from "react-player"

// Components
import CmsText from "../cms-text/cms-text"
import CmsButton from "../cms-button/cms-button"
import SvgIcon from "../../../hooks/svg"

// Hooks
import useMediaQuery from "../../../hooks/useMediaQuery"

// Styles
import "./cms-hero.scss"

// To be extended with storyblokprops

const CmsHero = props => {
  const heroCard = props.blok
  const isMobile = useMediaQuery(1025)
  const isMobileSmall = useMediaQuery(576)

  const renderContent = () => (
    <div
      className={cx(
        "cms-hero",
        {
          "cms-hero--padding-top": !isMobile && heroCard.paddingTop,
        },
        {
          "cms-hero--padding-bottom": heroCard.paddingBottom,
        }
      )}
    >
      <div className="cms-hero__wrapper">
        {heroCard.banner.map((card, index) => (
          <div
            key={index}
            className={cx("cms-hero__wrapper__content", {
              "cms-hero__wrapper__content--zero-spacing": heroCard.zeroSpacing,
            })}
          >
            <div className="cms-hero__wrapper__content__wrapper">
              <div
                className={cx("cms-hero__wrapper__content__image", {
                  "cms-hero__wrapper__content__image__4x5":
                    heroCard.is4x5AspectMobile && isMobileSmall,
                })}
              >
                {card.desktopVideo && !isMobile ? (
                  <ReactPlayer
                    url={card.desktopVideo}
                    playsinline={true}
                    width="100%"
                    height="100%"
                    muted={true}
                    loop={true}
                    controls={false}
                    className="background-video"
                    playing
                  />
                ) : card.mobileVideo && isMobile ? (
                  <ReactPlayer
                    url={card.mobileVideo}
                    playsinline={true}
                    width="100%"
                    height="100%"
                    muted={true}
                    loop={true}
                    controls={false}
                    className="background-video"
                    playing
                  />
                ) : (
                  <Image
                    largeScreenImage={
                      card.desktopImage && card.desktopImage.filename
                    }
                    smallScreenImage={
                      card.mobileImage && card.mobileImage.filename
                    }
                    alt={card.mobileImage && card.mobileImage.alt}
                    layout={"fullWidth"}
                    loading="eager"
                  />
                )}
              </div>
              <div className="cms-hero__wrapper__content__inner">
                <div className="cms-hero__wrapper__content__inner__container">
                  <div
                    className={cx(
                      "cms-hero__wrapper__content__inner__wrapper",
                      {
                        "cms-hero__wrapper__content__inner__wrapper--center--desktop":
                          card.desktopPositionVertical === "center",
                      },
                      {
                        "cms-hero__wrapper__content__inner__wrapper--top--desktop":
                          card.desktopPositionVertical === "top",
                      },
                      {
                        "cms-hero__wrapper__content__inner__wrapper--bottom--desktop":
                          card.desktopPositionVertical === "bottom",
                      },
                      {
                        "cms-hero__wrapper__content__inner__wrapper--center":
                          card.mobilePositionVertical === "center",
                      },
                      {
                        "cms-hero__wrapper__content__inner__wrapper--top":
                          card.mobilePositionVertical === "top",
                      },
                      {
                        "cms-hero__wrapper__content__inner__wrapper--bottom":
                          card.mobilePositionVertical === "bottom",
                      },
                      {
                        "cms-hero__wrapper__content__inner__wrapper--left--desktop":
                          card.desktopPositionHorizontal === "left",
                      },
                      {
                        "cms-hero__wrapper__content__inner__wrapper--center-h--desktop":
                          card.desktopPositionHorizontal === "center",
                      },
                      {
                        "cms-hero__wrapper__content__inner__wrapper--right--desktop":
                          card.desktopPositionHorizontal === "right",
                      },
                      {
                        "cms-hero__wrapper__content__inner__wrapper--left":
                          card.mobilePositionHorizontal === "left",
                      },
                      {
                        "cms-hero__wrapper__content__inner__wrapper--center-h":
                          card.mobilePositionHorizontal === "center",
                      },
                      {
                        "cms-hero__wrapper__content__inner__wrapper--right":
                          card.mobilePositionHorizontal === "right",
                      },
                      {
                        "cms-hero__wrapper__content__inner__wrapper--blue":
                          card.textBackground === "blue",
                      },
                      {
                        "cms-hero__wrapper__content__inner__wrapper--white":
                          card.textBackground === "white",
                      },
                      {
                        "cms-hero__wrapper__content__inner__wrapper--red":
                          card.textBackground === "red",
                      },
                      {
                        "cms-hero__wrapper__content__inner__wrapper--black":
                          card.textBackground === "black",
                      },
                      {
                        "cms-hero__wrapper__content__inner__wrapper--turquoise":
                          card.textBackground === "turquoise",
                      }
                    )}
                  >
                    <div className="cms-hero__wrapper__content__inner--title">
                      <div
                        className={cx({
                          "cms-hero__wrapper__content__inner--title--restricted-width":
                            heroCard.restrictedTextWidth,
                        })}
                      >
                        {isMobile && card.mobileContent?.length > 0
                          ? card.mobileContent.map((text, index) => (
                              <CmsText
                                key={index}
                                hex={text.colorHex ? text.colorHex : ""}
                                color={text.color ? text.color : "white"}
                                text={text.text}
                                size={text.size}
                                tag={text.tag ? text.tag : "p"}
                                fontWeight={text.fontWeight}
                                lineHeight={text.lineHeight}
                              />
                            ))
                          : card.content?.map((text, index) => (
                              <CmsText
                                key={index}
                                hex={text.colorHex ? text.colorHex : ""}
                                color={text.color ? text.color : "white"}
                                text={text.text}
                                size={text.size}
                                tag={text.tag ? text.tag : "p"}
                                fontWeight={text.fontWeight}
                                lineHeight={text.lineHeight}
                              />
                            ))}
                      </div>

                      <div className="cms-hero__wrapper__content__inner__row">
                        <div className="cms-hero__wrapper__content__inner__buttons">
                          {card.buttons?.map((link, index) => (
                            <CmsButton
                              url={link.url}
                              text={link.text}
                              type={link.type}
                              key={index}
                              size={link.size ? link.size : ""}
                              background={link.background}
                              backgroundHex={
                                link.backgroundHex ? link.backgroundHex : ""
                              }
                              colorHex={link.colorHex ? link.colorHex : ""}
                            />
                          ))}
                        </div>

                        {card.buttons?.map((link, index) => (
                          <div key={index}>
                            {link.type === "link_button" && (
                              <SvgIcon
                                className="cms-hero__wrapper__content__arrow"
                                name="arrow_forward_ios"
                                height="16"
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {card.link ? (
                    <div className="cms-hero__wrapper__content__inner__absolute-link">
                      <Link to={card.link}>{card.link}</Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <>
      {!heroCard.fullWidth ? (
        <div className="grid-container">
          <div className="grid__row">
            <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
              {renderContent()}
            </div>
          </div>
        </div>
      ) : (
        renderContent()
      )}
    </>
  )
}

export default CmsHero
