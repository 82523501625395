//Dependencies
import React from "react"
import Table from "../../general/table/table"
import Image from "../../ui/image/image"

//Styles
import "./image-table.scss"

//Hooks

const ImageTable = props => {
  const image = props.blok.content.find(i => i.component === "image")
  const table = props.blok.content.find(i => i.component === "table")

  return (
    <section className="image-table">
      <div className="grid-container">
        <div className="grid__row">
          <div className="grid__col-xlarge-6 grid__col-large-6 grid__col-medium-9 grid__col-small-2">
            <div className="image-table__image">
              <Image
                largeScreenImage={image.largeScreenImage.filename}
                largeScreenHeight={420}
                largeScreenWidth={630}
                smallScreenImage={image.smallScreenImage.filename}
                smallScreenHeight={500}
                smallScreenWidth={900}
                alt={image.largeScreenImage.alt}
                layout={"constrained"}
              />
            </div>
          </div>
          <div className="grid__col-xlarge-6 grid__col-large-6 grid__col-medium-9 grid__col-small-2">
            <Table props={table} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ImageTable
