import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Mousewheel, Scrollbar, Navigation } from "swiper"
import SvgIcon from "../../../hooks/svg"
import CmsText from "../../cms/cms-text/cms-text"
import useMediaQuery from "../../../hooks/useMediaQuery"

//Styles
import "swiper/css"
import "swiper/css/mousewheel"
import "swiper/css/scrollbar"
import "swiper/css/navigation"
import "./swiper-carousel-variation-two.scss"
import cx from "classnames"

//Components
import Image from "../../ui/image/image"
import ProductCard from "../../product/product-card/product-card"

SwiperCore.use([Mousewheel, Scrollbar])

const SwiperCarouselVarationTwo = props => {
  const isMobile = useMediaQuery(576)
  const data = useStaticQuery(graphql`
    query {
      brink {
        searchProducts(query: "{\"size\":1000,\"query\":{\"query_string\":{\"query\":\"type:productVariant\"}}}") {
          products {
            id
            name
            category
            active
            archived
            imageUrl
            price {
              amount
              currencyUnit
            }
            discount {
              amount
              currencyUnit
            }
            relatedProduct {
              id
            }
            relatedVariants {
              id
            }
          }
        }
      }
    }
  `)

  const brinkProducts = data.brink.searchProducts.products.filter(
    p => p.active && !p.archived
  )

  const componentProps = props.blok ? props.blok : props.prop
  const headingWithCaptionImage = props.blok.sections.find(
    i => i.component === "heading-with-caption-image"
  )

  const productCard = componentProps.sections.find(
    c => c.component === "product-list"
  )

  const sections = componentProps.sections.filter(
    c => c.component === "slide" || c.component === "product-list-column"
  )
  const products =
    productCard && productCard.products && productCard.products.length

  return (
    (sections.length !== 0 || !!products) && (
      <section
        className={cx(
          "swiper-carousel-variation-two",
          {
            "padding-top":  componentProps.paddingTop,
          },
          {
            "padding-bottom": componentProps.paddingBottom,
          }
        )}
      >
        <div className="grid-container">
          <div className="grid__row">
            <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
              <div className="swiper-carousel-variation-two__heading--icons">
                <div
                  className={`swiper-carousel-variation-two__swiper--arrows swiper-carousel-variation-two-prev prev-${componentProps._uid}`}
                >
                  <SvgIcon name="small-simple-arrow-left" height="80" />
                </div>
                <div
                  className={`swiper-carousel-variation-two__swiper--arrows swiper-carousel-variation-two-next next-${componentProps._uid}`}
                >
                  <SvgIcon name="small-simple-arrow-right" height="80" />
                </div>
              </div>
              <div
                className={cx("swiper-carousel-variation-two__heading", {
                  "swiper-carousel-variation-two__heading-centered":
                    !isMobile && headingWithCaptionImage.centerHeadline,
                })}
              >
                {componentProps.heading &&
                  componentProps.heading.map((text, index) => (
                    <CmsText
                      key={index}
                      color={text.color ? text.color : "black"}
                      text={text.text}
                      size={text.size}
                      tag={text.tag ? text.tag : "p"}
                      fontWeight={text.fontWeight}
                    />
                  ))}
                {componentProps.link &&
                  componentProps.link.map((item, index) => (
                    <a
                      key={index}
                      href={item.link.cached_url}
                      className="swiper-carousel-variation-two__heading__link"
                    >
                      {item.linkText}
                    </a>
                  ))}
              </div>
              <Swiper
                className={cx("swiper-carousel-variation-two__swiper", {
                  "swiper-carousel-variation-two__swiper--more-slides":
                    componentProps.fitMoreSlides,
                })}
                spaceBetween={16}
                direction={"horizontal"}
                loop={false}
                navigation={{
                  nextEl: `.next-${componentProps._uid}`,
                  prevEl: `.prev-${componentProps._uid}`,
                }}
                modules={[Navigation]}
                mousewheel={{
                  releaseOnEdges: true,
                  forceToAxis: true,
                }}
                breakpoints={{
                  900: {
                    spaceBetween: 16,
                    slidesPerView: componentProps.fitMoreSlides ? 3 : "auto",
                    slidesPerGroup: 1,
                  },
                  0: {
                    spaceBetween: 8,
                    slidesPerView: componentProps.fitMoreSlides ? 1.1 : "auto",
                    slidesPerGroup: 1,
                  },
                }}
                slidesPerView={componentProps.fitMoreSlides ? 1 : "auto"}
                slidesPerGroup={1}
                touchReleaseOnEdges={true}
                grabCursor={true}
                scrollbar={{
                  hide: false,
                  dragSize: "auto",
                  draggable: true,
                }}
              >
                {componentProps.sections
                  .filter(
                    c =>
                      c.component === "slide" ||
                      c.component === "product-list-column"
                  )
                  .map((slide, index) => (
                    <SwiperSlide
                      className="swiper-carousel-variation-two__swiper-slide"
                      key={index}
                    >
                      <div className="swiper-carousel-variation-two__swiper-slide__image image__wrapper">
                        <Image
                          largeScreenImage={slide.image.filename}
                          largeScreenHeight={"auto"}
                          largeScreenWidth={"auto"}
                          smallScreenImage={slide.image.filename}
                          smallScreenHeight={"auto"}
                          smallScreenWidth={"auto"}
                          alt={slide.image.alt}
                          layout={"constrained"}
                        />
                      </div>
                      <div className="swiper-carousel-variation-two__swiper-text__container">
                        <div className="swiper-carousel-variation-two__swiper-title">
                          <p className="swiper-carousel-variation-two__swiper-title-text">
                            {slide.title}
                          </p>
                        </div>
                        <div className="swiper-carousel-variation-two__swiper-text__description">
                          {slide.description}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                {productCard &&
                  productCard.products &&
                  productCard.products.map((product, index) => {
                    const sku = product.content?.sku ?? 0
                    const brinkProduct =
                      brinkProducts.find(brinkProd => brinkProd.id === sku) ||
                      null
                    return (
                      <SwiperSlide
                        className="swiper-carousel-variation-three__swiper-slide"
                        key={index}
                      >
                        <ProductCard
                          props={product}
                          brinkProduct={brinkProduct}
                          bgColor={props.blok.bgColor}
                        />
                      </SwiperSlide>
                    )
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    )
  )
}

export default SwiperCarouselVarationTwo
