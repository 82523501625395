//Dependencies
import React from "react"
import cx from "classnames"

//Styles
import "./logos-banner.scss"

//Hooks
import Image from "../../ui/image/image"

const LogosBanner = props => {
  const componentProps = props.blok ? props.blok : props.props

  return (
    <section

      className={cx(
        "logos-banner",
        {
          "logos-banner--padding-top": componentProps.paddingTop,
        },
        {
          "logos-banner--padding-bottom": componentProps.paddingBottom,
        }
      )
      }>
      <div className="grid-container">
        <div className="grid__row">
          <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
            <div className="logos-banner__inner">
              <div className="logos-banner__image">
                {componentProps.sections.map((logo, index) => (
                  <div className="logos-banner__image-logo " key={index}>
                    <Image
                      largeScreenImage={logo.largeScreenImage.filename}
                      largeScreenHeight={24}
                      smallScreenImage={logo.largeScreenImage.filename}
                      smallScreenHeight={24}
                      alt={logo.largeScreenImage.alt}
                      layout={"constrained"}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LogosBanner
