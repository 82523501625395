//Dependencies
import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Mousewheel, Scrollbar, Navigation } from "swiper"
import cx from "classnames"

//Styles
import "./swiper-carousel-variation-one.scss"
import "swiper/css"
import "swiper/css/mousewheel"
import "swiper/css/scrollbar"

//Components
import Image from "../../ui/image/image"
import ImageTitleLink from "../image-title-link/image-title-link"
import UpsellCard from "../../cart/upsell-card/upsell-card"
import RichTextResolver from "../../../helpers/storyblok/richTextResolver"
import RenderLink from "../../ui/link/link"
import CmsText from "../../cms/cms-text/cms-text"
import SvgIcon from "../../../hooks/svg"
import useMediaQuery from "../../../hooks/useMediaQuery"

SwiperCore.use([Mousewheel, Scrollbar])

const SwiperCarouselVarationOne = props => {
  const isMobile = useMediaQuery(576)
  const componentProps = props.blok ? props.blok : props.props

  const slide = componentProps.carouselSlides.filter(
    c => c.component === "slide"
  )

  const imageTitleLink = componentProps.carouselSlides.filter(
    c => c.component === "image-title-link"
  )

  const upsellCard = componentProps.carouselSlides.filter(
    c => c.component === "upsell-card"
  )

  const renderContent = () => {
    return (
      <>
        {/* <div className="swiper-carousel-variation-one__heading--icons">
          <div
            className={`swiper-carousel-variation-one__swiper--arrows swiper-carousel-variation-one-prev prev prev-${componentProps._uid}`}
          >
            <SvgIcon name="arrow_backward_ios" height={40} />
          </div>
          <div
            className={`swiper-carousel-variation-one__swiper--arrows swiper-carousel-variation-one-next next-${componentProps._uid}`}
          >
            <SvgIcon name="arrow_forward_ios" height={40} />
          </div>
        </div> */}
        <Swiper
          className="swiper-carousel-variation-one__swiper"
          autoHeight={true}
          direction="horizontal"
          loop={false}
          modules={[Navigation]}
          spaceBetween={12}
          mousewheel={{
            releaseOnEdges: true,
            forceToAxis: true,
          }}
          breakpoints={{
            900: {
              spaceBetween: 12,
              slidesPerView: 3,
              slidesPerGroup: 1,
            },
            0: {
              slidesPerView: componentProps.fitMoreSlides ? 1.1 : 1,
              slidesPerGroup: 1,
            },
          }}
          navigation={{
            nextEl: `.next-${componentProps._uid}`,
            prevEl: `.prev-${componentProps._uid}`,
          }}
          slidesPerView={"auto"}
          slidesPerGroup={1}
          touchReleaseOnEdges={true}
          grabCursor={true}
          scrollbar={{
            hide: false,
            dragSize: "auto",
            draggable: true,
          }}
        >
          {slide &&
            slide.map((slides, index) => (
              <SwiperSlide
                className="swiper-carousel-variation-one__swiper-slide"
                key={index}
              >
                <div className="swiper-carousel-variation-one__swiper-slide__image image__wrapper">
                  <Image
                    largeScreenImage={slides.image.filename}
                    smallScreenImage={slides.image.filename}
                    alt={slides.image.alt}
                    //layout={"constrained"}
                    layout={"fullWidth"}
                  />
                </div>
                <div className="swiper-carousel-variation-one__swiper-text__container">
                  <div className="swiper-carousel-variation-one__swiper-title">
                    {slides.index !== "" && (
                      <div className="swiper-carousel-variation-one__swiper-title-index__container">
                        <p className="swiper-carousel-variation-one__swiper-title-index">
                          0{slides.index}
                        </p>
                      </div>
                    )}
                    <p className="swiper-carousel-variation-one__swiper-title-text">
                      {slides.title}
                    </p>
                  </div>
                  <div>
                    <RichTextResolver
                      data={slides.content}
                      styleClass="swiper-carousel-variation-one__swiper__content"
                    />
                  </div>
                  <div className="swiper-carousel-variation-one__swiper-text__description">
                    {slides.description}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          {imageTitleLink &&
            imageTitleLink.map((link, index) => (
              <SwiperSlide
                className="swiper-carousel-variation-one__swiper-slide"
                key={index}
              >
                <ImageTitleLink props={link} />
              </SwiperSlide>
            ))}
          {upsellCard &&
            upsellCard.map((card, index) => (
              <SwiperSlide
                className="swiper-carousel-variation-one__swiper-slide"
                key={index}
              >
                <UpsellCard props={card} />
              </SwiperSlide>
            ))}
        </Swiper>
        {componentProps.button && componentProps.buttonText && (
          <div className="swiper-carousel-variation-one__button">
            <RenderLink
              linkText={componentProps.buttonText}
              linkUrl={`${componentProps.button.url}`}
              buttonLook="black"
            />
          </div>
        )}
      </>
    )
  }

  return (
    <section
      className={cx(
        "swiper-carousel-variation-one",
        {
          "padding-top": componentProps.paddingTop,
        },
        {
          "padding-bottom": componentProps.paddingBottom,
        }
      )}
    >
      <div className="grid-container">
        <div className="grid__row">
          <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
            {componentProps.heading && (
              <div
                className={cx("swiper-carousel-variation-one__heading", {
                  "swiper-carousel-variation-one__heading--centered":
                    componentProps.centeredTitle,
                })}
              >
                {componentProps.heading.map((text, index) => (
                  <CmsText
                    key={index}
                    color={text.color ? text.color : "black"}
                    text={text.text}
                    size={text.size}
                    tag={text.tag ? text.tag : "p"}
                    fontWeight={text.fontWeight}
                  />
                ))}
                {componentProps.link &&
                  componentProps.link.map((item, index) => (
                    <a
                      key={index}
                      href={item.link.cached_url}
                      className="swiper-carousel-variation-one__heading__link"
                    >
                      {item.linkText}
                    </a>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {!componentProps.fullWidth && !componentProps.noGrid ? (
        <div className="grid-container">
          <div className="grid__row">
            <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
              {renderContent()}
            </div>
          </div>
        </div>
      ) : (
        renderContent()
      )}
    </section>
  )
}

export default SwiperCarouselVarationOne
