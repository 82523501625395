// Dependency
import React from "react"
import RichTextResolver from "../../../../helpers/storyblok/richTextResolver"
import SvgIcon from "../../../../hooks/svg"

import Image from "../../../ui/image/image"

// Styles
import "./footer-text-social-media.scss"
import CmsText from "../../../cms/cms-text/cms-text"
import useMediaQuery from "../../../../hooks/useMediaQuery"

// Template
const FooterTextSocialMedia = props => {
  const isTabletOrMobile = useMediaQuery(1024)

  return (
    <div className="footer-text-social-media">
      <div className="footer-text-social-media__about_us">
        <div className="footer-text-social-media__about_us__heading">
          {props.aboutUs?.heading?.map((eachContent, index) => {
            return (
              <CmsText
                key={index}
                text={eachContent.text}
                size={eachContent.size}
                tag={eachContent.tag ? eachContent.tag : "p"}
                fontWeight={eachContent.fontWeight}
                lineHeight={eachContent.lineHeight}
              />
            )
          })}
        </div>
        <div className="footer-text-social-media__about_us__content">
          {props.aboutUs?.content?.map((eachContent, index) => {
            return (
              <CmsText
                key={index}
                text={eachContent.text}
                size={eachContent.size}
                tag={eachContent.tag ? eachContent.tag : "p"}
                fontWeight={eachContent.fontWeight}
                lineHeight={eachContent.lineHeight}
              />
            )
          })}
        </div>
      </div>
      {isTabletOrMobile ? null : (
        <>
          <div className="footer-text-social-media__social-media">
            {props.socialMedia.sections.map((i, index) => {
              return (
                <div
                  className="footer-text-social-media__social-media-icon"
                  key={index}
                >
                  <a href={i.link} target="_blank">
                    <SvgIcon name={i.icon} />
                  </a>
                </div>
              )
            })}
          </div>
          <div className="footer-text-social-media__logos">
            {props.footerLogos.logos.map((i, index) => {
              return (
                <div
                  className="footer-text-social-media__logos-logo"
                  key={index}
                >
                  <SvgIcon name={i.logos} />
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}
export default FooterTextSocialMedia
