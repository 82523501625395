//Dependencies
import React from "react"

//Styles
import "./table.scss"

//Hooks
import SvgIcon from "../../../hooks/svg"
import RenderLink from "../../ui/link/link"
import { useTranslation } from "react-i18next"

const Table = props => {
  const componentProps = props.blok ? props.blok : props.props
  const { t } = useTranslation()
  return (
    <div className="table">
      <div className="table__container">
        {componentProps.columns.map((column, index) => (
          <div className="table__container__column" key={index}>
            <div className="table__container__column--title">
              {column.columnTitle}
            </div>
            {column.sections.map((item, index) => (
              <div className="table__container__column--content" key={index}>
                {item.text && (
                  <div className="table__container__column--content-text">
                    {item.text}
                  </div>
                )}
                {item.isCheckedIcon && (
                  <div className="table__container__column--content-icon">
                    <SvgIcon name="checkmark-filled-green" />
                  </div>
                )}
                {item.isUncheckedIcon && (
                  <div className="table__container__column--content-icon">
                    <SvgIcon name="cross-gray" />
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="table__button">
        <RenderLink
          linkText={t("product.viewAll")}
          linkUrl="/products"
          buttonLook="black"
        />
      </div>
    </div>
  )
}

export default Table
