import React from "react"
import SvgIcon from "../../../hooks/svg"

//Styles
import "./review-star.scss"

const ReviewStar = props => {
  if (!props.starAmount || Number.isNaN(props.starAmount)) return null
  const starAmount = Math.floor(props.starAmount)
  const halfStar = props.starAmount - starAmount > 0
  return (
    <div className="review-star">
      {Array(starAmount)
        .fill(0)
        .map((_s, i) => (
          <SvgIcon key={`star-${i}`} name="star_white" height={14} />
        ))}
      {halfStar ? (
        <SvgIcon key={`half-star`} name="star_white_half" height={14} />
      ) : undefined}
      {Array(5 - starAmount - (halfStar ? 1 : 0))
        .fill(0)
        .map((_s, i) => (
          <SvgIcon key={`star-${i}`} name="star_white_outline" height={14} />
        ))}
    </div>
  )
}

export default ReviewStar
