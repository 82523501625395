//Dependencies
import React from "react"
import Dinero from "dinero.js"

//Styles
import "./price.scss"
import { useBrink } from "../../../context/BrinkContext"

const market = process.env.GATSBY_BUILD_MARKET

if (market === 'se') Dinero.globalLocale = "SV"
if (market === 'dk') Dinero.globalLocale = "DE"
if (market === 'no') Dinero.globalLocale = "NO"


const Price = ({ prices, discounts }) => {
  const {
    store: { currencyUnit },
  } = useBrink()

  const discount =
    discounts?.find(d => d.currencyUnit === currencyUnit)?.amount ?? 0

  const price = prices?.find(p => p.currencyUnit === currencyUnit)?.amount ?? 0

  const getDinero = (amount, currencyUnit) => {
    const hasCents = Dinero({
      amount: amount,
      currency: currencyUnit,
    }).hasCents()

    if (hasCents) {
      if (market === 'se' || market === 'no' || market === 'dk'){
        return Dinero({
          amount: amount,
          currency: currencyUnit,
          precision: 2,
        }).toFormat("$0,0")
      }
      else{
        return Dinero({
          amount: amount,
          currency: currencyUnit,
          precision: 2,
        }).toFormat("$0,0.00")
      }
    }

    return Dinero({
      amount: amount,
      currency: currencyUnit,
    }).toFormat("$0,0")
  }

  return (
    <div className="price">
      <div className="price__container">
        {discount !== 0 && (
          <div className="price__container__has-discount">
            <span className="price__container__product-price--discounted">
              {getDinero(price - discount, currencyUnit)}
            </span>
            <span className="price__container__product-price--strikethrough">
              {getDinero(price, currencyUnit)}
            </span>
          </div>
        )}
        {discount === 0 && (
          <span className="price__container__product-price">
            {getDinero(price, currencyUnit)}
          </span>
        )}
      </div>
    </div>
  )
}

export default Price
