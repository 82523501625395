// Dependency
import React, { useState } from "react"
import Button from "../../../ui/button/button"
import Textfield from "../../form/textfield/textfield"
import {
  subscribeToNewsletter,
  getKlaviyoListIds,
} from "../../../../context/utils/events/klaviyo"
import * as events from "../../../../context/utils/events"

// Styles
import "./footer-form.scss"
import { useTranslation } from "react-i18next"

// Template
const FooterForm = props => {
  const { t } = useTranslation()
  const [email, setEmail] = useState()
  const [firstName, setFirstName] = useState()
  const [message, setMessage] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const handleSubmit = async e => {
    setMessage()
    setIsLoading(true)
    e.preventDefault()
    const listId = getKlaviyoListIds(
      process.env.GATSBY_BUILD_MARKET.toUpperCase()
    ) //this should be currentStore.countryCode
    const response = await subscribeToNewsletter(
      process.env.GATSBY_BUILD_MARKET.toUpperCase(), //this should be currentStore.countryCode
      email,
      firstName,
      listId
    )
    if (response.success) {
      events.signup({
        listId,
        status: true,
      })
      setMessage(t("newsletter.success"))
    } else if (response && response.data.is_subscribed) {
      setMessage(t("newsletter.alreadySubscribed"))
    } else {
      setMessage(t("newsletter.error"))
    }
    setIsLoading(false)
  }

  const handleChange = e => {
    setEmail(e.target.value)
    setMessage()
  }

  const handleChangeName = e => {
    setFirstName(e.target.value)
    setMessage()
  }

  return (
    <div className="footer-form">
      <div className="footer-form__heading">{props.heading}</div>
      <div className="footer-form__caption">{props.caption}</div>
      <div className="footer-form__form">
        <form onSubmit={handleSubmit}>
          <div className="footer-form__inner">
            <div className="footer-form__inner-left">
              <input
                value={firstName}
                defaultValue=""
                name="name"
                useStatic={true}
                placeholder={t("newsletter.firstName")}
                onChange={handleChangeName}
                required={true}
                type="text"
              />
            </div>
            <div className="footer-form__inner-left">
              <input
                value={email}
                defaultValue=""
                name="email"
                placeholder={t("newsletter.email")}
                useStatic={true}
                onChange={handleChange}
                required={true}
                type="email"
              />
            </div>
            <div className="footer-form__inner-right">
              <Button
                buttonText={t("button.send")}
                type="submit"
                isLoading={isLoading}
              />
            </div>
          </div>
        </form>

        {message}
      </div>
      <div className="footer-form__helper">{props.helperText}</div>
    </div>
  )
}

export default FooterForm
