//Dependencies
import React from "react"

//Styles
import "./product-award.scss"

//Hooks
import Image from "../../ui/image/image"

//Components
import TextBlockImage from "../../global/text-block-image/text-block-image"

const ProductAward = props => {
  const componentProps = props.blok.productAward.find(
    i => i.component === "image"
  )

  const textBlockImage = props.blok.productAward.find(
    i => i.component === "text-block-image"
  )

  return (
    <section className="product-award">
      <div className="grid-container">
        <div className="grid__row">
          <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
            <div className="product-award__inner">
              <div className="product-award__image-container image__wrapper">
                <div className="product-award__image">
                  <Image
                    largeScreenImage={componentProps.largeScreenImage.filename}
                    largeScreenHeight={"auto"}
                    largeScreenWidth={"auto"}
                    smallScreenImage={componentProps.smallScreenImage.filename}
                    smallScreenHeight={"auto"}
                    smallScreenWidth={"auto"}
                    alt={componentProps.largeScreenImage.alt}
                    layout={"constrained"}
                  />
                </div>
              </div>
              <div className="product-award__text-block-image">
                <TextBlockImage
                  props={textBlockImage}
                  smallWidth={350}
                  smallHeight={250}
                  largeWidth={650}
                  largeHeight={500}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductAward
