import { navigate } from "gatsby"
import React, { useContext, createContext, useState, useEffect } from "react"
import BrinkAPI from "../helpers/BrinkAPI"
import useLocalStorage from "../hooks/useLocalStorage"
import GA4 from "../services/tracking"

const defaultState = {
  cart: {
    cartItems: [],
    totalPrice: 0,
    totalPriceWithDiscount: 0,
    totalDiscountAmount: 0,
    discounts: {},
    store: {
      currencyUnit: "",
      languageCode: "",
      countryCode: "",
      taxPercentage: 0,
    },
  },
  store: {
    currencyUnit: "",
    languageCode: "",
    countryCode: "",
    taxPercentage: 0,
  },
  isCartOpen: false,
  setIsCartOpen: false,
  cartQuantity: 0,
  addToCart: false,
  getStocks: false,
  getPaymentMethods: false,
  makePayment: false,
  makeDetailsCall: false,
  cartToOrder: false,
  getShippingOptions: false,
  setShippingMethod: false,
  getOrderConfirmation: false,
}

const baseUrl = process.env.GATSBY_BRINK_API_URL

const market = process.env.GATSBY_BUILD_MARKET

const noneDomainSlugs = ["PT", "PL", "BE", "ES", "FR", "AT", "IT"]

const BrinkContext = createContext(defaultState)

const BrinkContextProvider = ({ children, staticStore, location }) => {
  const [BrinkSessionId, setBrinkSessionId] = useLocalStorage("btoken", null)
  const [userMarket, setUserMarket] = useLocalStorage("bmarket", null)
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [cookieSettingsVisible, setCookieSettingsVisible] = useState(false)
  const [store, _setStore] = useState(staticStore)

  const setStore = async (store, update = false) => {
    _setStore(store)
    setUserMarket(store)
    if (update && BrinkSessionId) {
      await addToCart({ productVariantId: null, changeStore: store })
      if (
        location.pathname === "/checkout" ||
        location.pathname === "/checkout/"
      ) {
        window.location.reload()
      }
    }
  }

  //If .com site and no market, default to Austria
  // if (market === 'COM') {
  //   if (!userMarket) {
  //     let defaultStore = {
  //       currencyUnit: "EUR",
  //       languageCode: "en",
  //       countryCode: "AT",
  //       taxPercentage: 20
  //     }
  //     setStore(defaultStore)
  //   } else {
  //     //setStore(userMarket)
  //   }
  // }
  const cartTemplate = {
    cartItems: [],
    totalPrice: 0,
    totalPriceWithDiscount: 0,
    totalDiscountAmount: 0,
    discounts: {},
    store: store,
  }
  const [cart, setCart] = useLocalStorage("BrinkCart", cartTemplate)
  const pathBase = `${location.protocol}//${location.host}`

  useEffect(() => {
    //Close Expired carts
    const closeExpiredCart = async brinkAPI => {
      //If Cart exists
      if (BrinkSessionId) {
        console.log("Cart exists")
        await brinkAPI
          .getCart({ Authorization: BrinkSessionId })
          .then(async ({ data: cartResponse }) => {
            if (cartResponse.state !== "ACTIVE") {
              console.log("Cart is not active, closing")
              //Reset Cart
              setBrinkSessionId(null)
              setCart(cartTemplate)
            } else {
              //if .COM
              if (market === "COM") {
                //Check if market is saved in LS
                let um = userMarket ? userMarket.countryCode : null

                if (cartResponse.store.countryCode !== um) {
                  console.log(
                    "Setting store and market",
                    cartResponse.store.countryCode
                  )
                  if (um) setStore(cartResponse.store)
                  setCart(cartResponse.cart)
                } else {
                  console.log("Setting stored market", userMarket.countryCode)
                  setStore(userMarket)
                }
              } else {
                if (cartResponse.store.countryCode !== store.countryCode) {
                  console.log("Switched market")
                  addToCart({ productVariantId: null })
                }
              }
              //Switch market
            }
          })
          .catch(() => console.log("failed to fetch cart"))
      } else {
        if (market === "COM") {
          console.log("no cart")
          if (userMarket) {
            console.log("Setting stored market", userMarket.countryCode)
            setStore(userMarket)
          } else {
            console.log("No stored market, checking geo location")
            const geoLocate = await getUserCountry()
            if (geoLocate) {
              console.log("Geo location found", geoLocate.countryCode)
              if (noneDomainSlugs.includes(geoLocate.countryCode)) {
                console.log(
                  "Market in list, setting market ",
                  geoLocate.countryCode
                )
                const store = {
                  currencyUnit: "EUR",
                  languageCode: "en",
                  countryCode: geoLocate.countryCode,
                }
                setStore(store)
              } else {
                console.log("Market not in list, setting market AT")
                // const store = {
                //   currencyUnit: "EUR",
                //   languageCode: "en",
                //   countryCode: "AT",
                // }
                // setStore(store)
              }
            }
          }
        }
      }
    }

    const brinkAPI = new BrinkAPI({
      baseUrl: baseUrl,
    })

    closeExpiredCart(brinkAPI)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let cartQuantity = 0
  cart &&
    cart.cartItems &&
    cart.cartItems.forEach(item => {
      if (item.type !== "shippingOption") cartQuantity += item.quantity
    })

  const closeCart = () => {
    setBrinkSessionId(null)
    setCart(cartTemplate)
    //setEuStore(null)
  }

  const getUserCountry = async () => {
    const brinkAPI = new BrinkAPI({ baseUrl: baseUrl })
    return await brinkAPI.getCountryCode()
  }

  const addToCart = async ({
    productVariantId,
    quantity,
    addons = [],
    options = [],
    openCart = false,
    shipping = false,
    token = false,
    changeStore = false,
  }) => {
    const brinkAPI = new BrinkAPI({
      baseUrl: baseUrl,
    })

    let prods = []

    if (productVariantId)
      prods.push({
        id: productVariantId,
        quantity: quantity,
        addons: addons,
        options: options,
      })

    // if (!shipping) {
    //   const chosenShipping =
    //     cart.cartItems.find(item => item.type === "shippingOption")?.id ||
    //     "6e10ab5a-66b9-498c-bd39-262a216db63b"
    //   prods = [...prods, { id: chosenShipping, quantity: 1 }]
    // }

    let cc = changeStore ? changeStore.countryCode : store.countryCode
    let lc = changeStore ? changeStore.languageCode : store.languageCode

    let action = BrinkSessionId ? "put" : "post"
    if (token) {
      setBrinkSessionId(token)
      action = "get"
    }
    try {
      const options = {
        action: action,
        headers: BrinkSessionId ? { Authorization: BrinkSessionId } : {},
        products: prods,
        store: {
          countryCode: cc,
          languageCode: lc,
        },
        token,
      }
      const response = await brinkAPI.syncCart(options)
      if (!BrinkSessionId) setBrinkSessionId(response.jwtToken)
      setCart(response.cart)
      setIsCartOpen(openCart)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  const getStocks = async ids => {
    const brinkAPI = new BrinkAPI({
      baseUrl: baseUrl,
    })

    try {
      return await brinkAPI.getStocks({ ids: ids, store: store.countryCode })
    } catch (error) {
      console.log(error)
    }
  }

  const cartToOrder = async address => {
    const brinkAPI = new BrinkAPI({
      baseUrl: baseUrl,
    })

    try {
      const responseData = await brinkAPI.cartToOrder({
        headers: { Authorization: `Bearer ${BrinkSessionId}` },
        email: address.email,
        shippingAddress: address,
        billingAddress: address,
      })
      if (responseData.error) {
        if (
          responseData.error === "Cart closed" ||
          responseData.error === "Order is already successful"
        ) {
          closeCart()
        }

        // const navigation = {
        //   "Out of stock": "/error-out-of-stock/",
        //   "Order is already successful": "/"
        // }
        console.log(responseData.error)
        // navigate(navigation[responseData.error] || "/error/", {
        //   state: {
        //     error: responseData.error
        //   }
        // })
        return null
      }
      return responseData
    } catch (error) {
      console.error(error)
      //navigate("/error/")
    }
  }

  const handleVoucher = async ({ code }) => {
    const brinkAPI = new BrinkAPI({
      baseUrl: baseUrl,
    })

    const shipping = cart.cartItems.find(i => i.type === "shippingOption")?.id

    const products = shipping ? [{ id: shipping, quantity: 1 }] : []

    try {
      const res = await brinkAPI.handleVoucher({
        headers: { Authorization: `Bearer ${BrinkSessionId}` },
        code: code,
        products: products,
      })

      setCart(res.cart)

      return res.cart.discounts.rules.filter(r => r.ruleType === "DISCOUNTCODE")
        .length
    } catch (error) {
      console.error(error)
      return "error"
    }
  }

  const cartToKlarnaOrder = async (options = {}) => {
    const brinkAPI = new BrinkAPI({
      baseUrl: baseUrl,
    })

    const merchantUrls = {
      terms: `${window.location.origin}/terms-and-conditions`,
      checkout: `${window.location.origin}/checkout/?klarnaOrderId={checkout.order.id}`,
      confirmation: `${window.location.origin}/thankyou/?klarnaOrderId={checkout.order.id}`,
    }

    const merchantData = {
      errorPage: `${window.location.origin}/error/`,
      errorOutOfStockPage: `${window.location.origin}/error-out-of-stock/`,
    }

    try {
      const responseData = await brinkAPI.cartToKlarnaOrder({
        headers: { Authorization: `Bearer ${BrinkSessionId}` },
        merchantUrls,
        merchantData: JSON.stringify(merchantData),
        options,
        shippingAddress: {},
      })
      if (responseData.error) {
        if (responseData.error === "Klarna order creation conflict") return null
        if (
          responseData.error === "Cart closed" ||
          responseData.error === "Order is already successful"
        ) {
          closeCart()
        }
        const navigation = {
          "Out of stock": "/error-out-of-stock/",
          "Order is already successful": "/",
          "Missing product": "/",
        }
        navigate(navigation[responseData.error] || "/error/")
        return null
      }
      return responseData
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const getPaymentMethods = async () => {
    const brinkAPI = new BrinkAPI({
      baseUrl: baseUrl,
    })

    try {
      return await brinkAPI.getPaymentMethods({
        headers: { Authorization: `Bearer ${BrinkSessionId}` },
        currencyUnit: cart.store.currencyUnit,
        countryCode: cart.store.countryCode,
        amount: cart.totalPriceWithDiscount,
        //shopperReference: "3b295dd0-f624-11ec-b939-0242ac120002",
      })
    } catch (error) {
      console.error(error)
      //navigate("/error/")
    }
  }

  const getShippingOptions = async () => {
    const brinkAPI = new BrinkAPI({
      baseUrl: baseUrl,
    })

    try {
      return await brinkAPI.getShippingOptions({
        headers: { Authorization: `Bearer ${BrinkSessionId}` },
        countryCode: store.countryCode,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const setShippingMethod = async option => {
    const brinkAPI = new BrinkAPI({
      baseUrl: baseUrl,
    })

    try {
      const options = {
        action: "PUT",
        headers: { Authorization: BrinkSessionId },
        products: [
          {
            id: option,
            quantity: 1,
          },
        ],
        store: {
          countryCode: store.countryCode,
          languageCode: store.languageCode,
        },
      }
      return await brinkAPI.syncCart(options)
    } catch (error) { }
  }

  const makePayment = async (
    order,
    paymentMethod,
    storePaymentMethod,
    browserInfo
  ) => {
    const brinkAPI = new BrinkAPI({
      baseUrl: baseUrl,
    })

    try {
      return await brinkAPI.makePayment({
        headers: { Authorization: `Bearer ${BrinkSessionId}` },
        orderId: order.id,
        paymentMethod: paymentMethod,
        storePaymentMethod: storePaymentMethod,
        //shopperReference: "654sdf-sdfds6-fsdf65-gdfgdfg4",
        browserInfo: browserInfo,
        redirect: {
          success: `${pathBase}/thankyou/`,
          error: `${pathBase}/checkout/`,
          canceled: `${pathBase}/checkout/`,
          default: `${pathBase}`,
        },
      })
    } catch (error) {
      console.error(error)
      navigate("/error/")
    }
  }

  const makeDetailsCall = async (orderId, details) => {
    const brinkAPI = new BrinkAPI({
      baseUrl: baseUrl,
    })

    try {
      const res = await brinkAPI.makeDetailsCall({
        headers: { Authorization: `Bearer ${BrinkSessionId}` },
        orderId: orderId,
        details: details,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  const getOrderConfirmation = async (orderId, signature) => {
    const brinkAPI = new BrinkAPI({
      baseUrl: baseUrl,
    })

    try {
      const orderConfirmation = brinkAPI.getOrderConfirmation(
        orderId,
        signature
      )
      return orderConfirmation
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getKlarnaOrder = async orderId => {
    const brinkAPI = new BrinkAPI({
      baseUrl: baseUrl,
    })

    try {
      const klarnaOrder = brinkAPI.getKlarnaOrder({
        headers: { Authorization: BrinkSessionId },
        orderId,
      })
      return klarnaOrder
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const syncIngridDeliveryCheckout = async (
    shippingAddress,
    withSearchAddress = true
  ) => {
    //console.log(shippingAddress)
    const brinkApi = new BrinkAPI({
      baseUrl: baseUrl,
      headers: { Authorization: `Bearer ${BrinkSessionId}` },
    })
    const searchAddress = {
      postalCode: shippingAddress.postalCode || "",
      countryCode: cart.store.countryCode,
    }
    //setIsLoading(true)
    return brinkApi
      .ingridDeliveryCheckout(BrinkSessionId, {
        ...(withSearchAddress && { searchAddress }),
      })
      .then(({ cart, ingrid }) => {
        setCart(cart)
        GA4.addShippingInfo(
          {
            shippingOption: cart.shipping.customerAttribute.ingrid.category,
            value: cart.shipping.price[cart.store.currencyUnit] / 100,
            currency: cart.store.currencyUnit,
          },
          cart
        )
        return ingrid
      })
      .catch(error => {
        console.log(error)
        // updateShippingAddress({
        //   postalCode: undefined
        // })
        navigate("/error/")
      })
  }

  //const getCart

  const contextObjects = {
    cart,
    closeCart,
    store,
    setStore,
    isCartOpen,
    setIsCartOpen,
    BrinkSessionId,
    cartQuantity,
    addToCart,
    handleVoucher,
    getStocks,
    getPaymentMethods,
    makePayment,
    makeDetailsCall,
    cartToOrder,
    getShippingOptions,
    setShippingMethod,
    getOrderConfirmation,
    syncIngridDeliveryCheckout,
    cartToKlarnaOrder,
    getKlarnaOrder,
    cookieSettingsVisible,
    setCookieSettingsVisible,
  }

  return (
    <BrinkContext.Provider value={contextObjects}>
      {children}
    </BrinkContext.Provider>
  )
}

export default BrinkContextProvider

export const useBrink = () => useContext(BrinkContext)
