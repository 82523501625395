// Dependency
import React from "react"
import Image from "../../ui/image/image"
import SvgIcon from "../../../hooks/svg"
import { storyblokEditable } from "gatsby-source-storyblok"

// Styles
import "./bullets-icons.scss"

// Template
const BulletsIcons = props => {
  const componentProps = props.blok
    ? props.blok
    : props.props
    ? props.props
    : props

  return (
    <div {...storyblokEditable(props.props)}>
      <div className="bullets-icons" id="bullets-icons">
        <div className="bullets-icons__bullets">
          <div className="bullets-icons__list">
            {componentProps.bulletPoints &&
              componentProps.bulletPoints.map((point, index) => {
                return (
                  <div className="bullets-icons__bullet" key={index}>
                
                  <SvgIcon name="line" />
                    <span className="bullets-icons__bullet-text">
                      {point.content}
                    </span>
                  </div>
                )
              })}
          </div>
        </div>
        <div className="bullets-icons__icons">
          {componentProps.images &&
            componentProps.images.map((image, index) => {
              return (
                <div className="bullets-icons__icons__icon" key={index}>
                  <Image
                    largeScreenImage={image.largeScreenImage.filename}
                    largeScreenHeight={56}
                    largeScreenWidth={56}
                    smallScreenImage={image.smallScreenImage.filename}
                    smallScreenHeight={56}
                    smallScreenWidth={56}
                    alt={image.largeScreenImage.alt}
                    layout={"constrained"}
                  />
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
export default BulletsIcons
