// Dependency
import React from "react"

// Styles
import "./button.scss"
import cx from "classnames"
import SvgIcon from "../../../hooks/svg"
import Loader from "../loader/loader"

// Template
const Button = props => {
  const componentProps = props.props ? props.props : props
  const iconName =
    componentProps.icon &&
    componentProps.icon.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase()
  return (
    <button
      className={cx(
        "button",
        { "button--icon-left": componentProps.alignIconleft },
        { "button--outline": componentProps.outlined },
        { "button--outline-variation": componentProps.outlinedVariation },
        { "button--secondary": componentProps.secondary },
        { "button--success": componentProps.success },
        { "button--error": componentProps.error },
        { "button--no-border": componentProps.noBorder },
        { "button--icon-only": componentProps.iconOnly },
        { "button--icon-only-no-bg": componentProps.iconOnlyNobg },
        { "button--icon-transparet-bg": componentProps.transparetBg },
        {
          "button--disabled":
            componentProps.disabled || componentProps.isLoading,
        },
        { "button--active": componentProps.active }
      )}
      onClick={props.onClick}
      disabled={componentProps.disabled}
    >
      {!componentProps.isLoading && componentProps.buttonText}
      {componentProps.isLoading && <Loader />}
      {componentProps.icon && (
        <SvgIcon
          name={iconName}
          className={cx("button__icon", {
            "button__icon--large": componentProps.largeIcon,
          })}
        />
      )}
      {componentProps.asset && <SvgIcon name={componentProps.asset} height={componentProps.assetHeight} />}
    </button>
  )
}
export default Button
