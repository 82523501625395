//Dependencies
import React from "react"

//Styles
import "./mobile-nav-links.scss"

//Hooks
import SvgIcon from "../../../../hooks/svg"
import RenderLink from "../../../ui/link/link"
import { Link } from "gatsby"

const MobileNavLinks = props => {
  const componentProps = props.blok
    ? props.blok
    : props.props
    ? props.props
    : props

  return (
    <div className={`${componentProps.showMeganav ? "mobile-nav-links--show" : ""} mobile-nav-links`} >
      <div className="mobile-nav-links__container__tab">
        {/* <div className="mobile-nav-links__container__tab-icon">
          <SvgIcon
            className="mobile-nav-links__container__tab-icon--svg"
            name={componentProps.icons}
            height="22"
          />
        </div> */}
        <RenderLink
          className="mobile-nav-links__container__tab-title"
          linkText={componentProps.linkText}
          linkUrl={componentProps.link.url || "#"}
        />
      </div>
      {
        componentProps.showMeganav ?
        <div className="mobile-nav-links__meganav">
          {props.meganav && props.meganav.map((link, i) => {
            return (
              <Link to={link.link.url} key={i} >
                <span>{link.linkText}</span>
                <SvgIcon height="18" name="arrow-right-m" />
              </Link>
            )
          })}
        </div>
        :
        null
      }
  
    </div>
  )
}

export default MobileNavLinks
