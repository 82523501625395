//Dependencies
import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import cx from "classnames"
import CmsText from "../../cms/cms-text/cms-text"

//Style
import "./large-review-block.scss"
import "swiper/css"
import "swiper/css/mousewheel"
import "swiper/css/navigation"
import "swiper/css/scrollbar"
import SvgIcon from "../../../hooks/svg"
import useLipscoreReviews from "../../../hooks/useLipscoreReviews"
import ReviewStarAmount from "../../general/review-star-amount/reviewStarAmount"
import moment from "moment/moment"
import useMediaQuery from "../../../hooks/useMediaQuery"

const LargeReviewBlock = props => {
  const isMobile = useMediaQuery(576)
  const componentProps = props.blok ? props.blok : props.prop
  const { lipscoreReviews } = useLipscoreReviews({ limit: 20 })

  return (
    <div
      className={cx(
        "large-review-block",
        {
          "padding-top": componentProps.paddingTop,
        },
        {
          "padding-bottom": componentProps.paddingBottom,
        }
      )}
    >
      <div className="grid-container">
        <div className="large-review-block__heading">
          <div className="large-review-block__heading-heading">
            {componentProps.heading &&
              componentProps.heading.map((text, index) => (
                <CmsText
                  key={index}
                  color={text.color ? text.color : "black"}
                  text={text.text}
                  size={text.size}
                  tag={text.tag ? text.tag : "p"}
                  fontWeight={text.fontWeight}
                />
              ))}
          </div>
        </div>
        <Swiper
          className="large-review-block__swiper"
          spaceBetween={16}
          direction={"horizontal"}
          loop={true}
          autoHeight={true}
          modules={[Navigation]}
          navigation={{
            nextEl: `.large-review-block--next`,
            prevEl: `.large-review-block--prev`,
          }}
          mousewheel={{
            releaseOnEdges: true,
            forceToAxis: true,
          }}
          breakpoints={{
            1100: {
              spaceBetween: 16,
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            900: {
              spaceBetween: 16,
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            0: {
              spaceBetween: 24,
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
          }}
          slidesPerView={1}
          slidesPerGroup={1}
          touchReleaseOnEdges={true}
          grabCursor={true}
          scrollbar={{
            hide: false,
            dragSize: "auto",
            draggable: true,
          }}
        >
          {lipscoreReviews &&
            Array.isArray(lipscoreReviews) &&
            lipscoreReviews.map((item, index) => (
              <SwiperSlide
                className="large-review-block__swiper-slide"
                key={index}
              >
                <div className="large-review-block__swiper-slide__item">
                  <div className="large-review-block__swiper-slide__item__heading">
                    <div className="large-review-block__swiper-slide__item__star_box">
                      <ReviewStarAmount
                        starAmount={item.rating}
                      />
                    </div>
                    <div className="large-review-block__swiper-slide__item__upper-middle">
                      <CmsText
                        text={item.user.name}
                        size="large-a"
                        fontWeight="semibold"
                        tag="p"
                      />
                      <div className="large-review-block__swiper-slide__item__upper-middle__gap" />
                      <CmsText
                        text={moment(item.created_at).format("MMM D YYYY")}
                        size={isMobile ? "xxx-small" : "x-small"}
                        tag="p"
                      />
                    </div>
                  </div>
                  <div className="large-review-block__swiper-slide__item__text">
                    <CmsText text={item.text} size="xa-small" tag="p" />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          <div className="large-review-block__swiper--arrows large-review-block--next">
            <SvgIcon
              name="small-simple-arrow-right"
              height={80}
              className="large-review-block--arrow"
            />
          </div>
          <div className="large-review-block__swiper--arrows large-review-block--prev">
            <SvgIcon
              name="small-simple-arrow-left"
              height={80}
              className="large-review-block--arrow"
            />
          </div>
        </Swiper>
      </div>
    </div>
  )
}

export default LargeReviewBlock
