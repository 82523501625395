// Dependency
import React, { useEffect, useState } from "react"
import cx from "classnames"
import loadable from "@loadable/component"

// Styles
import "./language-switcher.scss"

import at from "../../../assets/flags/at.svg"
import be from "../../../assets/flags/be.svg"
import ch from "../../../assets/flags/ch.svg"
import de from "../../../assets/flags/de.svg"
import dk from "../../../assets/flags/dk.svg"
import es from "../../../assets/flags/es.svg"
import fi from "../../../assets/flags/fi.svg"
import fr from "../../../assets/flags/fr.svg"
import gb from "../../../assets/flags/gb.svg"
import it from "../../../assets/flags/it.svg"
import nl from "../../../assets/flags/nl.svg"
import no from "../../../assets/flags/no.svg"
import pl from "../../../assets/flags/pl.svg"
import pt from "../../../assets/flags/pt.svg"
import se from "../../../assets/flags/se.svg"
import globe from "../../../assets/icons/globe.svg"
import { graphql, useStaticQuery } from "gatsby"
import { useBrink } from "../../../context/BrinkContext"
import useMediaQuery from "../../../hooks/useMediaQuery"

const ReactSelect = loadable(() => import("../react-select/react-select"))

const BUILD_MARKET = process.env.GATSBY_BUILD_MARKET

// Template
const LanguageSwitcher = props => {
  const isTabletOrMobile = useMediaQuery(1024)
  const data = useStaticQuery(graphql`
    query {
      brink {
        allStores {
          stores {
            countryCode
            currencyUnit
            languageCode
            tax
          }
        }
      }
    }
  `)

  const flags = {
    at,
    be,
    ch,
    de,
    dk,
    es,
    fi,
    fr,
    gb,
    it,
    nl,
    no,
    pl,
    pt,
    se,
  }

  const { store, setStore } = useBrink()

  const [langStore, setLangStore] = useState(store)

  useEffect(() => {
    if (BUILD_MARKET === "COM") {
      setLangStore(store)
    }
  }, [store])

  const options = data.brink.allStores.stores.map(store => ({
    value: store.countryCode,
    label: `${store.countryCode} | ${store.currencyUnit}`,
    // image: flags[store.countryCode.toLowerCase()],
  }))

  const formatOptionLabel = ({ value, label }) => (
    <div className="language-switcher">
      <div className="language-switcher__text">{label}</div>
    </div>
  )
  const domainSlugs = ["SE", "GB", "DE", "DK", "FI", "NL", "NO", "CH"]
  const handleChange = event => {
    const market = event.value === "GB" ? "co.uk" : event.value.toLowerCase()
    if (!domainSlugs.includes(event.value)) {
      if (BUILD_MARKET === "COM") {
        const savedStore = data.brink.allStores.stores.find(
          s => s.countryCode === event.value
        )
        setStore(savedStore, true)
      } else {
        window.location = `https://www.tanrevel.com`
      }
    } else {
      window.location = `https://tanrevel.${market}`
    }
  }

  return (
    <div
      className={cx("language-switcher", {
        "language-switcher--flag-only": props.flagOnly,
      })}
    >
      {!isTabletOrMobile ? (
        <div className="language-switcher__globe">
          <img src={globe} width="16" height="16" alt="globe" />
        </div>
      ) : null}
      {langStore ? (
        <ReactSelect
          defaultValue={
            options.find(s => {
              if (BUILD_MARKET === "COM") {
                return (
                  s.value.toLowerCase() === langStore.countryCode.toLowerCase()
                )
              } else {
                return s.value.toLowerCase() === process.env.GATSBY_BUILD_MARKET
              }
            }) ?? options[0]
          }
          formatOptionLabel={formatOptionLabel}
          options={options}
          noBorder
          id={props.id}
          handleChange={handleChange}
        />
      ) : null}
    </div>
  )
}

export default LanguageSwitcher
