// Dependency
import React from "react"
import Image from "../../ui/image/image"
import ReactPlayer from "react-player"
import useMediaQuery from "../../../hooks/useMediaQuery"

// Styles
import "./hero.scss"
import cx from "classnames"
import RenderLink from "../../ui/link/link"


// Template
const Hero = props => {

  const isTabletOrMobile = useMediaQuery(768)

  const componentProps = props.props
    ? props.props
    : props.blok
      ? props.blok
      : props

  return (
    <section
      className={cx(
        "hero",
        { "hero--compact": componentProps.compact },
        { "hero--desktop-middle": componentProps.desktopPosition === "middle" },
        { "hero--desktop-bottom": componentProps.desktopPosition === "bottom" },
        { "hero--mobile-middle": componentProps.mobilePosition === "middle" },
        { "hero--mobile-bottom": componentProps.mobilePosition === "bottom" },
        { "hero--desktop-top": componentProps.desktopPosition === "top" },
        { "hero--mobile-top": componentProps.mobilePosition === "top" },
        { "hero--compact-full-image": componentProps.compactFullImage }
      )}
      id="hero"
    >
      {/* <div className="hero__image">
        <Image
          largeScreenImage={
            componentProps.largeScreenImage &&
            componentProps.largeScreenImage.filename
          }
          largeScreenHeight={"auto"}
          largeScreenWidth={1400}
          smallScreenImage={
            componentProps.smallScreenImage &&
            componentProps.smallScreenImage.filename
          }
          smallScreenHeight={"auto"}
          smallScreenWidth={900}
          alt={
            componentProps.largeScreenImage &&
            componentProps.largeScreenImage.alt
          }
          layout={"constrained"}
          loading="eager"
        />
      </div>
      {componentProps.desktopVideo && !isTabletOrMobile ?
        <ReactPlayer
          url={componentProps.desktopVideo}
          playsinline={true}
          width="100%"
          height="100%"
          muted={true}
          loop={true}
          controls={false}
          className='hero__video'
          playing
        />
        : componentProps.mobileVideo && isTabletOrMobile ?

          <ReactPlayer
            url={componentProps.mobileVideo}
            playsinline={true}
            width="100%"
            height="100%"
            loop={true}
            muted={true}
            controls={false}
            className='hero__video'
            playing
          />
          :
          ''
      } */}
      <div
        className="hero__content"
        // style={{
        //   color: `${componentProps.textColor && componentProps.textColor.color
        //     }`,
        // }}
      >
        <div className="grid-container">
          <div className="grid__row">
            <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
              <div className="hero__content-inner">
                <h1 className="hero__heading">{componentProps.title}</h1>
                <span className="hero__subheading">
                  {componentProps.content}
                </span>
                {/* <div className="hero__button">
                  {componentProps.link && componentProps.link.url && (
                    <RenderLink
                      color={componentProps.buttonColor && componentProps.buttonColor.color}
                      backgroundColor={componentProps.buttonBackground && componentProps.buttonBackground.color}
                      linkText={componentProps.linkText}
                      linkUrl={`${componentProps.link.url}`}
                      buttonLook="black"
                    />
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
