// Dependency
import React from "react"
import { Link } from "gatsby"
import SvgIcon from "../../../hooks/svg"
import cx from "classnames"
import { useTranslation } from "react-i18next"

// Styles
import "./breadcrumbs.scss"

// Template
const Breadcrumbs = props => {
  const componentProps = props
  const { t } = useTranslation()

  return (
    <div
      className={cx("breadcrumbs", {
        "breadcrumbs--whiteBg": componentProps.whiteBackground,
        "breadcrumbs--large": componentProps.large,
      })}
    >

            <div className="breadcrumbs__inner">
              <Link className="breadcrumbs__main-link" to={`/`}>
                {t("breadcrumbs.home")}
              </Link>
              <span className="breadcrumbs__separator">
                <SvgIcon name="big-simple-arrow-right" height="8" />
              </span>
              {componentProps.links &&
                componentProps.links.map((element, i) => {
                  return (
                    <div key={i} className="breadcrumbs__list">
                      <Link
                        className="breadcrumbs__link"
                        to={`/${element.link}/`}
                      >
                        {element.title}
                      </Link>
                      <span className="breadcrumbs__separator">
                        <SvgIcon name="big-simple-arrow-right" height="8" />
                      </span>
                    </div>
                  )
                })}
              <span className="breadcrumbs__title">
                {componentProps.breadTitle}
              </span>
            </div>
          </div>

  )
}

export default Breadcrumbs
