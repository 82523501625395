"use client"

import React, { useEffect, useRef } from "react"

interface Props {
  lipscoreId: string
  productName: string
  brand: string
  productId: string
  productUrl: string
  variantId?: string
  variantName?: string
  description?: string
  imageUrl?: string
  price?: string
  priceCurrency?: string
  availability?: string
  category?: string
  gtin?: string
  readonly?: boolean
  className?: string
}

const LipscoreWidget = ({
  lipscoreId,
  productName,
  brand,
  productId,
  productUrl,
  variantId,
  variantName,
  description,
  imageUrl,
  price,
  priceCurrency,
  availability,
  category,
  gtin,
  readonly,
  className,
}: Props) => {
  const lipscoreRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const lipscoreDiv = document.createElement("div")
    lipscoreDiv.id = lipscoreId
    if (className) lipscoreDiv.className = className
    lipscoreDiv.dataset.productName = productName
    lipscoreDiv.dataset.brand = brand
    lipscoreDiv.dataset.productId = productId
    lipscoreDiv.dataset.productUrl = productUrl
    if (variantId) lipscoreDiv.dataset.variantId = variantId
    if (variantName) lipscoreDiv.dataset.variantName = variantName
    if (description) lipscoreDiv.dataset.description = description
    if (imageUrl) lipscoreDiv.dataset.imageUrl = imageUrl
    if (price) lipscoreDiv.dataset.price = price
    if (priceCurrency) lipscoreDiv.dataset.priceCurrency = priceCurrency
    if (availability) lipscoreDiv.dataset.availability = availability
    if (category) lipscoreDiv.dataset.category = category
    if (gtin) lipscoreDiv.dataset.gtin = gtin
    if (readonly) lipscoreDiv.dataset.readonly = "true"
    if (lipscoreRef.current) {
      lipscoreRef.current.innerHTML = ""
      lipscoreRef.current.appendChild(lipscoreDiv)
    }
  }, [])

  return (
    <div
      ref={lipscoreRef}
      id={lipscoreId}
      data-ls-product-name={productName}
      data-ls-brand={brand}
      data-ls-product-id={productId}
      data-ls-product-url={productUrl}
      data-ls-variant-id={variantId}
      data-ls-variant-name={variantName}
      data-ls-description={description}
      data-ls-image-url={imageUrl}
      data-ls-price={price}
      data-ls-price-currency={priceCurrency}
      data-ls-availability={availability}
      data-ls-category={category}
      data-ls-gtin={gtin}
      className={className}
      data-ls-readonly={readonly}
    />
  )
}
export default LipscoreWidget
