// Dependency
import React from "react"
import TextOnImageColorBlock from "../../general/text-on-image-color-block/text-on-image-color-block"
import HeadingWithCaptionImage from "../../general/heading-with-caption-image/heading-with-caption-image"

// CSS
import "./product-feature-image-ingredients.scss"

// Template
const ProductFeatureImageIngredients = props => {
  const textOnImageColorBlockData = props.blok.Components.filter(
    i => i.component === "text-on-image-color-block"
  )
  const ingredients = props.blok.Components.find(
    i => i.component === "product-ingredients-list"
  )

  return (
    <section className="product-feature-image-ingredients">
      <div className="grid-container">
        <div className="grid__row">
          <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
            <div className="product-feature-image-ingredients__inner">
              {textOnImageColorBlockData &&
                textOnImageColorBlockData.map((section, index) => {
                  return (
                    <div
                      className="product-feature-image-ingredients__column"
                      key={index}
                    >
                      <TextOnImageColorBlock key={index} props={section} />
                    </div>
                  )
                })}
              <div className="product-feature-image-ingredients__column">
                <div className="product-feature-image-ingredients__list">
                  <div className="product-feature-image-ingredients__list-inner">
                    <div className="product-feature-image-ingredients__list-title">
                      {ingredients.title}
                    </div>
                    <p>{ingredients.description}</p>
                  </div>
                  <div className="product-feature-image-ingredients__list__items">
                  {ingredients.sections.map((section, index) => {
                    return (
                      <HeadingWithCaptionImage props={section} key={index} />
                    )
                  })}
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default ProductFeatureImageIngredients
