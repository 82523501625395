//Dependencies
import React, { useState } from "react"
import RenderLink from "../../ui/link/link"
import RichTextResolver from "../../../helpers/storyblok/richTextResolver"
import SvgIcon from "../../../hooks/svg"

//Styles
import "./accordion.scss"
import cx from "classnames"

const Accordion = props => {
  const [accordionExpanded, setAccordionExpandend] = useState(false)

  const accordionHandler = () => {
    setAccordionExpandend(!accordionExpanded)
  }

  const iconClassHide =
    props.accordionStyle === "page"
      ? "plus"
      : "single"
      ? "big-simple-arrow-down"
      : "plus"
  const iconClassShow =
    props.accordionStyle === "page"
      ? "minus"
      : "single"
      ? "big-simple-arrow-up"
      : "minus"
      
  return (
    <div
      className={cx(
        "accordion",
        { "accordion--expanded": accordionExpanded },
        "accordion--unexpanded",
        { "accordion--page": props.accordionStyle === "page" },
        { "accordion--trans-bg": props.background === "transparent" }
      )}
    >
      <button className="accordion__button" onClick={accordionHandler}>
        {props.accordionTitle}

        <span className={`accordion__button__icon-${iconClassHide}`}>
          <SvgIcon height={20} name={iconClassHide} />
        </span>
        <span className={`accordion__button__icon-${iconClassShow}`}>
          <SvgIcon height={20} name={iconClassShow} />
        </span>
      </button>
      <div className="accordion__expandable-area">
        <div className="accordion__expandable-area__content">
          {props.contentType === "linksList" &&
            props.accordionContent.map((link, index) => {
              return (
                <div className={props.class} key={index}>
                  <RenderLink
                    linkText={link.linkText}
                    linkUrl={link.link.url ? link.link.url : "#"}
                  />
                  {/* <SvgIcon height={20} name="big-simple-arrow-right" /> */}
                </div>
              )
            })}
          {props.contentType !== "linksList" && (
            <RichTextResolver data={props.accordionContent} />
          )}
          {props.contentType === "html" && <div>{props.accordionContent}</div>}
        </div>
      </div>
    </div>
  )
}

export default Accordion
