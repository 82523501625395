//Dependencies
import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { EffectFade, Scrollbar, Pagination } from "swiper"

//Styles
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/scrollbar"
import "swiper/css/pagination"
import "./swiper-carousel-variation-three.scss"

//Components
import Image from "../../ui/image/image"
import Hero from "../hero/hero"

SwiperCore.use([Scrollbar, EffectFade])

const SwiperCarouselVariationThree = props => {
  const componentProps = props.blok ? props.blok : props.props
  const heroCarousel = componentProps.slides.filter(c => c.component === "hero")

  return (
    <div className="swiper-carousel-variation-three">
      <Swiper
        className="swiper-carousel-variation-three__swiper"
        spaceBetween={0}
        pagination={{ type: "bullets" }}
        modules={[Pagination]}
        loop={true}
        slidesPerView={1}
        slidesPerGroup={1}
        touchReleaseOnEdges={true}
        grabCursor={true}
        scrollbar={{
          hide: false,
          dragSize: "auto",
          draggable: true,
        }}
      >
        {heroCarousel &&
          heroCarousel.map((hero, index) => (
            <SwiperSlide
              className="swiper-carousel-variation-three__swiper-slide"
              key={index}
            >
              <Hero props={hero} />
            </SwiperSlide>
          ))}
        {!heroCarousel &&
          componentProps.slides.map((slide, index) => (
            <SwiperSlide
              className="swiper-carousel-variation-three__swiper-slide"
              key={index}
            >
              <div className="swiper-carousel-variation-three__swiper-slide__image image__wrapper">
                <Image
                  largeScreenImage={slide.largeScreenImage.filename}
                  largeScreenHeight={"auto"}
                  largeScreenWidth={"auto"}
                  smallScreenImage={slide.smallScreenImage.filename}
                  smallScreenHeight={"auto"}
                  smallScreenWidth={"auto"}
                  alt={slide.largeScreenImage.alt}
                  layout={"constrained"}
                />
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  )
}

export default SwiperCarouselVariationThree
