// Dependency
import React from "react"

// Styles
import "./static-banner.scss"

// Template
const StaticBanner = ({ blok }) => {
  return (
    <section className="static-banner" id="static-banner">
      <div className="static-banner__content">{blok.content}</div>
    </section>
  )
}
export default StaticBanner
