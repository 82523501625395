//Dependencies
import React from "react"
import RichTextResolver from "../../../helpers/storyblok/richTextResolver"

//Styles
import "./text-section.scss"

const TextSection = props => {
  const componentProps = props.props ? props.props : props.blok

  return (
    <div className="text-section" id="text-section">
      <div className="grid-container">
        <div className="grid__row">
          <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
            {componentProps.title}
            <RichTextResolver data={componentProps.content} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextSection
