import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { getGatsbyImage } from "gatsby-plugin-storyblok-image"

export default function StoryblokImage({
  image,
  layout,
  width,
  height,
  loading,
  alt,
}) {
  const gatsbyImageData =
    image && typeof image === "string"
      ? getGatsbyImage(image, {
          // layout can be 'fixed' | 'constrained' | 'fullWidth'
          // see more: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#layout
          layout: layout,

          // Use width & height when layout is 'fixed' or 'constrained'
          // No need when layout is 'fullWidth'
          width: width,
          height: height,
          loading: loading,

          // Optional:
          //quality: 100,
          // outputPixelDensities: [1, 2, 3], // For fixed or constrained
          // breakpoints	[750, 1080, 1366, 1920], // For fullWidth or constrained
          // backgroundColor: '#fff', // Just pass to gatsby-plugin-image image prop

          /** Storyblok image service additional options: */
          // `fitIn` set `true` to make the image like fixed object to "contain". Refer to https://www.storyblok.com/docs/image-service#fit-in
          // fitIn: true, // Default false (act like "cover")
          // fitInColor: '#fff', // Color to fill when fitIn true. Default is transparent. okay to add `#` prefix.

          // smartCrop: true, // Default true
        })
      : null
  const i = gatsbyImageData ? getImage(gatsbyImageData) : null
  return i ? <GatsbyImage alt={alt} image={i} loading={loading} /> : null
}
