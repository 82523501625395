// Dependency
import React from "react"
import Image from "../../ui/image/image"

// Styles
import "./social-media.scss"

// Template
const SocialMedia = props => {
  const componentProps = props.blok
    ? props.blok
    : props.props
    ? props.props
    : props

  return (
    <div className="social-media" id="social-media">
      <Image
        largeScreenImage={componentProps.banner.filename}
        largeScreenHeight={28}
        smallScreenImage={componentProps.banner.filename}
        smallScreenHeight={28}
        alt={componentProps.banner.alt}
        layout={"constrained"}
      />
    </div>
  )
}
export default SocialMedia
