import React from "react"
import SvgIcon from "../../../hooks/svg"

//Styles
import "./review-star-amount.scss"

const ReviewStarAmount = props => {
  if (!props.starAmount || Number.isNaN(props.starAmount)) return null
  const starAmount = Math.floor(props.starAmount)
  const halfStar = props.starAmount - starAmount > 0
  return (
    <div className="review-star-amount">
      {Array(starAmount)
        .fill(0)
        .map((_s, i) => (
          <SvgIcon
            key={`star-${i}`}
            name={props.variant === "golden" ? "reviewStar" : "star_black"}
            height={16}
          />
        ))}
      {halfStar ? (
        <SvgIcon
          key={`half-star`}
          name={
            props.variant === "golden" ? "star_gold_half" : "star_black_half"
          }
          height={16}
        />
      ) : undefined}
      {Array(5 - starAmount - (halfStar ? 1 : 0))
        .fill(0)
        .map((_s, i) => (
          <SvgIcon
            key={`star-${i}`}
            name={
              props.variant === "golden"
                ? "star_gold_empty"
                : "star_black_outline"
            }
            height={16}
          />
        ))}
      {props.reviewCount && <>({props.reviewCount})</>}
    </div>
  )
}

export default ReviewStarAmount
