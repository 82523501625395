// Dependency
import React from "react"
import { storyblokEditable } from "gatsby-source-storyblok"

// Styles
import "./heading-with-caption-image.scss"
import cx from "classnames"

// Hooks
import Image from "../../ui/image/image"

// Template
const HeadingWithCaptionImage = props => {
  const componentProps = props.blok
    ? props.blok
    : props.props
    ? props.props
    : props
  const Tag =
    componentProps && componentProps.headingTag
      ? componentProps.headingTag
      : "h2"

  return (
    <div {...storyblokEditable(props.props)}>
<div
      className={cx("heading-with-caption-image", {
        "heading-with-caption-image--left": componentProps.imageLeftAlign,
      })}
      id="heading-with-caption-image"
    >
      <div className="heading-with-caption-image__inner">
        {Tag && (
          <Tag
            className={cx("heading-with-caption-image__heading", {
              "heading-with-caption-image__heading--small":
                componentProps.smallHeading,
            })}
          >
            {componentProps.heading}
          </Tag>
        )}
        {componentProps && componentProps.caption ? (
          <div
            className={cx(
              "heading-with-caption-image__caption",
              {
                "heading-with-caption-image__caption--space4":
                  componentProps.space === "4",
              },
              {
                "heading-with-caption-image__caption--space0":
                  componentProps.space === "0",
              },
              {
                "heading-with-caption-image__caption--small":
                  componentProps.smallHeading,
              }
            )}
          >
            {componentProps && componentProps.caption}
          </div>
        ) : (
          ""
        )}
      </div>
      {componentProps.image && componentProps.image.id && (
        <div className="heading-with-caption-image__image image__wrapper">
          <Image
            largeScreenImage={componentProps.image.filename}
            largeScreenHeight={1400}
            largeScreenWidth={1000}
            smallScreenImage={componentProps.image.filename}
            smallScreenHeight={1400}
            smallScreenWidth={1000}
            alt={componentProps.image.alt}
            layout={"constrained"}
          />
        </div>
      )}
    </div>
    </div>
  )
}
export default HeadingWithCaptionImage
