import React, { useEffect, useState } from "react"
import RichTextResolver from "../../../helpers/storyblok/richTextResolver"

//Styles
import "./cta-header.scss"

const CtaHeader = props => {
  const [counter, setCounter] = useState("")

  useEffect(() => {
    const countDown = () => {
      let countDownDate = new Date(props.ctaHeader.counter).getTime()
      let x = setInterval(function () {
        let now = new Date().getTime()
        let distance = countDownDate - now
        let days = Math.floor(distance / (1000 * 60 * 60 * 24)).toString()
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ).toString()
        let minutes = Math.floor(
          (distance % (1000 * 60 * 60)) / (1000 * 60)
        ).toString()
        let seconds = Math.floor((distance % (1000 * 60)) / 1000).toString()
        setCounter(days + "d " + hours + "h " + minutes + "m " + seconds + "s ")

        if (distance < 0) {
          clearInterval(x)
          setCounter("EXPIRED")
        }
      }, 1000)
    }

    countDown()
  }, [props.ctaHeader.counter])

  return (
    <>
      {props.ctaHeader.link.url !== "" ? (
        <a href={props.ctaHeader.link.url}>
          <div className="cta-header" style={{background: props.ctaHeader.background}}>
            <div className="cta-header__content">
            <div className="cta-header__content--text" style={{color: props.ctaHeader.color}}>
                <RichTextResolver data={props.ctaHeader.text} />
              </div>
              <div className="cta-header__content--counter">
                {props.ctaHeader.counter !== "" && <p style={{color: props.ctaHeader.color}}>{counter}</p>}
              </div>
            </div>
          </div>
        </a>
      ) : (
        <div className="cta-header" style={{background: props.ctaHeader.background}}>
          <div className="cta-header__content">
            <div className="cta-header__content--text" style={{color: props.ctaHeader.color}}>
              <RichTextResolver data={props.ctaHeader.text} />
            </div>
            <div className="cta-header__content--counter">
              {props.ctaHeader.counter !== "" && <p style={{color: props.ctaHeader.color}}>{counter}</p>}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CtaHeader
