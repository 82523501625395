import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Mousewheel, Scrollbar } from "swiper"

//Styles
import "swiper/css"
import "swiper/css/mousewheel"
import "swiper/css/scrollbar"
import "./cart-upsell-carousel.scss"

//Components
import UpsellCard from "../../cart/upsell-card/upsell-card"

SwiperCore.use([Mousewheel, Scrollbar])

const CartUpsellCarousel = props => {
  const query = useStaticQuery(graphql`
    query {
      brink {
        getProducts(
          ids: [
            "A-190"
            "A-160"
            "A-133"
            "B-2520"
            "C-102"
            "B-2540-OR"
            "A-4210-OR-PP"
            "B-140-OR-2"
            "A-140-OR-REGION"
            "A-150-50-W"
            "B-180-OR"
            "A-180-LD"
            "A-180-OR"
            "B-1605"
            "A-190-LD"
            "B-190-OR"
            "A-41231-OR"
            "A-4210-OR"
            "A-190-OR"
            "A-TNRL-AS-HB"
            "A-TNRL-AS-MT"
            "A-TNRL-ME-DKCL-200"
            "A-TNRL-FA-CR-40"
            "A-TNRL-PRO-DKCL-80"
            "A-TNRL-FA-DKCL-80"
            "A-TNRL-FA-DKWM-80"
            "B-TNRL-ST-MD"
            "A-TNRL-FA-DKCL-40"
            "A-TNRL-FA-DKWM-40"
            "B-TNRL-ST-IS"
            "A-TNRL-FA-CR-80"
          ]
        ) {
          id
          name
          category
          active
          type
          archived
          imageUrl
          slug
          price {
            amount
            currencyUnit
          }
          discount {
            amount
            currencyUnit
          }
          relatedProduct {
            id
          }
          relatedVariants {
            id
          }
        }
      }
    }
  `)
  const componentProps = props.blok ? props.blok : props.props
  return (
    <div className="cart-upsell-carousel">
      <h4 className="cart-upsell-carousel__heading">{componentProps.title}</h4>
      <Swiper
        className="cart-upsell-carousel__swiper"
        spaceBetween={10}
        direction="horizontal"
        loop={false}
        mousewheel={{
          releaseOnEdges: true,
          forceToAxis: true,
        }}
        slidesPerView={"auto"}
        slidesPerGroup={1}
        touchReleaseOnEdges={true}
        grabCursor={true}
        scrollbar={{
          hide: false,
          dragSize: "auto",
          draggable: true,
        }}
      >
        {componentProps.productList &&
          componentProps.productList.map((card, index) => {
            const prod =
              query.brink.getProducts.find(
                prod => prod.id === card.content.sku
              ) ?? false

            if (!prod) {
              return null
            }
            return (
              <SwiperSlide
                className="cart-upsell-carousel__swiper-slide"
                key={index}
              >
                <UpsellCard props={card} brink={prod} />
              </SwiperSlide>
            )
          })}
      </Swiper>
    </div>
  )
}

export default CartUpsellCarousel
