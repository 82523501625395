// Dependency
import React, { useState } from "react"
import Image from "../../ui/image/image"
import cx from "classnames"
import ReactPlayer from "react-player"
import Button from "../../ui/button/button"
import Textfield from "../form/textfield/textfield"
import {
    subscribeToLeadGen,
  getKlaviyoListIds,
} from "../../../context/utils/events/klaviyo"
import * as events from "../../../context/utils/events"

// Components
import CmsText from "../../cms/cms-text/cms-text"

// Hooks
import useMediaQuery from "../../../hooks/useMediaQuery"
import { useTranslation } from "react-i18next"

// Styles
import "../../cms/cms-hero/cms-hero.scss"
import "./lead-section.scss"

// To be extended with storyblokprops

const LeadSection = props => {

    const heroCard = props.blok
    const isMobile = useMediaQuery(1025)

    const { t } = useTranslation()
    const [email, setEmail] = useState()
    const [firstName, setFirstName] = useState()
    const [message, setMessage] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const handleSubmit = async e => {
      setMessage()
      setIsLoading(true)
      e.preventDefault()
      const listId = heroCard.klaviyoId
      const response = await subscribeToLeadGen(
        //process.env.GATSBY_BUILD_MARKET.toUpperCase(), //this should be currentStore.countryCode
        email,
        firstName,
        listId
      )
      if (response.success) {
        events.signup({
          listId,
          status: true,
        })
        setMessage(t("newsletter.success"))
      } else if (response && response.data.is_subscribed) {
        setMessage(t("newsletter.alreadySubscribed"))
      } else {
        setMessage(t("newsletter.error"))
      }
      setIsLoading(false)
    }
  
     const handleChange = e => {
        setEmail(e.target.value)
        setMessage()
     }
  
    const handleChangeName = e => {
       setFirstName(e.target.value)
       setMessage()
     }

  return (

    <div className="cms-hero__wrapper__content lead-section ">
        <div className="cms-hero__wrapper__content__wrapper">
            <div className="cms-hero__wrapper__content__image image__wrapper">

                {heroCard.desktopVideo && !isMobile ? (
                <ReactPlayer
                    url={heroCard.desktopVideo}
                    playsinline={true}
                    width="100%"
                    height="100%"
                    muted={true}
                    loop={true}
                    controls={false}
                    className="background-video"
                    playing
                />
                ) : heroCard.mobileVideo && isMobile ? (
                <ReactPlayer
                    url={heroCard.mobileVideo}
                    playsinline={true}
                    width="100%"
                    height="100%"
                    muted={true}
                    loop={true}
                    controls={false}
                    className="background-video"
                    playing
                />
                ) :   <Image
                largeScreenImage={
                    heroCard.desktopImage &&
                    heroCard.desktopImage.filename
                }
                smallScreenImage={
                    heroCard.mobileImage &&
                    heroCard.mobileImage.filename
                }
                alt={
                    heroCard.mobileImage &&
                    heroCard.mobileImage.alt
                }
                layout={"fullWidth"}
                loading="eager"
                />
            }
            </div>
            <div className="cms-hero__wrapper__content__inner">
                <div className="grid-container cms-hero__wrapper__content__inner__container">
                    <div
                    className={cx(
                        "cms-hero__wrapper__content__inner__wrapper",
                        {
                        "cms-hero__wrapper__content__inner__wrapper--center--desktop":
                            heroCard.desktopPositionVertical === "center",
                        },
                        {
                        "cms-hero__wrapper__content__inner__wrapper--top--desktop":
                            heroCard.desktopPositionVertical === "top",
                        },
                        {
                        "cms-hero__wrapper__content__inner__wrapper--bottom--desktop":
                            heroCard.desktopPositionVertical === "bottom",
                        },
                            {
                        "cms-hero__wrapper__content__inner__wrapper--center":
                            heroCard.mobilePositionVertical === "center",
                        },
                        {
                        "cms-hero__wrapper__content__inner__wrapper--top":
                            heroCard.mobilePositionVertical === "top",
                        },
                        {
                        "cms-hero__wrapper__content__inner__wrapper--bottom":
                            heroCard.mobilePositionVertical === "bottom",
                        },
                        {
                        "cms-hero__wrapper__content__inner__wrapper--left--desktop":
                            heroCard.desktopPositionHorizontal === "left",
                        },
                        {
                        "cms-hero__wrapper__content__inner__wrapper--center-h--desktop":
                            heroCard.desktopPositionHorizontal === "center",
                        },
                        {
                        "cms-hero__wrapper__content__inner__wrapper--right--desktop":
                            heroCard.desktopPositionHorizontal === "right",
                        },
                            {
                        "cms-hero__wrapper__content__inner__wrapper--left":
                            heroCard.mobilePositionHorizontal === "left",
                        },
                        {
                        "cms-hero__wrapper__content__inner__wrapper--center-h":
                            heroCard.mobilePositionHorizontal === "center",
                        },
                        {
                        "cms-hero__wrapper__content__inner__wrapper--right":
                            heroCard.mobilePositionHorizontal === "right",
                        },
                        {
                        "cms-hero__wrapper__content__inner__wrapper--blue":
                            heroCard.textBackground === "blue",
                        },
                        {
                        "cms-hero__wrapper__content__inner__wrapper--white":
                            heroCard.textBackground === "white",
                        },
                        {
                        "cms-hero__wrapper__content__inner__wrapper--red":
                            heroCard.textBackground === "red",
                        },
                        {
                        "cms-hero__wrapper__content__inner__wrapper--black":
                            heroCard.textBackground === "black",
                        },
                        {
                        "cms-hero__wrapper__content__inner__wrapper--turquoise":
                            heroCard.textBackground === "turquoise",
                        }
                    )}
                    >
                        <div className="cms-hero__wrapper__content__inner--title">
                            { heroCard.content?.map((text, index) => (
                                <CmsText
                                    key={index}
                                    color={text.color ? text.color : "white"}
                                    text={text.text}
                                    size={text.size}
                                    tag={text.tag ? text.tag : "p"}
                                    fontWeight={text.fontWeight}
                                    lineHeight={text.lineHeight}
                                />
                            ))
                            }
                               <div className="footer-form__form lead-section__form">
                                <form onSubmit={handleSubmit}>
                                <div className="footer-form__inner">
                                    <div className="footer-form__inner-left">
                                    <Textfield
                                        value={firstName}
                                        defaultValue=""
                                        name="name"
                                        label={t("newsletter.firstName")}
                                        useStatic={true}
                                        onChange={handleChangeName}
                                        required={true}
                                        type="text"
                                    />
                                    </div>
                                    <div className="footer-form__inner-left">
                                    <Textfield
                                        value={email}
                                        defaultValue=""
                                        name="email"
                                        label={t("newsletter.email")}
                                        useStatic={true}
                                        onChange={handleChange}
                                        required={true}
                                        type="email"
                                    />
                                    </div>
                                    <div className="footer-form__inner-right">
                                    <Button
                                        buttonText={t("button.send")}
                                        type="submit"
                                        isLoading={isLoading}
                                    />
                                    </div>
                                </div>
                                </form>
                                <p className="lead-section__form__message">{message}</p>
                            </div>
                            <div className="lead-section__disclaimer">
                                { heroCard.disclaimer?.map((text, index) => (
                                        <CmsText
                                            key={index}
                                            color={text.color ? text.color : "white"}
                                            text={text.text}
                                            size={text.size}
                                            tag={text.tag ? text.tag : "p"}
                                            fontWeight={text.fontWeight}
                                            lineHeight={text.lineHeight}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LeadSection
