//Dependencies
import React from "react"

//Styles
import "./mobile-menu.scss"
import cx from "classnames"

//Components
import MobileNavLinks from "../mobile-nav-links/mobile-nav-links"
import MobileInfoLinks from "../mobile-info-links/mobile-info-links"
import Footer from "../../footer/footer"
import Button from "../../../ui/button/button"

const MobileMenu = props => {

  return (
    <div
      className={cx("mobile-menu", {
        "mobile-menu--is-open": props.isOpen,
      })}
    >
      <div className="">
        <div
          className="mobile-menu__inner-overlay"
          onClick={props.mobileNavToggle}
        />
        <div className="mobile-menu__container">
          <div className="mobile-menu__heading">
            <div className="mobile-menu__heading-text">
              {/* {props.navigation.menuLinks[0].title} */}
            </div>
            <Button
              onClick={props.mobileNavToggle}
              className="mobile-menu__heading-button"
              iconOnly={true}
              icon={"close"}
              largeIcon={true}
            />
          </div>
          <div className="mobile-menu__content">
            {props.navigation &&
              props.navigation.menuLinks[0] &&
              props.navigation.menuLinks[0].navLinks &&
              props.navigation.menuLinks[0].navLinks.map((link, index) => (
                <MobileNavLinks meganav={props.meganav.links} props={link} key={index} />
              ))}

            {/* {props.infoLinks.title && (
              <div className="mobile-menu__content__heading">
                {props.infoLinks.title}
              </div>
            )} */}

            <div className="mobile-menu__content__info-links">
              {props.infoLinks &&
                props.infoLinks.infoLinks.map((link, index) => (
                  <MobileInfoLinks props={link} key={index} />
                ))}
            </div>
         
          </div>
          <div className="mobile-menu__footer">
            <Footer
              showAccordion={false}
              showNewsletter={false}
              noBorder={true}
              id="mobile-menu-dropdown"
              mobileMenu={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
