//Dependencies
import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import CmsText from "../../cms/cms-text/cms-text"

//Styles
import "./image-gallery-tile.scss"

//Hooks
import Image from "../../ui/image/image"

const ImageGalleryTile = props => {
  const componentProps = props.blok

  return (
    <section
      className={cx(
        "image-gallery-tile",
        {
          "padding-top": componentProps.paddingTop,
        },
        {
          "padding-bottom": componentProps.paddingBottom,
        }
      )}
    >
      <div className="grid-container">
        <div className="grid__row">
          <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
            <div className="image-gallery-tile__row">
              <div>
                {componentProps.title &&
                  componentProps.title.map((text, index) => (
                    <CmsText
                      key={index}
                      color={text.color ? text.color : "black"}
                      text={text.text}
                      size={text.size}
                      tag={text.tag ? text.tag : "p"}
                      fontWeight={text.fontWeight}
                    />
                  ))}
              </div>
              <div className="image-gallery-tile__links">
                {componentProps.links.map((i, index) => {
                  return (
                    <div className="image-gallery-tile__link" key={index}>
                      {i.linkText}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
  
            <div className="image-gallery-tile__images">
              <div className="image-gallery-tile__images-inner">
                {componentProps.images.map((i, index) => {
                  return (
                    <Link
                      to={`/product/${i.productLink.slug}`}
                      className="image-gallery-tile__images-inner--img image__wrapper"
                      key={index}
                    >
                      <div className="image-gallery-tile__images-inner--img__wrapper">
                      <Image
                        key={index}
                        largeScreenImage={i.largeScreenImage.filename}
                        smallScreenImage={i.smallScreenImage.filename}
                        alt={i.largeScreenImage.alt}
                        layout={"constrained"}
                      />
                      </div>
                    
                    </Link>
                  )
                })}
              </div>
      </div>
    </section>
  )
}

export default ImageGalleryTile
