import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Mousewheel, Scrollbar, Pagination } from "swiper"
import Image from "../ui/image/image"
import CmsText from "../cms/cms-text/cms-text"

//Styles
import "swiper/css"
import "swiper/css/mousewheel"
import "swiper/css/scrollbar"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "./skin-tones-module.scss"
import cx from "classnames"

//Components

SwiperCore.use([Mousewheel, Scrollbar])

const SkinTonesModule = props => {
  const componentProps = props.blok ? props.blok : props.prop

  return (
    <section
      className={cx(
        "skin-tones-module",
        {
          "padding-top": componentProps.paddingTop,
        },
        {
          "padding-bottom": componentProps.paddingBottom,
        }
      )}
    >
      <div className="grid-container">
        <div className="grid__row">
          <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
            <div
              className={cx("skin-tones-module__heading", {
                "skin-tones-module__heading--center":
                  componentProps.centeredTitle,
              })}
            >
              {/* <div className="skin-tones-module__heading-heading">
                <h2>{componentProps.title}</h2>
              </div> */}
              {componentProps.heading &&
                componentProps.heading.map((text, index) => (
                  <CmsText
                    key={index}
                    color={text.color ? text.color : "black"}
                    text={text.text}
                    size={text.size}
                    tag={text.tag ? text.tag : "p"}
                    fontWeight={text.fontWeight}
                  />
                ))}
            </div>
            <Swiper
              className="skin-tones-module__swiper"
              spaceBetween={16}
              direction={"horizontal"}
              loop={false}
              pagination={true}
              modules={[Pagination]}
              mousewheel={{
                releaseOnEdges: true,
                forceToAxis: true,
              }}
              breakpoints={{
                1100: {
                  spaceBetween: 16,
                  slidesPerView: 5,
                  slidesPerGroup: 1,
                },
                900: {
                  spaceBetween: 16,
                  slidesPerView: 3,
                  slidesPerGroup: 1,
                },
                0: {
                  spaceBetween: 8,
                  slidesPerView: 2,
                  slidesPerGroup: 1,
                },
              }}
              slidesPerView={1}
              slidesPerGroup={1}
              touchReleaseOnEdges={true}
              grabCursor={true}
              scrollbar={{
                hide: false,
                dragSize: "auto",
                draggable: true,
              }}
            >
              {componentProps.slides.map((slide, index) => (
                <SwiperSlide
                  className="skin-tones-module__swiper-slide"
                  key={index}
                >
                  <div className="skin-tones-module__swiper-slide__image image__wrapper">
                    <Image
                      largeScreenImage={slide.largeScreenImage.filename}
                      smallScreenImage={slide.smallScreenImage.filename}
                      alt={slide.largeScreenImage.alt}
                      //layout={"constrained"}
                      layout={"fullWidth"}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SkinTonesModule
