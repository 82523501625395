// import { useEffect, useState } from "react"
// import React from "react"

// const useMediaQuery = query => {
//   const getMatches = query => {
//     // Prevents SSR issues
//     if (typeof window !== "undefined") {
//       return window.matchMedia(query).matches
//     }
//     return false
//   }

//   const [matches, setMatches] = useState(getMatches(query))

//   function handleChange() {
//     setMatches(getMatches(query))
//   }

//   useEffect(() => {
//     const matchMedia = window.matchMedia(query)

//     // Triggered at the first client-side load and if query changes
//     handleChange()

//     matchMedia.addEventListener("change", handleChange)

//     return () => {
//       matchMedia.removeEventListener("change", handleChange)
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [query])

//   return matches
// }

// export default useMediaQuery

import * as React from "react";

const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;

const useMediaQuery = (width) => {
  const [targetReached, setTargetReached] = React.useState(false);

  const updateTarget = React.useCallback((e) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`);
    media.addListener(updateTarget);

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true);
    }

    return () => media.removeListener(updateTarget);
  }, []);

  return targetReached;

}
export default useMediaQuery