import React from "react"
import StoryblokClient from "storyblok-js-client"

const RichTextResolver = ({ data, styleClass, children }) => {
  let Storyblok = new StoryblokClient({
    accessToken: "",
  })

  const createMarkup = storyblokHTML => {
    return {
      __html: Storyblok.richTextResolver.render(storyblokHTML),
    }
  }

  return (
    <div className={styleClass} dangerouslySetInnerHTML={createMarkup(data)} />
  )
}

export default RichTextResolver
