// Dependency
import React, { useState, useEffect } from "react"
import cx from "classnames"
import { storyblokEditable } from "gatsby-source-storyblok"
import { useTranslation } from "react-i18next"

// Styles
import "./tabs.scss"

// Hooks
import RichTextResolver from "../../../helpers/storyblok/richTextResolver"
import SvgIcon from "../../../hooks/svg"

// Template
const Tabs = props => {
  const componentProps = props.blok ? props.blok : props.props
  const [activeTab, setActiveTab] = useState(componentProps.tabs[0]._uid)
  const [checkTabLength, setCheckTabLength] = useState(false)
  const [checkTabHeight, setCheckTabHeight] = useState(false)
  const { t } = useTranslation()

 
  const toggleTab = (tabTop, tabBottom) => {
    //Transform animation
    tabBottom.offsetParent === null ? tabTop.classList.add("cd-product-tabs-opened") : tabTop.classList.remove("cd-product-tabs-opened");
    //Toggle visibillity
    tabBottom.style = tabBottom.offsetParent === null ? 'display: block; max-height: 1700px;' : 'display: none; max-height: 0;';
  }

  return (
    <div {...storyblokEditable(props.props)}>
      <div className="tabs">
      {componentProps &&
            componentProps.tabs &&
            componentProps.tabs.map((tab, index) => {
              return (
        <div className={"cd-single-product-tabs"}>
          <div className={"cd-single-product-tab-top"} onClick={(e) => {
            toggleTab(e.currentTarget, e.currentTarget.nextElementSibling)
          }}>
            <span>{tab.title}</span>
            <SvgIcon name="add" />
          </div>
          <div className={"cd-product-tab-content"}>
            <RichTextResolver
                data={tab.content}
              />
          </div>
        </div>
             )
            })}
            </div>
    </div>
  )
}
export default Tabs
