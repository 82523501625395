//Dependencies
import React from "react"

//Hooks
import StoryblokImage from "../../../hooks/storyblok-image"
import useMediaQuery from "../../../hooks/useMediaQuery"

const Image = props => {
  const isTabletOrMobile = useMediaQuery(992)
  return !isTabletOrMobile
    ? props.largeScreenImage && (
        <StoryblokImage
          height={props.largeScreenHeight}
          width={props.largeScreenWidth}
          alt={props.alt}
          layout={props.layout}
          image={props.largeScreenImage}
          loading={props.loading}
        />
      )
    : props.smallScreenImage && (
        <StoryblokImage
          alt={props.alt}
          height={props.smallScreenHeight}
          width={props.smallScreenWidth}
          layout={props.layout}
          image={props.smallScreenImage}
          loading={props.loading}
        />
      )
}

export default Image
