// Dependency
import React from "react"

// Styles
import "./loader.scss"

// Template
const Loader = () => {
  return (
    <div className="loader">
      <div className="loader__icon"></div>
    </div>
  )
}
export default Loader
