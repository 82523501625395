//Dependencies
import React from "react"

//Style
import "./text-block-image.scss"
import cx from "classnames"

//Hooks
import CmsText from "../../cms/cms-text/cms-text"
import Image from "../../ui/image/image"
import useMediaQuery from "../../../hooks/useMediaQuery"

const TextBlockImage = props => {
  const componentProps = props.blok ? props.blok : props.props
  const Tag = componentProps && componentProps.fullWidth ? "section" : "div"
  const isMobile = useMediaQuery(576)

  const renderContent = () => {
    return (
      <div
        className={cx(
          {
            "padding-top": componentProps.paddingTop,
          },
          {
            "padding-bottom": !isMobile && componentProps.paddingBottom,
          },
          {
            "text-block-image__container--center":
              componentProps.centerAlignedContent,
          }
        )}
      >
        <h3
          className={cx("text-block-image__title", {
            "text-block-image__title--small": !componentProps.fullWidth,
          })}
        >
          {componentProps.title}
        </h3>
        <div
          className={cx("text-block-image__inner", {
            "text-block-image__inner--right":
              componentProps.imageAlignment === "right",
            "text-block-image__inner--top":
              componentProps.imageAlignment === "top",
            "text-block-image__inner--bottom":
              componentProps.imageAlignment === "bottom",
            "text-block-image__inner--center":
              componentProps.centerAlignedContent,
          })}
        >
          <div className="text-block-image__image">
            <Image
              largeScreenImage={componentProps.largeScreenImage.filename}
              largeScreenHeight={props.largeHeight}
              largeScreenWidth={props.largeWidth}
              smallScreenImage={componentProps.smallScreenImage.filename}
              smallScreenHeight={props.smallHeight}
              smallScreenWidth={props.smallWidth}
              alt={componentProps.largeScreenImage.alt}
              layout={"constrained"}
            />
          </div>
          <div className="text-block-image__description">
            {componentProps.texts &&
              componentProps.texts.map((text, index) => (
                <div key={index} className="text-block-image__content">
                  {text.component === "link" ? (
                    <>
                      <div className="text-block-image__content--gap--top" />
                      <a
                        href={text.link.cached_url}
                        className="text-block-image__content--link"
                      >
                        {text.linkText}
                      </a>
                      <div className="text-block-image__content--gap--bottom" />
                    </>
                  ) : (
                    <CmsText
                      color={text.color}
                      text={text.text}
                      size={text.size}
                      tag={text.tag ? text.tag : "p"}
                      fontWeight={text.fontWeight}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }
  return (
    <Tag className="text-block-image">
      {componentProps.fullWidth ? (
        renderContent()
      ) : (
        <div className="grid-container">
          <div className="grid__row">
            <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
              {renderContent()}
            </div>
          </div>
        </div>
      )}
    </Tag>
  )
}

export default TextBlockImage
