// Dependency
import React from "react"
import Image from "../../ui/image/image"
import RenderLink from "../../ui/link/link"
import Button from "../../ui/button/button"
import cx from "classnames"

// Styles
import "./image-title-link.scss"

// Template
const ImageTitleLink = props => {
  const componentProps = props.blok
    ? props.blok
    : props.props
    ? props.props
    : props

  return (
    <div className="image-title-link" id="image-title-link">
      <RenderLink
        linkUrl={componentProps.link.url || "#"}
        linkText=<div className="image-title-link__inner">
          <div className="image-title-link__image image__wrapper">
            <Image
              largeScreenImage={componentProps.largeScreenImage.filename}
              largeScreenHeight={"auto"}
              largeScreenWidth={"auto"}
              smallScreenImage={componentProps.smallScreenImage.filename}
              smallScreenHeight={"auto"}
              smallScreenWidth={"auto"}
              alt={componentProps.largeScreenImage.alt}
              layout={"constrained"}
            />
          </div>
          <div className="image-title-link__wrapper">
            <div>
              <div
                className={cx("image-title-link__title", {
                  "image-title-link__title__small": true,
                })}
              >
                {componentProps.title}
              </div>
              {componentProps.linkText && (
                <p className="image-title-link__title__link">
                  {componentProps.linkText}
                </p>
              )}
            </div>
            <div className="image-title-link__link">
              <Button
                buttonText={componentProps.link.linkText}
                asset="arrow_forward_ios"
                assetHeight={20}
                iconOnly={true}
                transparetBg={true}
              />
            </div>
          </div>
        </div>
      />
    </div>
  )
}
export default ImageTitleLink
