import Dinero from "dinero.js"
import { getPrice } from "./tracking"

export const getKlaviyoListIds = (countryCode) => {
    try {
        const env = process.env.GATSBY_BUILD_ENV || "test"
        const klaviyoListIds = {
            test: {
                default: "TXbeMh"
            },
            production: {
                gb: "TSvBgc",
                se: "RVEXki",
                dk: "QWaKXi",
                de: "SAd5YJ",
                fi: "ThzbSq",
                no: "Ttrcz3",
                nl: "RMSjEW",
                default: "XGmwkp"
            }
        }
        const key = klaviyoListIds[env][countryCode] ?? klaviyoListIds[env].default
        if (!key) console.error("Missing Klaviyo List ID")
        return key
    } catch (error) {
        console.error("Missing Klaviyo List ID")
        console.error(error)
    }
}

export const getKlaviyoApiKey = (countryCode) => {
    try {
        const env = process.env.GATSBY_BUILD_ENV || "test"
        const klaviyoApiKeys = {
            develop: {
                default: "Rpii3c"
            },
            production: {
                gb: "RBBGcT",
                se: "WQ3imS",
                dk: "TqCiad",
                de: "UQpE6C",
                fi: "WEX3MB",
                no: "TF7jeK",
                nl: "WLbybm",
                default: "QPNmik"
            }
        }
        const key = klaviyoApiKeys[env][countryCode] ?? klaviyoApiKeys[env].default
        if (!key) console.error("Missing Klaviyo Apikey")
        return key
    } catch (error) {
        console.error("Missing Klaviyo Apikey")
        console.error(error)
    }
}

const toKlaviyoItems = (items, currencyUnit) => {
    return items.map((item) => ({
        ProductID: item.id,
        ProductName: item.name,
        Quantity: item.quantity,
        ItemPrice: toDinero(item.price[currencyUnit], currencyUnit).toUnit(),
        RowTotal: toDinero(item.price[currencyUnit], currencyUnit)
            .multiply(item.quantity)
            .toUnit(),
        ImageURL: item.imageUrl,
        ProductURL: `${window.location.origin}/product/${item.slug}`
    }))
}

const toDinero = (amount, currencyUnit) => {
    return new Dinero({ amount: amount, currency: currencyUnit })
}

export const toViewedProduct = (item, currencyUnit) => {
    return {
        ProductName: item.name,
        ProductID: item.id,
        LanguageCode: process.env.GATSBY_BUILD_LANGUAGE,
        URL: `${window.location.href}`,
        Price: toDinero(getPrice(item.price, currencyUnit).amount, currencyUnit).toUnit()
    }
}

export const addedToCart = (item, cart) => {
    if (item.type !== "productVariant") return
    //console.log(item.price)
    const klaviyoCart = {
        $value: toDinero(
            cart.totalPriceWithDiscount,
            cart.store.currencyUnit
        ).toUnit(),
        AddedItemProductName: item.name,
        AddedItemProductID: item.id,
        AddedItemImageURL: item.imageUrl,
        AddedItemPrice: toDinero(getPrice(item.price, cart.store.currencyUnit).amount).toUnit(),
        AddedItemQuantity: 1,
        CountryCode: cart.store.countryCode,
        ItemNames: cart.cartItems.map((ct) => ct.name),
        CheckoutURL: `${window.location.origin}/checkout`,
        Items: toKlaviyoItems(cart.cartItems, cart.store.currencyUnit)
    }

    return klaviyoCart

}

export const startedCheckout = (cart, BrinkSessionId) => {
    const cartProductVariants = cart.cartItems.filter(
        (i) => i.type === "productVariant"
    )
    return {
        $event_id: `${BrinkSessionId}_${new Date().valueOf()}`,
        $value: toDinero(
            cart.totalPriceWithDiscount,
            cart.store.currencyUnit
        ).toUnit(),
        CountryCode: cart.store.countryCode,
        ItemNames: cartProductVariants.map((ct) => ct.name),
        CheckoutURL: `${window.location.origin}/checkout/`,
        Items: toKlaviyoItems(cartProductVariants, cart.store.currencyUnit)
    }
}


const toBillingAddress = (billingAddress) => ({
    email: billingAddress.email,
    firstName: billingAddress.given_name,
    lastName: billingAddress.family_name,
    phone: billingAddress.phone,
    address: billingAddress.street_address,
    houseNumberOrName: billingAddress.street_address2,
    city: billingAddress.city,
    postalCode: billingAddress.postal_code,
    region: "",
    country: billingAddress.country
})

const toKlaviyoAddress = (address) => {
    return {
        email: address.email,
        first_name: address.firstName,
        last_name: address.lastName,
        phone_number: address.phone,
        address1: address.address,
        address2: address.houseNumberOrName || "",
        city: address.city,
        zip: address.postalCode,
        region: "",
        country: address.country
    }
}

const toDiscountValue = (order, currencyUnit) => {
    if (order.merchant_reference2) {
        return toDinero(
            order.order_lines
                .filter((o) => o.type === "physical")
                .reduce(
                    (result, current) => (result += current.total_discount_amount),
                    0
                ),
            currencyUnit
        ).toUnit()
    } else {
        return toDinero(order.orderAmount, currencyUnit)
            .subtract(toDinero(order.orderAmountWithDiscount, currencyUnit))
            .toUnit()

    }
}

export const placedOrder = (order, GAOrder, cart, discountCode) => {
    const billingAddress = order.merchant_reference2 ? toBillingAddress(order.billing_address) : order.billingAddress
    return {
        customer_properties: {
            $email: billingAddress.email,
            $first_name: billingAddress.firstName,
            $last_name: billingAddress.lastName,
            $phone_number: billingAddress.phone,
            $address1: billingAddress.address,
            $address2: billingAddress.houseNumberOrName || "",
            $city: billingAddress.city,
            $zip: billingAddress.postalCode,
            $region: "",
            $country: billingAddress.country
        },
        properties: {
            $event_id: order.merchant_reference2 ? order.order_number : order.reference,
            $value: toDinero(
                order ? order.orderAmountWithDiscount || order.order_amount : 0,
                cart.store.currencyUnit
            ).toUnit(),
            CountryCode: cart.store.countryCode,
            DiscountCode: discountCode || "",
            DiscountValue: toDiscountValue(order, cart.store.currencyUnit),
            ItemNames: cart.cartItems.map((ct) => ct.name),
            Items: toKlaviyoItems(cart.cartItems, cart.store.currencyUnit)
        },
        BillingAddress: toKlaviyoAddress(billingAddress),
        ShippingAddress: toKlaviyoAddress(billingAddress)
    }
}

export const orderedProducts = (item, order, cart) => {
    const billingAddress = order.merchant_reference2 ? toBillingAddress(order.billing_address) : order.billingAddress

    return {
        customer_properties: {
            $email: billingAddress.email,
            $first_name: billingAddress.firstName,
            $last_name: billingAddress.lastName,
            $country: billingAddress.country
        },
        properties: {
            $event_id: order.merchant_reference2 ? order.order_number : order.reference,
            OrderId: order.merchant_reference2 ? order.order_number : order.reference,
            $value: toDinero(item.price[cart.store.currencyUnit], cart.store.currencyUnit).toUnit(),
            ProductID: item.id,
            ProductName: item.name,
            Quantity: item.quantity,
            ImageURL: item.imageUrl,
            Categories: [item.category],
            ProductURL: `${window.location.origin}/product/${item.slug}`
        }
    }
}