//Dependencies
import React, { useLayoutEffect } from "react"

//Styles
import "./cart.scss"
import cx from "classnames"

//Components
import Button from "../../ui/button/button"
import { useBrink } from "../../../context/BrinkContext"
import { useTranslation } from "react-i18next"
import CartItem from "../cart-item/cart-item"
import Price from "../../general/price/price"
import RenderLink from "../../ui/link/link"
import CartUpsellCarousel from "../cart-upsell-carousel/cart-upsell-carousel"

const Cart = props => {
  const { t } = useTranslation()
  const {
    cart,
    isCartOpen,
    setIsCartOpen,
    store: { currencyUnit },
  } = useBrink()

  const cartItems = cart
    ? cart.cartItems.filter(item => item.type !== "shippingOption")
    : []

  const storeTax = cart ? cart.store?.taxPercentage / 100 ?? 0 : 0

  const vat = cart
    ? ((cart.totalPriceWithDiscount / (storeTax + 1)) * storeTax) | 0
    : 0

  useLayoutEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        setIsCartOpen(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [isCartOpen, setIsCartOpen])

  return (
    <div
      className={cx("cart", {
        "cart--is-open": isCartOpen,
      })}
    >
      <div
        className="cart__inner-overlay"
        onClick={() => {
          setIsCartOpen(false)
        }}
      />
      <div className="cart__inner">
        <div className="cart__heading">
          <Button
            onClick={() => {
              setIsCartOpen(false)
            }}
            className="cart__heading-btn"
            iconOnly={true}
            transparetBg={true}
            icon={"close"}
          />
          <div className="cart__heading-title">{t("cart.yourCart")}</div>
        </div>
        <div className="cart-content">
          <div className="cart-content__mini-cart">
            <div className="cart-content__mini-cart__top">
              <CartItem cartItems={cartItems} controls={true} />

              {cartItems.length === 0 && (
                <div className="cart-content__empty-heading">
                  {t("cart.empty")}
                </div>
              )}
            </div>
            <div className="cart-content__mini-cart__upsell">
              {cartItems.length !== 0 && props.cart && (
                <div className="cart-content__upsell__carousel">
                  <CartUpsellCarousel props={props.cart} />
                </div>
              )}
            </div>
          </div>
          <div className="cart-content--wrapper">
            {cartItems.length !== 0 && (
              <div className="cart-content--wrapper">
                <div className="cart-content__summary cart-content__summary--mini-cart">
                  <div className="cart-content__summary-line">
                    {t("cart.price")}
                    <span>
                      <Price
                        prices={[
                          {
                            amount: cart.totalPrice,
                            currencyUnit: currencyUnit,
                          },
                        ]}
                      />
                    </span>
                  </div>
                  <div className="cart-content__summary-line">
                    {t("cart.shipping")}
                    <span>{t("cart.shippingCalculated")}</span>
                  </div>
                  <div className="cart-content__summary-line">
                    {t("cart.vat")}
                    <span>
                      <Price
                        prices={[
                          {
                            amount: vat,
                            currencyUnit: currencyUnit,
                          },
                        ]}
                      />
                    </span>
                  </div>
                  {props.cart.totalDiscountAmount && (
                    <div className="cart-content__summary-line cart-content__summary-line--discount">
                      {t("cart.discount")}
                      <span>
                        <Price
                          prices={[
                            {
                              amount: cart.totalDiscountAmount,
                              currencyUnit: currencyUnit,
                            },
                          ]}
                        />
                      </span>
                    </div>
                  )}
                  <div className="cart-content__sub-total">
                    {t("cart.subtotal")}
                    <span>
                      <Price
                        prices={[
                          {
                            amount: cart.totalPriceWithDiscount,
                            currencyUnit: currencyUnit,
                          },
                        ]}
                      />
                    </span>
                  </div>
                </div>
                <div className="cart__checkout-button">
                  {cartItems.length > 0 && (
                    <RenderLink
                      asset="arrow-right-white"
                      linkText={t("cart.checkout")}
                      linkUrl="/checkout"
                      buttonLook="green"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cart
