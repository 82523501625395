import "./notify-me-modal.scss"
import React, { useState } from "react"
import cx from "classnames"
import { handleRestock } from "../../context/utils/events/klaviyo"
import { useTranslation } from "react-i18next"
import SvgIcon from "../../hooks/svg"

const NotifyMeModal = ({ openNotifyMe, toggleNotifyMe, selectedVariant }) => {
  const [email, setEmail] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)
  const { t } = useTranslation()

  const handleSubmit = async () => {
    const validEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    if (email.match(validEmail)) {
      console.log("email is valid")

      const response = await handleRestock(email, selectedVariant)
      //console.log reponse
      if (response.success) {
        console.log(response)
        setSubmitted(true)
        setError(false)
      } else {
        console.log("error")
      }
    } else {
      setError(true)
      setSubmitted(false)
      console.log("email is not valid")
    }
  }

  const handleSubmitEnterClick = e => {
    if (e.key === "Enter") {
      handleSubmit()
    }
  }

  return (
    <div className={cx("notify-me", { "notify-me--open": openNotifyMe })}>
      <div
        className="notify-me--overlay"
        onClick={() => {
          toggleNotifyMe(selectedVariant)
        }}
      ></div>
      <div className="notify-me-content">
        <div className="notify-me--image">
          <div
            onClick={() => {
              toggleNotifyMe(selectedVariant)
            }}
            className="notify-me--close"
          >
            <SvgIcon className="notify-me--icon" name="close" alt="close" />
          </div>
        </div>
        <div className="notify-me--details">
          {submitted ? (
            <>
              <h2>{t("notifyMe.success")}</h2>
              <p>{t("notifyMe.monitoring")}</p>
            </>
          ) : (
            <>
              <h2>{t("notifyMe.heading")}</h2>
              <p>{t("notifyMe.description")}</p>
              <div className="text-input">
                <input
                  onKeyDown={e => handleSubmitEnterClick(e)}
                  placeholder={t("notifyMe.placeholder")}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
              <button
                onClick={() => {
                  handleSubmit()
                }}
                className="button button-black"
              >
                {t("notifyMe.notify")}
              </button>
            </>
          )}
        </div>

        {error && (
          <div className="notify-me-message">
            <p>{t("notifyMe.error")}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default NotifyMeModal
