const dispatchEvent = props => event => {
  try {
    event(props)
  } catch (error) {
    console.error("events:", error)
  }
}

export const signup = ({ listId, status, amount, currentStore }) =>
  dispatchEvent({
    listId,
    status,
    amount,
    currentStore,
  })
