// Dependency
import React, { useState, useEffect } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Button from "../../ui/button/button"
import BulletsIcons from "../bullets-icons/bullets-icons"
import { useTranslation } from "react-i18next"
import useMediaQuery from "../../../hooks/useMediaQuery"
import SvgIcon from "../../../hooks/svg"
import useLipscoreReviews from "../../../hooks/useLipscoreReviews"
import Breadcrumbs from "../breadcrumbs/breadcrumbs"
import useTestFreakApi from "../../../hooks/useTestFreakApi"
import ReviewStarAmount from "../review-star-amount/reviewStarAmount"
import Tabs from "../tabs/tabs"
import LipscoreWidget from "../../general/lipscore-widget/lipscore-widget"


// Styles
import "./info-card.scss"
import Price from "../price/price"
import { useBrink } from "../../../context/BrinkContext"
import GA4 from "../../../services/tracking"

// Template
const InfoCard = props => {
  const query = useStaticQuery(graphql`
    query {
      allStoryblokEntry(
        filter: { field_component: { in: ["productPerk", "paymentLogo"] } }
      ) {
        nodes {
          content
          name
          field_component
          full_slug
        }
      }
    }
  `)

  const perks = query.allStoryblokEntry.nodes.filter(
    node =>
      node.field_component === "productPerk" &&
      node.full_slug.includes("new-site") &&
      node.full_slug.includes(process.env.GATSBY_BUILD_LANGUAGE)
  )

  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [inStock, setInStock] = useState()

  const isTabletOrMobile = useMediaQuery(1024)
  const componentProps = props.blok
    ? props.blok
    : props.props
    ? props.props
    : props

  const { addToCart } = useBrink()

  const { lipscoreReviews, lipscoreStars } = useLipscoreReviews({
    internal_id: props.brink.getProduct.id,
  })

  useEffect(() => {
    const getTheStocks = async () => {
      setIsLoading(true)
      setInStock(true)
      setIsLoading(false)
    }
    getTheStocks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddToCart = async () => {
    setIsLoading(true)
    const cartResponse = await addToCart({
      productVariantId: props.brink.getProduct.id,
      quantity: 1,
      options: [
        {
          key: "url",
          value: `https://tanrevel.${process.env.GATSBY_BUILD_MARKET}/product/${props.brink.getProduct.slug}`,
        },
      ],
      openCart: true,
    })
    GA4.addToCart(props.brink.getProduct, {
      listName: "PDP",
      cart: cartResponse.cart,
    })
    setIsLoading(false)
  }

  const reviews = useTestFreakApi("A-4210-OR")

  const reviewsActive = false

  // // STICKY ATC
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const topBorder = document
  //       .getElementById("atc-sticky-trigger")
  //       .getBoundingClientRect().top
  //     topBorder >= 0
  //       ? document
  //         .getElementById("atc-sticky")
  //         .classList.remove("atc-sticky--fixed")
  //       : document
  //         .getElementById("atc-sticky")
  //         .classList.add("atc-sticky--fixed")
  //   }
  //   window.addEventListener("scroll", handleScroll)
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll)
  //   }
  // }, [])

  const links = [{ title: t("listing.breadCrumbsProducts"), link: "products" }]

  const tabs = props.productDescription?.components.find(
    i => i.component === "tabs"
  )

  return (
    <section className="info-card" id="info-card">
      <div className="info-card__breadcrumbs">
        <Breadcrumbs breadTitle={componentProps.title} links={links} />
      </div>
      <div>
        <div className="info-card__title-wrapper">
          <h1 className="info-card__title">{componentProps.title}</h1>
          <div className="info-card__price">
            <Price
              prices={props.brink.getProduct.price}
              discounts={props.brink.getProduct.discount}
            />
          </div>
        </div>

        {/* <div className="info-card__price-wrapper">
          <div>

          </div>
          <div className="info-card__delivery">

            {typeof inStock === undefined && <Loader />}
            {typeof inStock !== undefined && (
              <div
                className={cx("info-card__stock", {
                  "info-card__stock--not": componentProps.outOfStock,
                  "info-card__stock--yes": inStock,
                })}
              >
                {!componentProps.outOfStock
                  ? t("product.inStock")
                  : t("product.outOfStock")}
              </div>
            )}
            <div className="info-card__delivery-text">
              <SvgIcon name="truck" />
              <p>{t("product.deliveryInfo")}</p>
            </div>
          </div>
        </div> */}

        <div className="info-card__button-wrapper">
          <div className="info-card__button-wrapper__grid">
            {!isTabletOrMobile && lipscoreReviews && lipscoreReviews.length ? (
              <ReviewStarAmount
                starAmount={lipscoreStars}
                reviewCount={lipscoreReviews.length}
              />
            ) : undefined}
            {perks ? (
              <div className="info-card__perks">
                {perks.map((perk, index) => (
                  <div key={index} className="info-card__perks__item">
                    <div className="info-card__perks__item-icon">
                      <SvgIcon name="check" />
                    </div>
                    <p>{JSON.parse(perk.content).text}</p>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
          {componentProps.outOfStock ? (
            <div className="info-card__button-wrapper__notify-me">
              <Button
                buttonText={t("product.notifyMe")}
                onClick={() => {
                  props.toggleNotifyMe()
                }}
                isLoading={isLoading}
              />
            </div>
          ) : (
            <Button
              buttonText={
                !componentProps.outOfStock
                  ? t("product.addToCart")
                  : t("product.outOfStock")
              }
              disabled={componentProps.outOfStock}
              success={!componentProps.outOfStock ? true : false}
              onClick={() => {
                handleAddToCart()
              }}
              isLoading={isLoading}
              icon="bag-new"
              largeIcon={true}
            />
          )}
          
            <div className="info-card__button-wrapper__mobile_rating">
              <LipscoreWidget
                className="product-card__hidden"
                lipscoreId="lipscore-rating"
                productId={props.brink.getProduct.id}
              />
              {isTabletOrMobile && lipscoreReviews && lipscoreReviews.length ? (
                <ReviewStarAmount
                  starAmount={lipscoreStars}
                  reviewCount={lipscoreReviews.length}
                />
                  ) : undefined}
            </div>

          {componentProps.temporaryAtcText && (
            <Link
              to={componentProps.temporaryAtcLink}
              className="info-card__button-wrapper__temporary-link button button--success"
            >
              <span>{componentProps.temporaryAtcText}</span>
            </Link>
          )}

          {/* {paymentLogo ? (
          <div className="info-card__banner">
            <p>{t("perk.paysafe")}</p>
            {paymentLogo.map((logo, index) => (
              <div className="info-card__banner__logo" key={index}>
                <Image
                  largeScreenImage={
                    JSON.parse(logo.content).image[0].largeScreenImage.filename
                  }
                  largeScreenHeight={10}
                  smallScreenImage={
                    JSON.parse(logo.content).image[0].smallScreenImage.filename
                  }
                  smallScreenHeight={10}
                  alt={JSON.parse(logo.content).image[0].largeScreenImage.alt}
                  layout={"fixed"}
                />
              </div>
            ))}
          </div>
        ) : (
          ""
        )} */}
        </div>
        <div className="info-card__description">
          <h4>{componentProps.heading}</h4>
          <p>{componentProps.description}</p>
          <div className="info-card__bullets">
            <BulletsIcons props={componentProps.bulletPoints[0]} />
          </div>
        </div>

        {tabs ? <Tabs props={tabs} /> : ""}
      </div>

      {/* <div className="atc-sticky" id="atc-sticky">
        <div className="atc-sticky__top">
          <h4>{componentProps.title}</h4>
          <Price
            prices={props.brink.getProduct.price}
            discounts={props.brink.getProduct.discount}
          />
        </div>
        <div className="atc-sticky__button">
          {componentProps.outOfStock ? (
            <Button
              buttonText={t("product.notifyMe")}
              onClick={() => {
                props.toggleNotifyMe()
              }}
              isLoading={isLoading}
            />
          ) : (
            <Button
              buttonText={
                !componentProps.outOfStock
                  ? t("product.addToCart")
                  : t("product.outOfStock")
              }
              disabled={componentProps.outOfStock}
              success={!componentProps.outOfStock ? true : false}
              onClick={() => {
                handleAddToCart()
              }}
              isLoading={isLoading}
            />
          )}
        </div>
        {perks ? (
          <div className="atc-sticky__perks">
            {perks.slice(0, 2).map((perk, index) => (
              <div key={index} className="info-card__perks__item">
                <div className="info-card__perks__item-icon">
                  <SvgIcon name="check-round" />
                </div>
                <p>{JSON.parse(perk.content).text}</p>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </div> */}
    </section>
  )
}
export default InfoCard
