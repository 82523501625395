import React from "react"
import { I18nextProvider } from "react-i18next"
import { i18config } from "./src/internationalization/i18n"
import ModalContextProvider from "./src/context/ModalContext"
import BrinkContextProvider from "./src/context/BrinkContext"
import Layout from "./src/components/layouts"
//import Layout from "./src/components/layouts"

//Wrap context in page or root?
export const wrapPageElement = ({ element, props }) => {
  const store = props.pageContext.store || {
    currencyUnit: "SEK",
    languageCode: "sv",
    countryCode: "SE",
    taxPercentage: 25,
  }

  return (
    <I18nextProvider i18n={i18config(props.pageContext.translations)}>
      <BrinkContextProvider staticStore={store} location={props.location}>
        <ModalContextProvider>
          <Layout {...props}>{element}</Layout>
        </ModalContextProvider>
      </BrinkContextProvider>
    </I18nextProvider>
  )
}

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log("new pathname", location.pathname)
  // console.log("old pathname", prevLocation ? prevLocation.pathname : null)
  if (typeof window !== "undefined") {
    window.dataLayer.push({
      event: "pageView",
      page: location.pathname,
    })
  }
}

// //Wrap Layout around all pages
// exports.wrapPageElement = ({ element, props }) => {
//     return <Layout {...props}>{element}</Layout>
// }
