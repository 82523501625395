// Dependency
import React from "react"
import RenderLink from "../../../ui/link/link"
import Accordion from "../../accordion/accordion"

// Styles
import "./footer-links.scss"

// Template
const FooterLinks = props => {
  return (
    <div className="footer-links">
      <div className="footer-links__inner">
        {!props.isTabletOrMobile &&
          props.footerLinks.sections.map((section, index) => {
            return (
              <div className="footer-links__columns" key={index}>
                <div className="footer-links__heading">{section.heading}</div>
                {section.sections.map((link, index) => {
                  return (
                    <div className="footer-links__col" key={index}>
                      <RenderLink
                        linkText={link.linkText}
                        linkUrl={link.link.url}
                      />
                    </div>
                  )
                })}
              </div>
            )
          })}
      </div>
      {props.isTabletOrMobile &&
        props.footerLinks.sections.map((section, index) => (
          <Accordion
            accordionTitle={section.heading}
            accordionContent={section.sections}
            contentType="linksList"
            key={index}
            class="footer-links__col"
            accordionStyle="page"
          />
        ))}
    </div>
  )
}

export default FooterLinks
