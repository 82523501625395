//Dependencies
import React, { useState } from "react"
import cx from "classnames"
import loadable from "@loadable/component"
import makeAnimated from "react-select/animated"

//Styles
import "./react-select.scss"

const Select = loadable(() => import("react-select"))

const selectStyles = open => ({
  singleValue: provided => ({ ...provided }),
  menu: provided => ({
    ...provided,
    marginTop: 0,
    borderwidth: 10,
    fontSize: 12,
    height: open ? "100px" : "0px",
    overflow: "hidden",
    opacity: open ? 1 : 0,
    transition: "all 0.3s ease-in-out",
    visibility: open ? "visible" : "hidden",
  }),
})

const animatedComponents = makeAnimated()

const ReactSelect = props => {
  const [open, setOpen] = useState(false)
  const componentProps = props
  return (
    <div
      onClick={() => setOpen(!open)}
      className={cx("react-select__wrap", {
        "react-select__wrap--no-border": componentProps.noBorder,
        "react-select__wrap--filter": componentProps.type === "filter",
      })}
    >
      {componentProps.options && (
        <>
          <Select
            name="react-select"
            label={`sel-${componentProps.id}`}
            aria-label={`sel-${componentProps.id}`}
            aria-labelledby={`sel-${componentProps.id}`}
            id={componentProps.id}
            options={componentProps.options}
            className="react-select"
            classNamePrefix="react-select"
            isSearchable={false}
            onBlur={() => setOpen(false)}
            menuIsOpen
            styles={selectStyles(open)}
            isMulti={componentProps.isMulti}
            components={animatedComponents}
            onChange={componentProps.handleChange}
            value={componentProps.defaultValue}
            formatOptionLabel={componentProps.formatOptionLabel}
            menuShouldScrollIntoView={false}
            formatGroupLabel={`sel-${componentProps.id}`}
          />
        </>
      )}
    </div>
  )
}

export default ReactSelect
