// Dependency
import React, { useEffect, useState, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import { useBrink } from "../../../context/BrinkContext"
import Button from "../../ui/button/button"
import cx from "classnames"

// Hooks
import useMediaQuery from "../../../hooks/useMediaQuery"
import { useTranslation } from "react-i18next"

// Components
import StaticBanner from "../staticbanner/staticbanner"
import Navbar from "./navbar/navbar"
import Cart from "../../cart/cart/cart"
import MobileMenu from "./mobile-menu/mobile-menu"
import RenderLink from "../../ui/link/link"
import Marquee from "./marquee/marquee"

// Styles
import "./header.scss"
import UspSection from "../usp-section/usp-section"
import ReviewBlock from "../review-block/review-block"
import CtaHeader from "../cta-header/cta-header"
import SvgIcon from "../../../hooks/svg"

// Template
const Header = props => {
  const isTabletOrMobile = useMediaQuery(1025)
  const { setIsCartOpen, cartQuantity } = useBrink()
  const [checkTime, setCheckTimer] = useState(false)
  const componentProps = props.blok
    ? props.blok
    : props.props
      ? props.props
      : props
  const query = useStaticQuery(graphql`
    query {
      story: allStoryblokEntry(
        filter: { field_component: { eq: "header" } }
        sort: { fields: name }
      ) {
        nodes {
          name
          slug
          full_slug
          content
        }
      }
    }
  `)
  const lang = process.env.GATSBY_BUILD_LANGUAGE
  let filterLang = lang === "en" ? "default" : lang
  let filteredData
  if (filterLang === "default") {
    filteredData =
      // PROD TODO: restore query.story.nodes.filter(node => node.full_slug.startsWith("globals/"))
      query.story.nodes.filter(node =>
        node.full_slug.startsWith("new-site/globals/headerbar")
      ) ?? query.story.nodes[0]
  } else {
    filteredData =
      query.story.nodes.filter(node =>
        // PROD TODO: restore node.full_slug.includes(`${filterLang}/`)
        node.full_slug.includes(`${filterLang}/new-site/globals/headerbar`)
      ) ?? query.story.nodes[0]
  }

  const data = JSON.parse(filteredData[0].content)
  const logo = data.blocks.find(i => i.component === "image")
  const menu = data.blocks.find(i => i.component === "menu")
  const infoLinks = data.blocks.find(i => i.component === "mobile-info-links")
  const miniCart = data.blocks.find(i => i.component === "mini-cart")
  const marquee = data.blocks.find(i => i.component === "marquee")
  const uspSection = data.blocks.find(i => i.component === "usp-section")
  const ctaHeader = data.blocks.find(i => i.component === "cta-header")
  const meganav = data.blocks.find(i => i.component === "meganav")
  const reviewBlock = data.blocks.find(i => i.component === "review-block")

  useEffect(() => {
    if (
      new Date().getTime().toString() >= new Date(ctaHeader?.counter).getTime()
    ) {
      setCheckTimer(true)
    }
  }, [])

  const myRef = useRef({
    location: null,
  })

  useEffect(() => {
    // set the location on initial load
    if (!myRef.current.location) myRef.current.location = props.location
    // then make sure dialog is closed on route change
    else if (myRef.current.location !== props.location) {
      if (props.openMobileNav) mobileNavToggle()
      myRef.current.location = props.location
    }
  }, [props.location])

  const mobileNavToggle = () => {
    props.mobileNavToggle()
  }
  //Hide Navbar on scroll down
  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false
    let scrollDir = "down"

    const nav = document.getElementById("header__nav")
    const wrapper = document.getElementById("header__wrapper")

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      scrollDir = scrollY > lastScrollY ? "down" : "up"
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }

      const navHeight = wrapper.getBoundingClientRect().height
      const topBorder = wrapper.getBoundingClientRect().top

      // If scroll is longer then nav height
      if (topBorder < navHeight * -1) {
        nav.classList.add("header__nav--sd")
        if (scrollDir === "down") {
          nav.classList.remove("header__nav--su")
        } else {
          nav.classList.add("header__nav--fixed")
          nav.classList.add("header__nav--su")
        }
      } else if (topBorder >= 0) {
        nav.classList.remove("header__nav--fixed")
        nav.classList.remove("header__nav--sd")
        nav.classList.remove("header__nav--su")
      }
    }
    window.addEventListener("scroll", onScroll, { passive: true })

    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  const { t } = useTranslation()

  return (
    <>
      <header
        className={cx(
          "header",
          { "header--checkout": props.isCheckout },
          {
            "header--marquee":
              (marquee && marquee.active) || (uspSection && uspSection.active),
          }
        )}
        id="header"
      >
        {componentProps.noticeBanner && (
          <StaticBanner blok={{ content: componentProps.noticeBanner }} />
        )}
        <div
          className="header__wrapper header__wrapper--marquee"
          id="header__wrapper"
        >
          <div className="header__nav" id="header__nav">
            {uspSection &&
              uspSection.active &&
              !props.isCheckout &&
              uspSection.hideFromCheckout && (
                <UspSection uspSection={uspSection} />
              )}
            {uspSection &&
              uspSection.active &&
              !uspSection.hideFromCheckout && (
                <UspSection uspSection={uspSection} />
              )}

            {reviewBlock && <ReviewBlock blok={reviewBlock} />}

            {marquee && marquee.active ? <Marquee marquee={marquee} /> : ""}

            <div className="grid-container">
              <div className="grid__row">
                <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
                  <div className="header__inner">
                    {props.isCheckout && !isTabletOrMobile && (
                      <div
                        className={
                          !props.isCheckout
                            ? "header__left"
                            : "header__left header__left--checkout"
                        }
                      >
                        <RenderLink
                          linkText={t("checkout.contiuneShopping")}
                          linkUrl="/products"
                          asset="arrow_back"
                          buttonLook="subtle"
                          iconLeft={true}
                        />
                      </div>
                    )}
                    <div className="header__left__and__middle">
                      <div
                        className={
                          !props.isCheckout
                            ? "header__left"
                            : props.isCheckout && isTabletOrMobile
                              ? "header__left"
                              : "header__middle header__middle--center"
                        }
                      >
                        <Link to={"/"} className="header__logo">
                          {props.isCheckout && isTabletOrMobile ? (
                            <p>Checkout</p>
                          ) : (
                            logo && <SvgIcon name="tanrevel-black" />
                          )}
                        </Link>

                        {isTabletOrMobile && props.isCheckout ? (
                          <div className="header__button">
                            <RenderLink
                              linkText=""
                              linkUrl="/products"
                              asset="arrow_back"
                              buttonLook="subtle"
                              iconLeft={true}
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="header__middle">
                        {!props.isCheckout && !isTabletOrMobile && (
                          <Navbar
                            meganav={meganav}
                            menu={menu.menuLinks}
                            isCheckout={props.isCheckout}
                          />
                        )}
                      </div>
                    </div>
                    <div className="header__right">
                      {!props.isCheckout && (
                        <div
                          className="header__cart-icon"
                        >
                          <Link
                            to={"/contact"}
                            className="header__right__customer__care"
                          >
                            <p>{t('header.contact')}</p>
                          </Link>
                          <div className="header__cart-icon__wrapper">
                            <Button
                              iconOnlyNobg={true}
                              asset="bag-new"
                              assetHeight={24}
                              onClick={() => setIsCartOpen(true)}
                            />
                            {cartQuantity > 0 && (
                              <div className="header__cart-icon__quantity">
                                <span>{cartQuantity}</span>
                              </div>
                            )}
                          </div>
                          {isTabletOrMobile ? (
                            <div className="header__button__hamburger">
                              <Button
                                onClick={() => {
                                  mobileNavToggle()
                                }}
                                iconOnly={true}
                                transparetBg={true}
                                asset={"menu"}
                              />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {ctaHeader && !checkTime && <CtaHeader ctaHeader={ctaHeader} />}
      </header>

      <MobileMenu
        isOpen={props.openMobileNav}
        mobileNavToggle={props.toggleMobileNav}
        navigation={menu}
        meganav={meganav}
        infoLinks={infoLinks}
      />

      {!props.isCheckout && (
        <>
          <Cart cart={miniCart.cartContent[0]} />
        </>
      )}
    </>
  )
}

export default Header
