//Dependencies
import React from "react"
import RichTextResolver from "../../../helpers/storyblok/richTextResolver"
import { graphql, useStaticQuery } from "gatsby"
import Link from "../../ui/link/link"
import cx from "classnames"
import Hero from "../../general/hero/hero"
import Accordion from "../../general/accordion/accordion"
import useMediaQuery from "../../../hooks/useMediaQuery"

//Styles
import "./page-content-sections.scss"
import FormattedDate from "../../../helpers/date"
import ReactSelect from "../../general/react-select/react-select"
import Breadcrumbs from "../../general/breadcrumbs/breadcrumbs"

const PageContentSections = props => {
  const componentProps = props
  const query = useStaticQuery(graphql`
    query {
      story: allStoryblokEntry(
        filter: { field_component: { in: ["text-content", "trackorder"] } }
        sort: { fields: name }
      ) {
        nodes {
          name
          slug
          content
          full_slug
          created_at
        }
      }
    }
  `)

  const lang = process.env.GATSBY_BUILD_LANGUAGE
  let filterLang = lang === "en" ? "default" : lang
  let data
  if (filterLang === "default") {
    data = query.story.nodes.filter(node =>
      node.full_slug.startsWith(`term-pages/`)
      && node.full_slug.includes(`new-site`)
    )
  } else {
    data = query.story.nodes.filter(node =>
      node.full_slug.includes(`${process.env.GATSBY_BUILD_LANGUAGE}/`) && node.full_slug.includes(`new-site`)
    )
  }

  const date =
    componentProps.published_at &&
    `Last updated: ${FormattedDate(componentProps.published_at)}`
  const isTabletOrMobile = useMediaQuery(992)
  const renderSections =
    componentProps.sections && componentProps.sections.slice(1)

  let optionsData = []
  for (let i = 0; i < data.length; i++) {
    optionsData.push({ value: data[i].full_slug, label: data[i].name })
  }

  const onChange = event => {
    window.location = `/${event.value}`
  }

  return (
    <div className="text-content" id="text-content">
      <div className="listing__breadcrumbs">
        <div className="grid-container">
          <div className="grid__row">
            <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
              <Breadcrumbs
                breadTitle={componentProps.name}
                large={true}
              />
            </div>
          </div>
        </div>
      </div>
      <Hero
        largeScreenImage={componentProps.largeScreenImage}
        smallScreenImage={componentProps.smallScreenImage}
        compactFullImage={true}
        title={componentProps.name}
        content={date}
      />
      <div className="grid-container">
        <div className="grid__row">
          <div className="grid__col-xlarge-3 grid__col-large-3 grid__col-medium-9 grid__col-small-2">
            {!isTabletOrMobile && (
              <ul className="text-content__navigation">
                {data.map((page, index) => {
                  return (
                    <li
                      key={index}
                      className={cx("text-content__navigation-item", {
                        "text-content__navigation-item--active":
                          page.full_slug === componentProps.slug,
                      })}
                    >
                      <Link
                        linkText={page.name}
                        linkUrl={`/${page.slug}`}
                        className="text-content__navigation__link"
                      />
                    </li>
                  )
                })}
              </ul>
            )}
            {isTabletOrMobile && (
              <ReactSelect
                options={optionsData}
                isMulti={false}
                handleChange={event => onChange(event)}
                id="page-content"
              />
            )}
          </div>
          <div className="grid__col-xlarge-6 grid__col-large-6 grid__col-medium-9 grid__col-small-2">
            <div className="text-content__content">
              {componentProps.sections && (
                <>
                  <h2 className="text-content__main-heading">
                    {componentProps.sections[0].title}
                  </h2>
                  <RichTextResolver
                    data={componentProps.sections[0].content}
                    styleClass="text-content__description"
                  />
                </>
              )}
              {renderSections &&
                renderSections.map((section, index) => {
                  return (
                    <div key={index}>
                      {!isTabletOrMobile && (
                        <>
                          <h3 className="text-content__heading">
                            {section.title}
                          </h3>
                          <RichTextResolver
                            data={section.content}
                            styleClass="text-content__description"
                          />
                        </>
                      )}
                      {isTabletOrMobile && (
                        <Accordion
                          accordionTitle={section.title}
                          accordionContent={section.content}
                          accordionStyle="page"
                        />
                      )}
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageContentSections
