//Dependencies
import React, { useState } from "react"
import { useBrink } from "../../../context/BrinkContext"
import SvgIcon from "../../../hooks/svg"
import Price from "../../general/price/price"
import Loader from "../../ui/loader/loader"
import cx from "classnames"
import { useTranslation } from "react-i18next"

//Styles
import "./cart-item.scss"
import RenderLink from "../../ui/link/link"
import GA4 from "../../../services/tracking"

const lang = process.env.GATSBY_BUILD_LANGUAGE

const CartItem = props => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  const { addToCart, cart } = useBrink()

  const handleCartItemClick = async (item, qty) => {
    setIsLoading(true)
    const res = await addToCart({
      productVariantId: item.id,
      quantity: qty,
      openCart: true,
    })
    if (qty === 1) {
      GA4.addToCart(item, { listName: "cart", cart: res.cart })
    } else {
      GA4.removeFromCart(item, { listName: "cart", cart })
    }
    setIsLoading(false)
    const body = document.querySelector("body")
    body.style.overflow = "auto"
  }

  return (
    <div>
      {isLoading && <Loader />}
      {props.cartItems.map(item => (
        <div
          key={item.id}
          className={cx("cart-item", {
            "cart-item--loading": isLoading,
          })}
        >
          <div className="cart-item__container">
            <div className="cart-item__container__inner">
              <div className="cart-item__image">
                <div className="cart-item__image__wrapper image__wrapper">
                  <img src={item.imageUrl} alt={item.name} />
                </div>
              </div>
              <div className="cart-item__content">
                <div className="cart-item__content__top">
                  <div className="cart-item__content__title">
                    <RenderLink
                      linkText={
                        item.attribute &&
                        item.attribute.displayName &&
                        item.attribute.displayName[lang]
                          ? item.attribute.displayName[lang]
                          : item.name
                      }
                      linkUrl={`/product/${item.slug}`}
                    />
                    {/* <span>{item.customerAttribute && item.customerAttribute.productFeed_size ?
                    <span>
                      {item.customerAttribute.productFeed_size} {item.customerAttribute.productFeed_unit_pricing_measure && item.customerAttribute.productFeed_unit_pricing_measure}
                    </span>
                      :
                      <span>One size</span>
                    } </span> */}
                  </div>
                  <div className="cart-item__content__price">
                    <div>
                      {item.isGift ? (
                        <span className="cart-item__content__price__gift">
                          {t("cart.gift")}
                        </span>
                      ) : (
                        <Price
                          prices={Object.entries(item.price).map(prices => ({
                            amount: prices[1],
                            currencyUnit: prices[0],
                          }))}
                          discounts={Object.entries(item.discount).map(
                            prices => ({
                              amount: prices[1],
                              currencyUnit: prices[0],
                            })
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="cart-item__content__removes">
                  {props.controls && !item.isGift && (
                    <div className="cart-item__content__quantity">
                      <div
                        onClick={() => {
                          handleCartItemClick(item, -1)
                        }}
                        className="cart-item__content__quantity__button"
                      >
                        <SvgIcon name="remove" />
                      </div>
                      <div className="cart-item__content__quantity__button--count">
                        {item.quantity}
                      </div>
                      <div
                        onClick={() => {
                          handleCartItemClick(item, 1)
                        }}
                        className="cart-item__content__quantity__button"
                      >
                        <SvgIcon name="add" />
                      </div>
                    </div>
                  )}
                  {props.controls && !item.isGift && (
                    <div
                      onClick={() => handleCartItemClick(item, 0)}
                      className="cart-item__remove"
                    >
                      {t("cart.remove")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CartItem
