import axios from "axios"

export default class BrinkAPI {
  constructor({ baseUrl }) {
    this.orderUrl = `${baseUrl}/orderv1`
    this.productUrl = `${baseUrl}/productv1`
    this.stockUrl = `${baseUrl}/stockv2`
    this.integrationUrl = `${baseUrl}/integrationv1`
  }

  //Get Cart
  async getCart(headers) {
    return axios({
      method: "GET",
      url: `${this.orderUrl}/carts`,
      headers: headers,
    })
  }

  //get user country
  async getCountryCode() {
    try {
      return (
        await axios({
          method: "get",
          url: `${this.orderUrl}/countrycode`
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error getting country code")
    }
  }

  //Sync Cart
  async syncCart({ action, headers = {}, products = [], store, token = false }) {
    try {
      let data = {
        store: store,
      }
      if (products.length > 0) data = { ...data, products: products }
      let options = {
        method: action,
        url: `${this.orderUrl}/carts`,
        headers: headers,
        data: data,
      }

      if (token) {
        delete options.data
        options.url = `${this.orderUrl}/carts/${token}`
      }

      const res = await axios(options)

      return res.data
    } catch (error) {
      console.error(error)
      throw new Error("Error syncing cart")
    }
  }

  //Get shippingOptions
  async getShippingOptions({ headers, countryCode }) {
    try {
      return (
        await axios({
          method: "GET",
          url: `${this.orderUrl}/shipments/${countryCode || "SE"}`,
          headers: headers,
        })
      ).data
    } catch (error) {
      console.log(error)
      throw new Error("Error getting shipping options")
    }
  }

  //Get Stocks
  async getStocks({ ids, store }) {
    const pIds = typeof ids === "string" ? ids : ids.join(",")
    try {
      return (
        await axios.get(`${this.stockUrl}/products`, {
          params: { productIds: pIds, storeId: store },
        })
      ).data
    } catch (error) {
      console.log(error)
      throw new Error("Failed to fetch stocks")
    }
  }

  //Get Prod Variants
  async getVariants(ids) {
    try {
      return (
        await axios.get(`${this.productUrl}/products`, {
          params: { ids: ids },
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error getting stocks")
    }
  }

  //Get payment methods from Adyen
  async getPaymentMethods({
    headers = {},
    countryCode,
    currencyUnit,
    blockedPaymentMethods,
    amount,
    shopperReference,
  }) {
    try {
      return (
        await axios({
          method: "post",
          url: `${this.orderUrl}/payments/adyen/paymentmethods`,
          headers: headers,
          data: {
            countryCode: countryCode,
            channel: "web",
            shopperReference: shopperReference,
            //blockedPaymentMethods: blockedPaymentMethods,
            amount: {
              currency: currencyUnit,
              value: amount,
            },
          },
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error getting payment methods")
    }
  }

  //Adyen make payment
  async makePayment({
    headers = {},
    orderId,
    paymentMethod,
    storePaymentMethod,
    shopperReference,
    browserInfo,
    redirect,
  }) {
    try {
      return (
        await axios({
          method: "post",
          url: `${this.orderUrl}/payments/adyen/payments`,
          headers: headers,
          data: {
            orderId: orderId,
            paymentMethod: paymentMethod,
            storePaymentMethod: storePaymentMethod,
            shopperReference: shopperReference,
            browserInfo: browserInfo,
            origin: window.location.origin,
            redirect: redirect,
          },
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error making payment")
    }
  }

  //Adyen make details call
  async makeDetailsCall({ headers = {}, orderId, details }) {
    try {
      const res = await axios({
        method: "POST",
        url: `${this.orderUrl}/payments/adyen/payments/details`,
        headers: headers,
        data: {
          orderId: orderId,
          details: details,
        },
      })
      return res.data
    } catch (error) {
      console.log(error)
      throw new Error("Error making details call")
    }
  }

  async getOrderConfirmation(orderId, signature) {
    return axios({
      method: "get",
      url: `${this.orderUrl}/payments/adyen/orders/${orderId}/confirmation`,
      headers: { Authorization: signature },
    }).then(({ data }) => data)
  }

  //Make order from cart
  async cartToOrder({ headers = {}, email, shippingAddress, billingAddress }) {
    try {
      return (
        await axios({
          method: "post",
          url: `${this.orderUrl}/carts/orders`,
          headers: headers,
          data: {
            email: email,
            shippingAddress: shippingAddress,
            billingAddress: billingAddress,
          },
          validateStatus: status => [404, 200].includes(status),
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error cart to order")
    }
  }

  //Add voucher
  async handleVoucher({ code, products, headers }) {
    let data = { products: products }
    data = code === 'deleteDiscount' ? { ...data, deleteDiscount: true } : { ...data, code: code }
    try {
      return (
        await axios({
          method: "put",
          url: `${this.orderUrl}/carts`,
          headers: headers,
          data: data
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error applying voucher")
    }
  }

  //Ingrid Delivery Checkout
  async ingridDeliveryCheckout(brinkSessionId, searchAddress) {
    return axios({
      method: "post",
      url: `${this.integrationUrl}/integrations/ingrid/delivery-checkout`,
      headers: { Authorization: brinkSessionId },
      data: searchAddress
    }).then(({ data }) => data)
  }

  async cartToKlarnaOrder({
    headers = {},
    merchantUrls,
    merchantData,
    options,
    shippingAddress
  }) {
    try {
      return (
        await axios({
          method: "post",
          url: `${this.orderUrl}/carts/klarna/orders`,
          headers,
          data: {
            merchant_urls: merchantUrls,
            merchant_data: merchantData,
            options,
            ...(shippingAddress.postalCode && {
              shipping_address: { postal_code: shippingAddress.postalCode }
            }),
            ...(shippingAddress.postalCode && {
              billing_address: { postal_code: shippingAddress.postalCode }
            })
          },
          validateStatus: (status) => [409, 404, 403, 400, 200].includes(status)
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error cart to klarna order")
    }
  }

  async getKlarnaOrder({ headers = {}, orderId }) {
    try {
      return (
        await axios({
          method: "get",
          url: `${this.orderUrl}/payments/klarna/order?orderId=${orderId}`,
          headers,
          validateStatus: (status) => status === 200
        })
      ).data
    } catch (error) {
      console.error(error)
      throw new Error("Error get klarna order")
    }
  }


  //Change store,lang and currency
  async changeStore() { }
}
