// Dependency
import React from "react"
import cx from "classnames"

// Styles
import "./cms-text.scss"

const CmsText = props => {
  const {
    text,
    size,
    color,
    tag,
    fontWeight,
    lineHeight,
    hex,
    paddingTop,
    paddingBottom,
  } = props

  const Tag = tag

  return (
    <Tag
      style={{ color: hex }}
      className={cx(
        "text",
        { "text--xxxsmall": size === "xxx-small" },
        { "text--xxsmall": size === "xx-small" },
        { "text--xsmall": size === "x-small" },
        { "text--xasmall": size === "xa-small" },
        { "text--px-14-20": size === "px-14-20" },
        { "text--small": size === "small" },
        { "text--small-fixed": size === "small-fixed" },
        { "text--medium": size === "medium" },
        { "text--medium-fixed": size === "medium-fixed" },
        { "text--large": size === "large" },
        { "text--large-a": size === "large-a" },
        { "text--xlarge": size === "x-large" },
        { "text--xalarge": size === "xa-large" },
        { "text--xblarge": size === "xb-large" },
        { "text--xxalarge": size === "xxa-large" },
        { "text--xxblarge": size === "xxb-large" },
        { "text--xxlarge": size === "xx-large" },
        { "text--xxxlarge": size === "xxx-large" },
        { "text--xxxalarge": size === "xxxa-large" },
        { "text--4XL": size === "4XL" },
        { "text--5XL": size === "5XL" },
        { "text--6XL": size === "6XL" },
        { "text--7XL": size === "7XL" },
        { "text--white": color === "white" },
        { "text--black": color === "black" },
        { "text--semibold": fontWeight === "semibold" },
        { "text--bold": fontWeight === "bold" },
        { "text--lineheight-big": lineHeight === "big" },
        { "text--lineheight-medium": lineHeight === "medium" },
        { "text--padding-top": paddingTop },
        { "text--padding-bottom": paddingBottom }      
      )}
    >
      {text}
    </Tag>
  )
}
export default CmsText
