import { useEffect } from "react"

const useTestFreak = ({ type, productId }) => {
  //const { currentStore } = useContext(BrinkContext)

  useEffect(() => {
    const reviewLang = process.env.GATSBY_BUILD_LANGUAGE || "en"
    const loadTestFreaks = callback => {
      const existingScript = document.getElementById("tF")
      if (!existingScript) {
        const script = document.createElement("script")
        script.src = `https://js.testfreaks.com/onpage/tanrevel.com-${reviewLang}/head.js`
        script.id = "tF"
        document.body.appendChild(script)
        script.onload = () => {
          if (callback) callback()
        }
      }
      if (existingScript && callback) callback()
    }

    loadTestFreaks(() => {
      let testFreaks = window.testFreaks || []
      testFreaks.push(["setProductId", productId])
      testFreaks.push(["load", ["badge", "reviews"]])
      testFreaks.push(["setAnalytics", "dataLayer"])
      testFreaks.push(["setAggregateRatingSchema", "json-ld"])
    })
    return () => {
      delete window.testFreaks
      document.getElementById("tF")?.remove()
    }
  }, [productId])
}

export default useTestFreak
