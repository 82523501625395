import React from "react"
import cx from "classnames"

//Styles
import "./review-block.scss"
import useMediaQuery from "../../../hooks/useMediaQuery"
import useLipscoreReviews from "../../../hooks/useLipscoreReviews"
import ReviewStar from "../../../components/general/review-star/reviewStar"

const ReviewBlock = props => {
  const componentProps = props.blok ? props.blok : props.prop
  const isTabletOrMobile = useMediaQuery(1024)

  const colorMode = isTabletOrMobile
    ? componentProps.color_mode_mobile
    : componentProps.color_mode

  const { lipscoreReviewsCountString, lipscoreStars, lipscoreRatingNumber } =
    useLipscoreReviews({})

  return (
    <>
      {componentProps.link.url !== "" ? (
        <div
          className={cx(
            "review-block",
            {
              "review-block--light": colorMode === "light",
            },
            {
              "review-block--dark": colorMode === "dark",
            }
          )}
        >
          <div className="grid-container">
            <a href={componentProps.link.url} className="review-block__content">
              <div className="review-block__content--rating">
                <span>{lipscoreRatingNumber}</span>
              </div>
              <div className="review-block__content--image">
                <ReviewStar starAmount={lipscoreStars} />
              </div>
              <div className="review-block__content--text">
                <span>
                  {componentProps.text.replace(
                    "{{REVIEW_COUNT}}",
                    lipscoreReviewsCountString
                  )}
                </span>
              </div>
            </a>
          </div>
        </div>
      ) : (
        <div
          className={cx(
            "review-block",
            {
              "review-block--light": colorMode === "light",
            },
            {
              "review-block--dark": colorMode === "dark",
            }
          )}
        >
          <div className="grid-container">
            <div className="review-block__content">
              <div className="review-block__content--rating">
                <span>{lipscoreRatingNumber}</span>
              </div>
              <div className="review-block__content--image">
                <ReviewStar starAmount={lipscoreStars} />
              </div>
              <div className="review-block__content--text">
                <span>
                  {componentProps.text.replace(
                    "{{REVIEW_COUNT}}",
                    lipscoreReviewsCountString
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ReviewBlock
