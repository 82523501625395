//Dependencies
import React from "react"
import HeadingWithCaptionImage from "../../general/heading-with-caption-image/heading-with-caption-image"
import SwiperCarouselVarationOne from "../../general/swiper-carousel-variation-one/swiper-carousel-variation-one"

// CSS
import "./product-instruction-carousel.scss"

const ProductInstructionCarousel = props => {
  const headingWithCaptionImage = props.blok.sections.find(
    i => i.component === "heading-with-caption-image"
  )

  const swiperCarouselVariationOne = props.blok.sections.find(
    i => i.component === "swiper-carousel-variation-one"
  )
  swiperCarouselVariationOne["noGrid"] = true
  return (
    <section className="product-instruction-carousel">
      <div className="grid-container">
        <div className="grid__row">
          <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
            <div className="product-instruction-carousel__heading">
              <HeadingWithCaptionImage props={headingWithCaptionImage} />
            </div>
            <SwiperCarouselVarationOne props={swiperCarouselVariationOne} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductInstructionCarousel
