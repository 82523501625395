//Dependencies
import React, { useRef } from "react"
import Image from "../../ui/image/image"
import Price from "../../general/price/price"
import Button from "../../ui/button/button"
import { graphql, useStaticQuery } from "gatsby"

//Styles
import "./product-card.scss"
import RenderLink from "../../ui/link/link"
import GA4 from "../../../services/tracking"
import { useTranslation } from "react-i18next"
import { useBrink } from "../../../context/BrinkContext"
import useTestFreakApi from "../../../hooks/useTestFreakApi"
import useLispcorereviews from "../../../hooks/useLipscoreReviews"
import ReviewStarAmount from "../../general/review-star-amount/reviewStarAmount"
import useMediaQuery from "../../../hooks/useMediaQuery"
import LipscoreWidget from "../../general/lipscore-widget/lipscore-widget"

const ProductCard = props => {
  const isMobile = useMediaQuery(576)
  const query = useStaticQuery(graphql`
    query {
      story: allStoryblokEntry(
        filter: { field_component: { eq: "blurbBackground" } }
        sort: { fields: name }
      ) {
        nodes {
          content
        }
      }
    }
  `)

  // PROD TODO: restore lipscore fetching block
  // const lipscoreReviews = undefined
  // const lipscoreStars = 0
  const { lipscoreReviews, lipscoreStars } = useLispcorereviews({
    internal_id: props.brinkProduct?.id || '0',
  })

  const globalData = query.story.nodes[0].content

  const parsedGlobalData = JSON.parse(globalData)

  const componentProps = props.blok ? props.blok : props.props
  const {
    store: { currencyUnit },
  } = useBrink()

  //console.log(currencyUnit, "currencyUnit")
  // console.log(componentProps)
  // console.log(props.brinkProduct)

  const { t } = useTranslation()

  const { store } = useBrink()

  const ref = useRef()
  //const [viewedProducts, setViewedProducts] = useState([])

  //const inViewport = useIntersection(ref.current, "-250px")

  // if (inViewport) {
  //   let unique = `${componentProps.slug}`
  //   if (!viewedProducts.includes(unique)) {
  //     console.log({ inViewport, ref })

  //     // GA.onProductView(prod, `Blog - ${post}`, position)
  //     GA4.viewItemsList([props.brinkProduct], {
  //       listName: "SHOP ALL",
  //       index: props.index,
  //     })
  //     console.log(unique)
  //     setViewedProducts([...viewedProducts, unique])
  //   }
  // }

  const percentageDiscount = props.brinkProduct?.discount.find(
    t => t.currencyUnit === currencyUnit
  )
  const percentagePrice = props.brinkProduct?.price.find(
    t => t.currencyUnit === currencyUnit
  )

  const percent = percentageDiscount?.amount / percentagePrice?.amount
  const roundPercent = Math.round(percent * 100)

  const reviews = useTestFreakApi(componentProps.content?.sku)

  const image = componentProps.content?.gallery?.find(
    component => component.component === "carousel"
  ).slides[0].image

  return (
    componentProps.content && (
      <div ref={ref} className="product-card">
        {!componentProps.issoldout && componentProps.content.label !== "" && (
          <div className="product-card__tag">
            {componentProps.content.label}
          </div>
        )}
        {componentProps.issoldout && (
          <div className="product-card__tag product-card__tag--sold-out">
            {t("product.soldout")}
          </div>
        )}
        {componentProps.slug ? (
          <RenderLink
            className="product-card__link"
            linkUrl={`/product/${componentProps.slug}`}
            onClick={() =>
              GA4.selectItem(props.brinkProduct, {
                index: props.index,
                listName: "SHOP ALL",
                currencyUnit: store.currency,
              })
            }
            linkText={
              <div className="product-card__inner">
                {image && (
                  <div
                    className="product-card__image image__wrapper"
                    style={{ backgroundColor: props.bgColor }}
                  >
                    <Image
                      largeScreenImage={image.filename}
                      smallScreenImage={image.filename}
                      alt={image.alt}
                      layout={"fullWidth"}
                      loading="eager"
                    />
                    {componentProps.content.hoverImage && (
                      <div className="product-card__image__hover">
                        <Image
                          largeScreenImage={
                            componentProps.content.hoverImage.filename
                          }
                          smallScreenImage={
                            componentProps.content.hoverImage.filename
                          }
                          alt={componentProps.content.hoverImage.alt}
                          //layout={"constrained"}
                          loading="eager"
                          layout={"fullWidth"}
                        />
                      </div>
                    )}
                    <div className="product-card__image__blurbs">
                      {componentProps.content.blurbs &&
                        componentProps.content.blurbs.map((blurb, index) => (
                          <div
                            key={index}
                            className="product-card__image__blurbs__blurb"
                            style={{
                              backgroundColor: blurb.backgroundColor,
                              color: blurb.color,
                            }}
                          >
                            {blurb.text}
                          </div>
                        ))}
                      {componentProps.content.productInfo[0].outOfStock && (
                        <div className="product-card__image__blurbs__blurb product-card__image__blurbs__blurb--oos">
                          {t("notifyMe.notify")}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                
                  <div className="product-card-variant-2__rating_box">
                    <LipscoreWidget
                      className="product-card__hidden"
                      lipscoreId="lipscore-rating"
                      productId={props.brinkProduct.id}
                    />
                    {lipscoreReviews && lipscoreReviews.length ? (
                    <ReviewStarAmount
                      starAmount={lipscoreStars}
                      reviewCount={lipscoreReviews.length}
                    />
                    ) : undefined}
                  </div>     
                <div className="product-card__content">
                  <div className="product-card__content__details">
                    <div className="product-card__content__title">
                      {componentProps.name}
                    </div>
                  </div>
                  <div className="product-card__footer">
                    <div className="product-card__content__price">
                      <Price
                        prices={props.brinkProduct && props.brinkProduct.price}
                        discounts={
                          props.brinkProduct && props.brinkProduct.discount
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        ) : undefined}
        {componentProps.content.productInfo[0].splash ? (
          <div className="product-card__discount-cms">
            <div className="product-card__discount-cms__content">
              <div className="product-card__discount-cms__content--number">
                <p>{componentProps.content.productInfo[0].splash}</p>
              </div>
            </div>
          </div>
        ) : props.brinkProduct?.discount &&
          props.brinkProduct?.discount.find(
            d => d.currencyUnit === currencyUnit
          )?.amount ? (
          <div
            className="product-card__discount-splash"
            style={{ backgroundColor: parsedGlobalData.backgroundColor }}
          >
            <div className="product-card__discount-splash__content">
              <div className="product-card__discount-splash__content--number">
                <p style={{ color: parsedGlobalData.color }}>{roundPercent}%</p>
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
    )
  )
}

export default ProductCard
