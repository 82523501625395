// Dependency
import React from "react"
import BulletsIcons from "../../general/bullets-icons/bullets-icons"
import HeadingWithCaptionImage from "../../general/heading-with-caption-image/heading-with-caption-image"
import Tabs from "../../general/tabs/tabs"
import useMediaQuery from "../../../hooks/useMediaQuery"
import { useTranslation } from "react-i18next"
import { storyblokEditable } from "gatsby-source-storyblok"

// CSS
import "./product-description.scss"
import SvgIcon from "../../../hooks/svg"

// Template
const ProductDescription = props => {
  const isTabletOrMobile = useMediaQuery(1024)

  const tabs = props.props?.components.find(i => i.component === "tabs")
  const bulletsIcons = props.props?.components.find(
    i => i.component === "bullets"
  )
  const headingWithCaptionImage = props.props?.components.find(
    i => i.component === "heading-with-caption-image"
  )
  const { t } = useTranslation()

  return (
    <>
      {headingWithCaptionImage || bulletsIcons || tabs ? (
        <section className="product-description" id="atc-sticky-trigger">
          <div className="grid-container">
            <div className="grid__row">
              <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2 product-description__grid">
                <div {...storyblokEditable(props.props)}>
                  {tabs ? <Tabs props={tabs} /> : ""}
                </div>

                <div
                  className="product-description__perks"
                  {...storyblokEditable(props.props)}
                >
                  {isTabletOrMobile ? (
                    <div className="info-card__bullets info-card__bullets--mobile">
                      <BulletsIcons white={false} props={props.perks[0]} />
                    </div>
                  ) : (
                    ""
                  )}

                  {headingWithCaptionImage || bulletsIcons ? (
                    <div className="product-description__inner">
                      <HeadingWithCaptionImage
                        props={headingWithCaptionImage}
                      />
                      <BulletsIcons white={true} props={bulletsIcons} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {props.showWarranty ? (
            <div className="product-description__warranty">
              <div className="grid-container">
                <div className="product-description__warranty__container">
                  <SvgIcon name="check-splash" />
                  <div className="product-description__warranty__content">
                    <p>{t("product.warrantyTitle")}</p>
                    <span>{t("product.warrantyDesc")}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </section>
      ) : (
        ""
      )}
    </>
  )
}
export default ProductDescription
