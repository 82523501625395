import {
  addedToCart,
  orderedProducts,
  placedOrder,
  startedCheckout,
  toViewedProduct,
} from "./klaviyo"
import Cookies from "js-cookie"
import { sha256Encrypt } from "../helpers/encrypt"
import axios from "axios"

const isBrowser = typeof window !== "undefined"
const isProd = process.env.GATSBY_BUILD_ENV === "production"

export const getPrice = (price, currency) => {
  if (price instanceof Array) {
    return price.find(p => p.currencyUnit === currency)
  } else {
    return { amount: price[currency], currencyUnit: currency }
  }
}
const GA4Item = (product, options) => {
  const storePrice = getPrice(product.price, options.currencyUnit)
  let price = storePrice?.amount / 100 ?? 0
  const storeDiscount = product.discount
    ? getPrice(product.discount, options.currencyUnit)
    : { amount: 0, currencyUnit: options.currencyUnit }
  const discount = (storeDiscount?.amount ?? 0) / 100

  if (discount > 0) {
    price = price - discount
  }

  const item = {
    item_id: product.id,
    item_name: product.name,
    affiliation: window?.location.origin || "tanrevel.com",
    //coupon: "SUMMER_FUN",
    currency: storePrice?.currencyUnit ?? "SEK",
    discount: discount,
    item_brand: "Tanrevel",
    item_category: product.category,
    // item_category2: "Adult",
    // item_category3: "Shirts",
    // item_category4: "Crew",
    // item_category5: "Short sleeve",
    //item_variant: "green",
    price: price,
    quantity: product.quantity || 1,
  }

  if (options.discountCode) {
    item.coupon = options.discountCode
  }

  if (options.listName) {
    item.item_list_id = options.listName.split(" ").join("_").toLowerCase()
    item.item_list_name = options.listName
  }

  if (options.index !== undefined && options.index !== null) {
    item.index = options.index
  }

  if (product.index) {
    item.index = product.index
  }

  return item
}

const FBItem = (product, options) => {
  const storePrice = getPrice(product.price, options.currencyUnit)
  let price = storePrice?.amount / 100 ?? 0
  const storeDiscount = product.discount
    ? getPrice(product.discount, options.currencyUnit)
    : { amount: 0, currencyUnit: options.currencyUnit }
  const discount = (storeDiscount?.amount ?? 0) / 100

  if (discount > 0) {
    price = price - discount
  }
  return {
    content_ids: [product.id],
    content_name: product.name,
    content_type: "product",
    value: price,
    currency: options.currencyUnit,
  }

}
//
// const GA4Promotion = promotion => {
//   const item = {
//     promotion_id: promotion.id,
//     promotion_name: promotion.name,
//     creative_name: promotion.creativeName,
//     creative_slot: promotion.creativeSlot,
//   }
//
//   return item
// }

const getDiscountCode = cart => {
  const rule = cart?.discounts?.rules?.find(r => r.ruleType === "DISCOUNTCODE")
  return rule?.ruleData?.discountCode ?? false
}

const pushToDataLayer = (event, data) => {
  if (isBrowser && isProd) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({ event, ecommerce: data })
    //console.log('pushToDataLayer', event, data);
  } else {
    console.log("pushToDataLayer", event, data)
  }
}

const pushToKlavyio = (event, data) => {
  if (isBrowser && isProd) {
    window._learnq && window._learnq.push(["track", event, data])
  } else {
    console.log("pushToKlavyio", event, data)
  }
}

const GA4 = {
  viewItemsList: (productList, { listName, currencyUnit }) => {
    pushToDataLayer("view_item_list", {
      items: productList.map((product, index) =>
        GA4Item(product, { index, listName, currencyUnit })
      ),
    })
  },

  viewItem: (product, currencyUnit) => {
    pushToDataLayer("view_item", {
      items: [GA4Item(product, { listName: "PDP", currencyUnit })],
    })
    pixelConversionApi("ViewContent", FBItem(product, { currencyUnit }), {})
    pushToKlavyio("Viewed Product", toViewedProduct(product, currencyUnit))
  },

  selectItem: (product, { listName, index, currencyUnit }) => {
    pushToDataLayer("select_item", {
      items: [GA4Item(product, { index, listName, currencyUnit })],
    })
  },

  addToCart: (product, { listName, cart }) => {
    pushToDataLayer("add_to_cart", {
      items: [
        GA4Item(product, {
          listName,
          currencyUnit: cart.store.currencyUnit,
          discountCode: getDiscountCode(cart),
        }),
      ],
    })
    pixelConversionApi("AddToCart", FBItem(product, { currencyUnit: cart.store.currencyUnit }), {})
    pushToKlavyio("Added to Cart", addedToCart(product, cart))
  },

  removeFromCart: (product, { listName, cart }) => {
    pushToDataLayer("remove_from_cart", {
      items: [
        GA4Item(product, { listName, currencyUnit: cart.store.currencyUnit }),
      ],
    })
  },

  beginCheckout: (productList, cart, BrinkSessionId) => {
    pushToDataLayer("begin_checkout", {
      items: productList.map(product =>
        GA4Item(product, {
          currencyUnit: cart.store.currencyUnit,
          discountCode: getDiscountCode(cart),
        })
      ),
    })
    pixelConversionApi("InitiateCheckout", {
      content_ids: productList.map(product => product.id),
      content_name: productList.map(product => product.name),
      content_type: "product",
      value: productList.reduce(
        (result, current) =>
          (result += getPrice(current.price, cart.store.currencyUnit).amount * current.quantity),
        0
      ) / 100,
      contents: productList.map(product => ({
        id: product.id,
        quantity: product.quantity,
      })),
      num_items: productList.reduce(
        (result, current) => (result += current.quantity),
        0
      ),
      currency: cart.store.currencyUnit,
    }, {})

    pushToKlavyio("Started Checkout", startedCheckout(cart, BrinkSessionId))
  },

  addShippingInfo: ({ shippingOption, value, currency }, cart) => {
    pushToDataLayer("add_shipping_info", {
      shipping_tier: shippingOption,
      value,
      currency,
      items: cart.cartItems.map(product =>
        GA4Item(product, {
          currencyUnit: currency,
          discountCode: getDiscountCode(cart),
        })
      ),
    })
  },

  addPaymentInfo: ({ paymentMethod, value, currency, cart }) => {
    console.log(cart)
    pushToDataLayer("add_payment_info", {
      payment_type: paymentMethod,
      value,
      currency,
      items: cart.cartItems.map(product =>
        GA4Item(product, {
          currencyUnit: currency,
          discountCode: getDiscountCode(cart),
        })
      ),
    })
  },

  purchase: async (order, cart) => {
    const products = cart.cartItems.filter(
      product => product.type !== "shippingOption"
    )
    const discountCode = getDiscountCode(cart)
    console.log(order)
    const orderObj = {
      transaction_id: order.merchant_reference2
        ? order.merchant_reference1
        : order.reference,
      value: order.merchant_reference2
        ? order.order_amount / 100
        : order.orderAmountWithDiscount / 100,
      shipping: order.merchant_reference2
        ? order.order_lines
          .filter(ol => ol.type === "shipping_fee")
          .reduce((result, current) => (result += current.total_amount), 0) /
        100
        : (order.orderLines.find(line => line.type === "shipment")
          ?.totalAmountWithDiscount ?? 0) / 100,
      tax: order.merchant_reference2
        ? order.order_tax_amount / 100
        : order.orderTaxAmount / 100,
      affiliation: window?.location.origin || "tanrevel.com",
      currency: order.merchant_reference2
        ? order.purchase_currency
        : order.currencyUnit,
      items: products.map(product =>
        GA4Item(product, {
          currencyUnit: cart.store.currencyUnit,
          discountCode,
        })
      ),
      customer: {
        email: order?.billing_address?.email || order?.billingAddress?.email,
        phone: order?.billing_address?.phone || order?.billingAddress?.phone,
        sha256_email: await sha256Encrypt(
          order?.billing_address?.email || order?.billingAddress?.email
        ),
        sha256_phone: await sha256Encrypt(
          order?.billing_address?.phone || order?.billingAddress?.phone
        ),
        billing_address: order?.billing_address || order?.billingAddress,
        shipping_address: order?.shipping_address || order?.shippingAddress,
      },
    }
    pushToDataLayer("purchase", orderObj)
    pushToKlavyio(
      "Placed Order",
      placedOrder(order, orderObj, cart, discountCode)
    )
    cart.cartItems
      .filter(p => p.type === "productVariant")
      .map(item => {
        pushToKlavyio("Ordered Product", orderedProducts(item, order, cart))
      })
    const userData = {
      email: await sha256Encrypt(orderObj.customer.email.trim()),
      firstName: await sha256Encrypt(orderObj.customer.billing_address.given_name.trim()),
      lastName: await sha256Encrypt(orderObj.customer.billing_address.family_name.trim()),
      phone: await sha256Encrypt(orderObj.customer.phone.trim()),
      city: await sha256Encrypt(orderObj.customer.billing_address.city.trim()),
      zip: await sha256Encrypt(orderObj.customer.billing_address.postal_code.trim()),
      country: await sha256Encrypt(orderObj.customer.billing_address.country.trim())
    }
    pixelConversionApi("Purchase", {
      content_ids: products.map(product => product.id),
      content_name: products.map(product => product.name),
      content_type: "product",
      value: orderObj.value,
      currency: orderObj.currency,
      contents: products.map(product => ({
        id: product.id,
        quantity: product.quantity,
      })),
      num_items: products.reduce((result, current) => (result += current.quantity), 0),
    }, userData)
  },
}

export default GA4


const pixelConversionApi = (eventName, data, userData) => {
  const eventId = generateEventId()
  const fbc = Cookies.get("_fbc")
  const fbp = Cookies.get("_fbp")
  // console.log({
  //   custom_data: data,
  //   event_name: eventName,
  //   event_id: eventId,
  //   event_source_url: window.location.href,
  //   ...(fbc && { fbc: fbc }),
  //   ...(fbp && { fbp: fbp }),
  //   ...(userData?.email && { email: userData.email }),
  //   ...(userData?.phone && { phone: userData.phone }),
  //   ...(userData?.firstName && { first_name: userData.firstName }),
  //   ...(userData?.lastName && { last_name: userData.lastName }),
  //   ...(userData?.city && { city: userData.city }),
  //   ...(userData?.country && { country: userData.country }),
  //   ...(userData?.zip && { zip: userData.zip })
  // })

  axios
    .post(
      `${process.env.GATSBY_BRINK_API_URL}/integrationv1/integrations/facebook/graph/events`,
      {
        custom_data: data,
        event_name: eventName,
        event_id: eventId,
        event_source_url: window.location.href,
        ...(fbc && { fbc: fbc }),
        ...(fbp && { fbp: fbp }),
        ...(userData?.email && { email: userData.email }),
        ...(userData?.phone && { phone: userData.phone }),
        ...(userData?.firstName && { first_name: userData.firstName }),
        ...(userData?.lastName && { last_name: userData.lastName }),
        ...(userData?.city && { city: userData.city }),
        ...(userData?.country && { country: userData.country }),
        ...(userData?.zip && { zip: userData.zip })
      }
    )
    .then((response) => {
      console.log(response)
    })
    .catch((error) => {
      console.error(error)
    })
}

const generateEventId = () =>
  `${Date.now()}.${Math.floor(100000 + Math.random() * 900000)}`
