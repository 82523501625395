//Dependencies
import React, { useState } from "react"
import { useBrink } from "../../../context/BrinkContext"
import GA4 from "../../../services/tracking"
import Price from "../../general/price/price"
import Button from "../../ui/button/button"
import Image from "../../ui/image/image"
import Loader from "../../ui/loader/loader"

//Styles
import "./upsell-card.scss"

const UpsellCard = props => {
  const [isLoading, setIsLoading] = useState(false)
  const componentProps = props.blok ? props.blok : props.props
  const image = componentProps.content.gallery[0].slides[0].image.filename

  const { addToCart } = useBrink()

  const brinkProduct = props.brink

  const handleClick = async () => {
    setIsLoading(true)
    const res = await addToCart({
      productVariantId: brinkProduct.id,
      quantity: 1,
      options: [{ key: "url", value: `https://tanrevel.${process.env.GATSBY_BUILD_MARKET}/product/${brinkProduct.slug}` }],
      openCart: true,
    })
    GA4.addToCart(brinkProduct, { listName: "cart-upsell", cart: res.cart })

    setIsLoading(false)
  }

  return (
    <div className="upsell-card">
      {isLoading && <Loader />}
      <div className="upsell-card__left">
        <div className="upsell-card__content">
          <div className="upsell-card__content__img">
            <div className="upsell-card__content__img__wrapper image__wrapper">
              <Image
                largeScreenImage={image}
                smallScreenImage={image}
                largeScreenHeight={"auto"}
                largeScreenWidth={300}
                smallScreenHeight={"auto"}
                smallScreenWidth={300}
                alt={"image"}
                layout={"constrained"}
              />
            </div>
          </div>

        </div>
        <div className="upsell-card__info">
          <div className="upsell-card__info__title">
            {componentProps.content.productInfo[0].title}
          </div>
          <div className="upsell-card__info__price">
            <Price
              prices={brinkProduct.price}
              discounts={brinkProduct.discount}
            />
          </div>
        </div>
      </div>

      <div className="upsell-card__content-add">
        <Button
          className="upsell-card__content-add__btn"
          iconOnly={true}
          icon={"add"}
          onClick={() => {
            handleClick()
          }}
        />
      </div>
    </div>
  )
}

export default UpsellCard
