// Dependency
import React, { useState, useEffect } from "react"
import { storyblokInit, apiPlugin } from "gatsby-source-storyblok"
import { useBrink } from "../context/BrinkContext"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

// General components
import Hero from "./general/hero/hero"
import StaticBanner from "./general/staticbanner/staticbanner"
import Header from "./general/header/header"
import SwiperCarousel from "./general/swiper-carousel/swiper-carousel"
import Breadcrumbs from "./general/breadcrumbs/breadcrumbs"
import BulletsIcons from "./general/bullets-icons/bullets-icons"
import HeadingWithCaptionImage from "./general/heading-with-caption-image/heading-with-caption-image"
import Tabs from "./general/tabs/tabs"
import TextOnImageColorBlock from "./general/text-on-image-color-block/text-on-image-color-block"
import Listing from "./general/listing/listing"
import Table from "./general/table/table"
import Footer from "./general/footer/footer"
import ImageGalleryTile from "./general/image-gallery-tile/image-gallery-tile"
import InfoCard from "./general/info-card/info-card"
import LogosBanner from "./general/logos-banner/logos-banner"
import ImageTitleLink from "./general/image-title-link/image-title-link"
import SwiperCarouselVariationThree from "./general/swiper-carousel-variation-three/swiper-carousel-variation-three"
import TextSection from "./general/text-section/text-section"
import SwiperCarouselVarationOne from "./general/swiper-carousel-variation-one/swiper-carousel-variation-one"
import MobileMenu from "./general/header/mobile-menu/mobile-menu"
import MobileNavLinks from "./general/header/mobile-nav-links/mobile-nav-links"
import MobileInfoLinks from "./general/header/mobile-info-links/mobile-info-links"
import SocialMedia from "./general/social-media/social-media"
import LeadSection from "./general/lead-section/lead-section"

// CMS components
import CmsHero from "./cms/cms-hero/cms-hero"
import CmsText from "./cms/cms-text/cms-text"

// UI components
import Link from "./ui/link/link"

// Global components
import TextBlock from "./global/text-block/text-block"
import SwiperCarouselVarationTwo from "./global/swiper-carousel-variation-two/swiper-carousel-variation-two"
import TextBlockImage from "./global/text-block-image/text-block-image"
import MediaBanner from "./global/media-banner/media-banner"
import PageContentSections from "./global/page-content-sections/page-content-sections"

// Third party components
import TestFreak from "./third-party/testfreak/testfreak"

// Landing page components
import ImageTable from "./landing/image-table/image-table"

// Product page components
import ProductDescription from "./product/product-description/product-description"
import ProductFeatureImageIngredients from "./product/product-feature-image-ingredients/product-feature-image-ingredients"
import ProductAward from "./product/product-award/product-award"
import ProductInstructionCarousel from "./product/product-instruction-carousel/product-instruction-carousel"
import ProductCarouselInfo from "./product/product-carousel-info/product-carousel-info"
import ProductCard from "./product/product-card/product-card"

// Styles
import "../styles/index.scss"
import UpsellCard from "./cart/upsell-card/upsell-card"
import Button from "./ui/button/button"
import SkinTonesModule from "./skin-tones-module/skin-tones-module"
import ResultsUsp from "./results-usp/results-usp"
import ReviewBlock from "./general/review-block/review-block"
import LargeReviewBlock from "./general/large-review-block/large-review-block"
import SeoTextBlock from "./general/seo-text-block/seo-text-block"
import CategoryFilter from "./general/category-filter/category-filter"

const queryClient = new QueryClient()

storyblokInit({
  accessToken:
    process.env.NODE_ENV === "production"
      ? process.env.GATSBY_STORYBLOK_TOKEN
      : process.env.GATSBY_PREVIEW_STORYBLOK,
  use: [apiPlugin],
  components: {
    hero: Hero,
    link: Link,
    "text-block": TextBlock,
    "text-block-image": TextBlockImage,
    "product-listing": Listing,
    "offer-banner": StaticBanner,
    "social-media": SocialMedia,
    header: Header,
    carousel: SwiperCarousel,
    "instruction-carousel": ProductInstructionCarousel,
    breadcrumbs: Breadcrumbs,
    bullets: BulletsIcons,
    "heading-with-caption-image": HeadingWithCaptionImage,
    tabs: Tabs,
    "text-on-image-color-block": TextOnImageColorBlock,
    "swiper-carousel-variation-two": SwiperCarouselVarationTwo,
    "product-description": ProductDescription,
    "media-banner": MediaBanner,
    "test-freak": TestFreak,
    footer: Footer,
    "product-feature-image-ingredients": ProductFeatureImageIngredients,
    "info-card": InfoCard,
    "product-award": ProductAward,
    "product-carousel-info": ProductCarouselInfo,
    "logos-banner": LogosBanner,
    table: Table,
    "image-table": ImageTable,
    "image-gallery-tile": ImageGalleryTile,
    "image-title-link": ImageTitleLink,
    "swiper-carousel-variation-three": SwiperCarouselVariationThree,
    "swiper-carousel-variation-one": SwiperCarouselVarationOne,
    "product-card": ProductCard,
    menu: MobileMenu,
    "mobile-nav-links": MobileNavLinks,
    "mobile-info-links": MobileInfoLinks,
    "items-listing": Listing,
    "page-content-section": TextSection,
    "page-content-sections": PageContentSections,
    "upsell-card": UpsellCard,
    button: Button,
    cmsHero: CmsHero,
    cmsText: CmsText,
    "skin-tones-module": SkinTonesModule,
    "results-usp": ResultsUsp,
    "review-block": ReviewBlock,
    "large-review-block": LargeReviewBlock,
    "seo-text-block": SeoTextBlock,
    "category-filter": CategoryFilter,
    leadSection: LeadSection,
  },
})

const Layout = props => {
  const [page, setPage] = useState()
  const { isCartOpen, setIsCartOpen } = useBrink()
  const [openMobileNav, setOpenMobileNav] = useState(false)
  const toggleMobileNav = () => {
    setOpenMobileNav(!openMobileNav)
  }
  useEffect(() => {
    openMobileNav && setIsCartOpen(false)
    const component =
      (props.children &&
        props.children.props &&
        props.children.props.data &&
        props.children.props.data.storyblokEntry &&
        props.children.props.data.storyblokEntry.content.component) ||
      props.children.props.path
    setPage(component)
    if (page === "/checkout/" || page === "/checkout") {
      setIsCartOpen(false)
    }
  }, [props.children, isCartOpen, page, setIsCartOpen])

  return (
    <QueryClientProvider client={queryClient}>
    <div className={page === "listing" ? "body-bg" : null}>
      <Header
        location={props.location}
        mobileNavToggle={toggleMobileNav}
        openMobileNav={openMobileNav}
        toggleMobileNav={toggleMobileNav}
        isCheckout={page === "/checkout" || page === "/thankyou"}
      />
      <main>{props.children}</main>
      <Footer
        showAccordion={true}
        showNewsletter={false}
        isCheckout={page === "/checkout" || page === "/thankyou"}
      />
    </div>
    </QueryClientProvider>
  )
}
export default Layout
