// Dependency
import React from "react"
import cx from "classnames"
import { Link } from "gatsby"

// Styles
import "./cms-button.scss"

const CmsButton = props => {
  const { text, url, background, size, backgroundHex, colorHex, type } = props

  return (
    <Link
      style={{
        backgroundColor: backgroundHex,
        color: colorHex,
        borderColor: backgroundHex,
      }}
      to={url}
      className={cx(
        "cms-button",
        {
          "cms-button--white": background === "white" && type !== "link_button",
        },
        {
          "cms-button--black": background === "black" && type !== "link_button",
        },
        { "cms-button--small": size === "small" },
        { "cms-button--medium": size === "medium" },
        { "cms-button--big": size === "big" },
        { "cms-button--link": type === "link_button" }
      )}
    >
      {text}
    </Link>
  )
}
export default CmsButton
