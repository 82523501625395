// Dependency
import React from "react"
import loadable from "@loadable/component"

// Components
import HeadingWithCaptionImage from "../../general/heading-with-caption-image/heading-with-caption-image"
import SvgIcon from "../../../hooks/svg"

// Hooks
import Image from "../../ui/image/image"
import useMediaQuery from "../../../hooks/useMediaQuery"
import cx from "classnames"

// Styles
import "./media-banner.scss"

const ReactPlayer = loadable(() => import("react-player"))

// Template
const MediaBanner = props => {
  const headingWithCaptionImage = props.blok.components.find(
    i => i.component === "heading-with-caption-image"
  )
  const player = props.blok.components.find(i => i.component === "player")
  const media = props.blok.components.find(i => i.component === "media")

  const isTabletOrMobile = useMediaQuery(992)

  return (
    <section className="media-banner">
      <div className="grid-container">
        <div className="grid__row">
          <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
            <div className="media-banner__heading">
              {headingWithCaptionImage && (
                <HeadingWithCaptionImage props={headingWithCaptionImage} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
              className={cx("media-banner__wrapper", {
                "media-banner__wrapper--large-player": props.blok.largePlayer,
              })}
            >
              {player && player.url ? (
                <ReactPlayer
                  url={player.url}
                  width="100%"
                  height="100%"
                  loop={true}
                  controls={true}
                  className="media-banner__player"
                  playing
                  playIcon={
                    <div className="media-banner__play-icon">
                      <SvgIcon name="polygon" />
                    </div>
                  }
                  light={player.thumbnail.filename}
                />
              ) : !isTabletOrMobile ? (
                media.desktopImage.filename ? (
                  <Image
                    largeScreenImage={media.mobileImage.filename}
                    largeScreenHeight="auto"
                    smallScreenImage={media.mobileImage.filename}
                    smallScreenHeight="auto"
                    alt={media.mobileImage.alt}
                    layout={"constrained"}
                  />
                ) : (
                  ""
                )
              ) : media.mobileImage.filename ? (
                <Image
                  largeScreenImage={media.mobileImage.filename}
                  largeScreenHeight="auto"
                  smallScreenImage={media.mobileImage.filename}
                  smallScreenHeight="auto"
                  alt={media.mobileImage.alt}
                  layout={"constrained"}
                />
              ) : (
                ""
              )}
            </div>
    </section>
  )
}

export default MediaBanner
