import axios from "axios"

export const getKlaviyoListIds = countryCode => {
  try {
    const env = process.env.GATSBY_BUILD_ENV || "test"
    const klaviyoListIds = {
      test: {
        default: "TXbeMh",
      },
      production: {
        GB: "TSvBgc",
        SE: "RVEXki",
        DK: "QWaKXi",
        DE: "SAd5YJ",
        FI: "ThzbSq",
        NO: "Ttrcz3",
        NL: "RMSjEW",
        default: "XGmwkp",
      },
    }
    const key = klaviyoListIds[env][countryCode] ?? klaviyoListIds[env].default
    if (!key) console.error("Missing Klaviyo List ID")
    return key
  } catch (error) {
    console.error("Missing Klaviyo List ID")
    console.error(error)
  }
}

export const subscribeToNewsletter = async (
  countryCode,
  email,
  firstName,
  listId
) => {
  const config = Object.assign(
    {},
    {
      g: listId ?? getKlaviyoListIds(countryCode),
      email: email,
      $first_name: firstName,
    }
  )

  const body = Object.keys(config).reduce((str, key) => {
    config[key] && str.append(key, config[key])
    return str
  }, new URLSearchParams())

  return (
    await axios({
      url: "https://manage.kmail-lists.com/ajax/subscriptions/subscribe",
      method: "POST",
      headers: {
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
      },
      data: body,
    })
  ).data
}

export const subscribeToLeadGen= async (
  email,
  firstName,
  listId
) => {
  const config = Object.assign(
    {},
    {
      g: listId,
      email: email,
      $first_name: firstName,
    }
  )
  const body = Object.keys(config).reduce((str, key) => {
    config[key] && str.append(key, config[key])
    return str
  }, new URLSearchParams())

  return (
    await axios({
      url: "https://manage.kmail-lists.com/ajax/subscriptions/subscribe",
      method: "POST",
      headers: {
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
      },
      data: body,
    })
  ).data
}


export const handleRestock = async (email, selectedVariant) => {
  let formData = new URLSearchParams()
  const obj = {
    a: process.env.GATSBY_BUILD_KLAVIYO_ID,
    email: email,
    variant: selectedVariant,
    platform: "api",
  }

  Object.entries(obj).forEach(([key, value]) => {
    formData.append(key, value)
  })

  console.log(formData, "formData")

  return (
    await axios({
      url: "https://a.klaviyo.com/onsite/components/back-in-stock/subscribe",
      method: "POST",
      data: formData,
    })
  ).data
}
