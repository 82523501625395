import React from "react"
import SvgIcon from "../../../hooks/svg"
import { graphql, useStaticQuery } from "gatsby"
import { useState } from "react"
import cx from "classnames"

//Styles
import "./category-filter.scss"

const CategoryFilter = props => {
  const [open, setOpen] = useState(false)

  const query = useStaticQuery(graphql`
    query {
      allStoryblokEntry(
        filter: { field_component: { in: ["categoryFilter"] } }
      ) {
        nodes {
          content
          name
          field_component
          full_slug
        }
      }
    }
  `)

  const categoryFilter =
    query.allStoryblokEntry.nodes.find(
      node =>
        node.field_component === "categoryFilter" &&
        node.full_slug.includes(process.env.GATSBY_BUILD_LANGUAGE)
    ) ?? query.allStoryblokEntry.nodes[0]

  const data = categoryFilter ? JSON.parse(categoryFilter.content) : null

  const toggleTab = (tabTop, tabBottom) => {
    //Transform animation
    tabBottom?.offsetParent === null
      ? tabTop.classList.add("order-tabs__tab--open")
      : tabTop.classList.remove("order-tabs__tab--open")
    //Toggle visibillity
    if (tabBottom) {
      const displayValue =
        tabBottom?.offsetParent === null
          ? "display: flex; flex-direction: column; gap: 8px;"
          : "display: none;"
      tabBottom.setAttribute("style", displayValue)
    }
  }

  return (
    <div className="category-filter-tabs">
      <div
        className={cx("category-filter-tabs__tab", {
          "category-filter-tabs__tab--open": open,
        })}
      >
        <div
          className="category-filter-tabs__tab-header order-tabs__tab--open"
          onClick={e => {
            setOpen(!open)
            toggleTab(e.currentTarget, e.currentTarget.nextElementSibling)
          }}
        >
          <div className="category-filter-tabs__tab-header--left">
            <p>Filter</p>
          </div>
          <div className="category-filter-tabs__tab__arrow">
            <SvgIcon name="add" />
          </div>
        </div>
        <div className="category-filter-tabs__tab-content">
          {data &&
            data.pages.map((page, index) => {
              return (
                <div
                  className="category-filter-tabs__tab-content--item"
                  key={index}
                >
                  <a href={page.link.url}>{page.title}</a>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default CategoryFilter
