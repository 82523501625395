import { useMemo } from "react"
import { useQuery } from "@tanstack/react-query"

const useLispcorereviews = queryObj => {
  const params = Object.entries(queryObj)
    .filter(entry => entry[1])
    .map(entry => `${entry[0]}=${entry[1]}`)
    .join("&")
  const { data: lipscoreReviews } = useQuery({
    queryKey: ["lipscore-reviews", queryObj.limit, queryObj.internal_id],
    queryFn: () =>
      fetch(
        // .netlify/functions
        `/.netlify/functions/lipscore-reviews${params ? `?${params}` : ""}`
      ).then(res => res.json()),
  })

  const lipscoreReviewsCountString = useMemo(() => {
    if (
      lipscoreReviews &&
      Array.isArray(lipscoreReviews) &&
      lipscoreReviews.length
    ) {
      return `${lipscoreReviews.length}`
    }
    return "--"
  }, [lipscoreReviews])

  const lipscoreStars = useMemo(() => {
    let sum = 0
    if (
      lipscoreReviews &&
      Array.isArray(lipscoreReviews) &&
      lipscoreReviews.length
    ) {
      sum = lipscoreReviews.reduce((acc, curr) => {
        acc += curr.rating
        return acc
      }, 0)
      return sum / lipscoreReviews.length
    }
    return sum
  }, [lipscoreReviews])

  const lipscoreRatingNumber = useMemo(() => {
    return lipscoreStars % 1 ? lipscoreStars.toFixed(1) : lipscoreStars
  }, [lipscoreStars])

  return {
    lipscoreReviews,
    lipscoreStars,
    lipscoreReviewsCountString,
    lipscoreRatingNumber,
  }
}

export default useLispcorereviews
