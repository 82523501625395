// Dependency
import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Button from "../../ui/button/button"

// Hooks
import useMediaQuery from "../../../hooks/useMediaQuery"

// Styles
import "./listing.scss"
import ProductCard2 from "../../product/product-card-variant-2/product-card-variant-2"
import { useTranslation } from "react-i18next"
import Breadcrumbs from "../breadcrumbs/breadcrumbs"
import cx from "classnames"
import GA4 from "../../../services/tracking"
import { useBrink } from "../../../context/BrinkContext"
import CategoryFilter from "../category-filter/category-filter"

// Template
const Listing = props => {
  const data = useStaticQuery(graphql`
    query {
      brink {
        searchProducts(query: "{\"size\":1000,\"query\":{\"query_string\":{\"query\":\"type:productVariant\"}}}") {
          products {
            id
            name
            category
            active
            archived
            imageUrl
            price {
              amount
              currencyUnit
            }
            discount {
              amount
              currencyUnit
            }
            relatedProduct {
              id
            }
            relatedVariants {
              id
            }
          }
        }
      }
    }
  `)

  const brinkProducts = data.brink.searchProducts.products.filter(
    p => p.active && !p.archived
  )


  const { t } = useTranslation()
  const { store } = useBrink()
  const isTabletOrMobile = useMediaQuery(767)
  let gridClasses
  const componentProps = props.blok
  const originalProducts =
    componentProps.columns.find(i => i.component === "product-list").products ??
    []

  if (componentProps.numberOfColumns === "three") {
    gridClasses = "grid__col-xlarge-4 grid__col-large-4 grid__col-small-1"
  } else if (componentProps.numberOfColumns === "four") {
    gridClasses = "grid__col-xlarge-3 grid__col-large-4 grid__col-small-1"
  } else if (componentProps.numberOfColumns === "two") {
    gridClasses = "grid__col-xlarge-6 grid__col-large-4 grid__col-small-1"
  }

  const products = originalProducts

  const [count, setCount] = useState(isTabletOrMobile ? 32 : 32)
  const [productData, setProductData] = useState(products.slice(0, count))
  const [viewedProducts, setViewedProducts] = useState([])

  const message = productData.length <= 0 ? t("listing.noresults") : ""

  // show more bar width
  const productsBarWidth = {
    width: `${(count * 100) / products.length}%`,
  }

  // grid classes for product cards
  if (componentProps.numberOfColumns === "three") {
    gridClasses = "grid__col-xlarge-4 grid__col-large-4 grid__col-small-1"
  } else if (componentProps.numberOfColumns === "four") {
    gridClasses = "grid__col-xlarge-3 grid__col-large-4 grid__col-small-1"
  } else if (componentProps.numberOfColumns === "two") {
    gridClasses = "grid__col-xlarge-6 grid__col-large-4 grid__col-small-1"
  }

  // show more button data
  const showMore = count => {
    setCount(count + 8)
    setProductData(products.slice(0, count + 8))
  }

  useEffect(() => {
    const prods = []
    const ids = []
    productData.map((prod, index) => {
      const brinkProduct =
        brinkProducts.find(brinkProd => brinkProd.id === prod.content.sku) ||
        null

      if (brinkProduct && !viewedProducts.includes(brinkProduct.id)) {
        brinkProduct.index = index
        prods.push(brinkProduct)
        ids.push(brinkProduct.id)
      }
    })

    if (prods.length > 0) {
      GA4.viewItemsList(prods, {
        listName: componentProps.location
          ? componentProps.location.pathname.substring(1)
          : "SHOP ALL",
        currencyUnit: store.currencyUnit,
      })

      setViewedProducts(viewedProducts.concat(ids))
    }
  }, [productData])

  // breadcrumbs links
  const links = [{ title: t("listing.breadCrumbsProducts"), link: "products" }]

  return (
    <section className="listing" id="listing">
      <div className="listing__breadcrumbs">
        <div className="grid-container">
          <div className="grid__row">
            <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
              <Breadcrumbs
                breadTitle={componentProps.title}
                links={links}
                large={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="listing__filter">
        <div className="grid-container">
          <div className="grid__row">
            <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
              <div className="listing__filter-inner">
                <div className="listing__amount">
                  <p>
                    {t("listing.showing")} {productData.length}{" "}
                    {t("listing.products")}
                  </p>
                </div>
                <CategoryFilter categoryName={componentProps.title} />

                {/* {urlSlug !== "spraytan-kit" &&
                  urlSlug !== "refill" &&
                  urlSlug !== "mousse" &&
                  urlSlug !== "skincare" && (
                    <div className="listing__filter-left">
                      <ReactSelect
                        defaultValue={categoryOptions[0]}
                        options={categoryOptions}
                        noBorder={true}
                        id={"filter"}
                        type="filter"
                        handleChange={handleChangeCategory}
                      />
                    </div>
                  )} */}
                {/* <div className="listing__filter-right">
                  <ReactSelect
                    defaultValue={options2[0]}
                    options={options2}
                    noBorder={true}
                    id={"sorting"}
                    type="filter"
                    handleChange={handleChangeSorting}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={cx("grid-container", {
          "grid-container--no-padding-small": isTabletOrMobile,
        })}
      >
        <div className="grid__row">
          <div className="grid__flex">
            {message}
            {productData.map((product, index) => {
              const brinkProduct =
                brinkProducts.find(
                  brinkProd => brinkProd.id === product.content.sku
                ) || null
              return (
                <div key={index} className={`listing__products ${gridClasses}`}>
                  <ProductCard2
                    props={product}
                    index={index}
                    brinkProduct={brinkProduct}
                    bgColor={componentProps.bgColor}
                    testFreakReviews={product.content.sku}
                    listing={true}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {count < products.length && (
        <div className="listing__show-more-data">
          <div className="listing__show-more-button">
            <Button
              buttonText={t("product.showMore")}
              onClick={() => showMore(count)}
              outlined={true}
            />
          </div>
          <div className="listing__progressBar">
            <div className="listing__bar" style={productsBarWidth}></div>
          </div>
          <div className="listing__show-more-count">
            <span>{t("product.products")}</span>
            <span className="listing__count">
              {count}/{products.length}
            </span>
          </div>
        </div>
      )}
    </section>
  )
}

export default Listing
