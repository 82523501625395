import React, { useContext, createContext, useState } from "react"

const defaultState = {
  getModal: false,
  hideModal: false,
  modalValue: null,
  setModalValue: false,
}

const ModalContext = createContext(defaultState)

const Modals = {}

const ModalContextProvider = ({ children }) => {
  const [activeModal, setActiveModal] = useState({
    component: null,
    props: {},
  })

  const [modalValue, setModalValue] = useState(null)

  const getModal = (modal, props) => {
    //const Modal = Modals[modal];
    setActiveModal({ component: modal, props: props })
    //return (<Modal isOpen={open} />)
  }

  const showModal = (modal, props) => {
    const Modal = Modals[modal]
    return <Modal {...props} />
  }

  const hideModal = () => {
    setActiveModal({ component: null, props: {} })
  }

  const contextObjects = {
    getModal,
    hideModal,
    modalValue,
    setModalValue,
  }

  return (
    <ModalContext.Provider value={contextObjects}>
      {activeModal.component
        ? showModal(activeModal.component, activeModal.props)
        : null}
      {children}
    </ModalContext.Provider>
  )
}

export default ModalContextProvider

export const useModal = () => useContext(ModalContext)
