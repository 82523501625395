//Dependencies
import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  Mousewheel,
  Scrollbar,
  Pagination,
  Navigation,
} from "swiper"
import SvgIcon from "../../../hooks/svg"
import { useTranslation } from "react-i18next"

//Styles
import "swiper/css"
import "swiper/css/mousewheel"
import "swiper/css/scrollbar"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "./swiper-carousel.scss"

//Components
import Image from "../../ui/image/image"
import Breadcrumbs from "../breadcrumbs/breadcrumbs"

SwiperCore.use([Mousewheel, Scrollbar])

const SwiperCarousel = props => {
  const { t } = useTranslation()


  return (
    <div className="swiper-carousel swiper-carousel-gallery">
      <Swiper
        className="swiper-carousel__swiper"
        pagination={{ type: "fraction" }}
        navigation={{
          nextEl: ".swiper-carousel-next",
          prevEl: ".swiper-carousel-prev",
        }}
        modules={[Pagination, Navigation]}
        loop={true}
        direction={"horizontal"}
        mousewheel={{
          releaseOnEdges: true,
          forceToAxis: true,
        }}
        slidesPerView={1}
        slidesPerGroup={1}
        touchReleaseOnEdges={true}
        grabCursor={true}
        scrollbar={{
          hide: false,
          dragSize: "auto",
          draggable: true,
        }}
      >
        {/* <div className="info-card__breadcrumbs">
          <Breadcrumbs breadTitle={props.breadcrumbs.title} links={links} />
        </div> */}
        {props.gallery.slides.map((slide, index) => (
          <SwiperSlide className="swiper-carousel__swiper-slide" key={index}>
            <div
              className="swiper-carousel__swiper-slide__image image__wrapper"
            >
              <Image
                largeScreenImage={slide.image.filename}
                largeScreenHeight={"auto"}
                largeScreenWidth={"auto"}
                smallScreenImage={slide.image.filename}
                smallScreenHeight={"auto"}
                smallScreenWidth={"auto"}
                alt={slide.image.alt}
                layout={"constrained"}
                loading="eager"
              />
            </div>
          </SwiperSlide>
        ))}
        {/* <div className="swiper-carousel__swiper--arrows swiper-carousel-next">
          <SvgIcon
            name="arrow-right"
            height="23"
            className="swiper-carousel-arrow"
          />
        </div>
        <div className="swiper-carousel__swiper--arrows swiper-carousel-prev">
          <SvgIcon
            name="arrow-left"
            height="23"
            className="swiper-carousel-arrow"
          />
        </div> */}
      </Swiper>
    </div>
  )
}

export default SwiperCarousel
