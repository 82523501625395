// Dependency
import React from "react"
import SwiperCarousel from "../../general/swiper-carousel/swiper-carousel"
import InfoCard from "../../general/info-card/info-card"
import NotifyMeModal from "../../notify-me-modal/notify-me-modal"
import { useState } from "react"


// CSS
import "./product-carousel-info.scss"

// Template
const ProductCarouselInfo = props => {
  const carousel = props.gallery
  const infoCard = props.infoCard

  const [openNotifyMe, setOpenNotifyMe] = useState(false)

  const toggleNotifyMe = () => {
    setOpenNotifyMe(!openNotifyMe)
  }

  console.log("props", props.productdescription)

  return (
    <section className="product-carousel-info">

            <div className="product-carousel-info__wrapper">
              <SwiperCarousel
                breadcrumbs={infoCard}
                gallery={carousel}
                bgColor={props.bgColor}
              />
              <div className="product-carousel-info__info-card">
               
                <InfoCard
                  toggleNotifyMe={toggleNotifyMe}
                  props={infoCard}
                  brink={props.brink}
                  productDescription={props.productdescription}
                />
              </div>
          </div>
     
      <NotifyMeModal
        openNotifyMe={openNotifyMe}
        toggleNotifyMe={toggleNotifyMe}
        selectedVariant={props.brink.getProduct.id}
      />
    </section>
  )
}
export default ProductCarouselInfo
