import React, { useState } from "react"
import RichTextResolver from "../../../helpers/storyblok/richTextResolver"
import cx from "classnames"
import { useTranslation } from "react-i18next"

//Styles
import "./seo-text-block.scss"

const SeoTextBlock = props => {
  const componentProps = props.blok ? props.blok : props.prop

  const [textOpen, setTextOpen] = useState(false)

  const toggleText = () => {
    setTextOpen(!textOpen)
  }
  const { t } = useTranslation()
  return (
    <div className="seo-text-block">
      <div className="seo-text-block__container">
        <div className="grid-container">
          <div className="seo-text-block__content">
            <div className="seo-text-block__content--heading">
              <h2>{componentProps.title}</h2>
            </div>
            <div
              className={cx("seo-text-block__content--text", {
                "seo-text-block__content--text-open": textOpen,
              })}
            >
              <RichTextResolver data={componentProps.text} />
            </div>
            <div className="seo-text-block__content--show-more">
              <a
                className="seo-text-block__content--show-more__link"
                href="#"
                onClick={e => {
                  e.preventDefault()
                  toggleText()
                }}
              >
                {t("textBlock.readMore")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SeoTextBlock
