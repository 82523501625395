// Dependency
import React from "react"
import cx from "classnames"
import LanguageSwitcher from "../../language-switcher/language-switcher"

// Styles
import "./footer-language.scss"
import SvgIcon from "../../../../hooks/svg"
import useMediaQuery from "../../../../hooks/useMediaQuery"

// Template
const FooterLanguage = props => {
  const isTabletOrMobile = useMediaQuery(1024)

  return (
    <div className={cx("footer-language-container")}>
      {isTabletOrMobile ? (
        <div className="footer-text-social-media__logos">
          {props.footerLogos.logos.map((i, index) => {
            return (
              <div className="footer-text-social-media__logos-logo" key={index}>
                <SvgIcon name={i.logos} />
              </div>
            )
          })}
        </div>
      ) : null}
      <div className="footer-language">
        <div className="footer-language__heading">
          {props.footerLanguageSwitcher.heading}
        </div>
        <LanguageSwitcher id={props.id || "footer-dropdown"} footer={true} />
      </div>
    </div>
  )
}

export default FooterLanguage
