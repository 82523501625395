import React from "react"
import SvgIcon from "../../hooks/svg"
import cx from "classnames"

//Styles
import "./results-usp.scss"

const ResultsUsp = props => {
  const componentProps = props.blok ? props.blok : props.prop

  return (
    <div
      className={cx(
        "results-usp",
        {
          "padding-top": componentProps.paddingTop,
        },
        {
          "padding-bottom": componentProps.paddingBottom,
        }
      )}
    >
      <div className="grid-container">
        <div className="results-usp__content">
          <div className="results-usp__content--usp">
            {componentProps.items.map((item, index) => {
              return (
                <div key={index} className="results-usp__content--usp-item">
                  <SvgIcon name="check" height="10" />
                  <p>{item.text}</p>
                </div>
              )
            })}
          </div>

          <div className="results-usp__content--btn">
            <a className="link link--button-black" href="/products">
              {componentProps.buttonText}{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResultsUsp
