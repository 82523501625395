// Dependency
import React from "react"

import RenderLink from "../../ui/link/link"

// Styles
import "./text-block.scss"
import cx from "classnames"
import { storyblokEditable } from "gatsby-source-storyblok"

// Hooks
import RichTextResolver from "../../../helpers/storyblok/richTextResolver"

// Template
const TextBlock = props => {
  const componentProps = props.blok ? props.blok : props.props

  const renderContent = () => {
    return (
      <>
        <div
          className="text-block__heading"
          {...storyblokEditable(componentProps.content)}
          key={componentProps._uid}
        >
          <h1>{componentProps.heading}</h1>
        </div>
        <RichTextResolver
          data={componentProps.description}
          styleClass="text-block__description"
        />
        <div className="text-block__button">
          {componentProps.button &&
            componentProps.button.url &&
            !componentProps.link && (
              <RenderLink
                linkText={componentProps.buttonText}
                linkUrl={`${componentProps.button.url}`}
                buttonLook="black"
              />
            )}
          {componentProps.button &&
            componentProps.button.url &&
            componentProps.link && (
              <RenderLink
                linkText={componentProps.buttonText}
                linkUrl={`${componentProps.button.url}`}
              />
            )}
        </div>
      </>
    )
  }
  return (
    <section
      className={cx("text-block", {
        "text-block--center": componentProps.centerAlignedText,
      },
      {
        "padding-top":
        componentProps.paddingTop ,
      },
      {
        "padding-bottom":
        componentProps.paddingBottom ,
      }
      )}
      id="text-block"
    >
      {componentProps.fullWidth && (
        <div className="grid-container">
          <div className="grid__row">
            <div className="grid__col-xlarge-shift-3 grid__col-xlarge-6 grid__col-large-shift-2 grid__col-large-8 grid__col-medium-9 grid__col-small-2">
              {renderContent()}
            </div>
          </div>
        </div>
      )}
      {!componentProps.fullWidth && renderContent()}
    </section>
  )
}
export default TextBlock
