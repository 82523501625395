// Dependency
import React from "react"

// Template
const SvgIcon = props => {
  const componentProps = props.blok ? props.blok : props

  return (
    <img
      src={
        componentProps.src
          ? componentProps.src
          : require(`../assets/icons/${componentProps.name}.svg`).default
      }
      alt={componentProps.name}
      height={componentProps.height}
      className={componentProps.className}
    />
  )
}
export default SvgIcon
