//Dependencies
import React from "react"

//Style
import "./text-on-image-color-block.scss"

//Hooks
import Image from "../../ui/image/image"

const TextOnImageColorBlock = props => {
  const componentProps = props.blok ? props.blok : props.props

  return (
    <div className="text-on-image-color-block">
      <div className="text-on-image-color-block__image image__wrapper">
        <Image
          largeScreenImage={componentProps.largeScreenImage.filename}
          largeScreenHeight={1000}
          largeScreenWidth={1400}
          smallScreenImage={componentProps.smallScreenImage.filename}
          smallScreenHeight={500}
          smallScreenWidth={900}
          alt={componentProps.largeScreenImage.alt}
          layout={"constrained"}
        />
      </div>
      <div className="text-on-image-color-block__inner">
        <h2 className="text-on-image-color-block__title">
          {componentProps && componentProps.title}
        </h2>
        <div className="text-on-image-color-block__content">
          {componentProps && componentProps.description}
        </div>
      </div>
    </div>
  )
}

export default TextOnImageColorBlock
