// Dependency
import React from "react"
import Image from "../../../ui/image/image"
import cx from "classnames"
import { useState } from "react"
import SvgIcon from "../../../../hooks/svg"

// Styles
import "./navbar.scss"

// Components

// Hooks
import useMediaQuery from "../../../../hooks/useMediaQuery"
import RenderLink from "../../../ui/link/link"
import { Link } from "gatsby"

// Template
const Navbar = props => {
  const componentProps = props.blok
    ? props.blok
    : props.props
    ? props.props
    : props
  const isTabletOrMobile = useMediaQuery(1024)

  // Keep state of MegaNav
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const openMeganav = event => {
    setDropdownOpen(true)
  }
  const closeMeganav = () => {
    setDropdownOpen(false)
  }

  return (
    <div className="navbar">
      {!componentProps.isCheckout &&
        !isTabletOrMobile &&
        componentProps.menu &&
        componentProps.menu[0].navLinks &&
        componentProps.menu[0].navLinks.map((link, i) => {
          return (
            <div
              className="navbar__link-wrapper"
              onClick={closeMeganav}
              onMouseEnter={link.showMeganav ? openMeganav : null}
              onMouseLeave={closeMeganav}
              key={i}
            >
              <RenderLink
                linkText={link.linkText}
                linkUrl={link.link.url}
                className="navbar__link"
              />
              {link.showMeganav ? (
                <SvgIcon name="small-simple-arrow-down" alt="arrow down" />
              ) : (
                ""
              )}

              <div
                onMouseEnter={openMeganav}
                className={cx("meganav ", { "meganav--open": dropdownOpen })}
              >
                <div className="meganav__left">
                  {componentProps.meganav &&
                    componentProps.meganav.products.map((product, i) => {
                      const image = product.content?.gallery?.find(
                        component => component.component === "carousel"
                      ).slides[0].image
                      return (
                        <Link
                          to={`/product/${product.slug}`}
                          key={i}
                          className="meganav__product"
                        >
                          <div className="meganav__product__img image__wrapper">
                            <Image
                              largeScreenImage={image.filename}
                              largeScreenHeight={"auto"}
                              largeScreenWidth={1000}
                              smallScreenImage={image.filename}
                              smallScreenHeight={"auto"}
                              smallScreenWidth={1000}
                              alt={image.alt}
                              layout={"constrained"}
                            />
                          </div>
                          <div className="meganav__product__content">
                            <h4>{product.name}</h4>
                          </div>
                        </Link>
                      )
                    })}
                </div>
                <div className="meganav__right">
                  {componentProps.meganav &&
                    componentProps.meganav.links.map((link, i) => {
                      return (
                        <Link to={link.link.url} key={i}>
                          {link.linkText}
                        </Link>
                      )
                    })}
                </div>
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default Navbar
