// Dependency
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import cx from "classnames"

// Styles
import "./footer.scss"

// Hooks
import useMediaQuery from "../../../hooks/useMediaQuery"
import FooterForm from "./footer-form/footer-form"
import FooterTextSocialMedia from "./footer-text-social-media/footer-text-social-media"
import FooterLinks from "./footer-links/footer-links"
import FooterLanguage from "./footer-language/footer-language"
import ReviewBlock from "../review-block/review-block"
import SvgIcon from "../../../hooks/svg"

// Template
const Footer = props => {
  const isTabletOrMobile = useMediaQuery(1024)
  const query = useStaticQuery(graphql`
    query {
      story: allStoryblokEntry(
        filter: { field_component: { eq: "footer" } }
        sort: { fields: name }
      ) {
        nodes {
          name
          slug
          content
          full_slug
        }
      }
    }
  `)

  const filteredData =
    query.story.nodes.find(node =>
      node.full_slug.includes(process.env.GATSBY_BUILD_LANGUAGE) && node.full_slug.includes('new-site')
    ) ?? query.story.nodes[0]
  const data = JSON.parse(filteredData.content)
  const form = data.sections.find(i => i.component === "form")
  const socialMedia = data.sections.find(i => i.component === "social-media")
  const aboutUs = data.sections.find(i => i.component === "footer-about-us")
  const footerLogos = data.sections.find(i => i.component === "footer-logos")
  const footerLinks = data.sections.find(i => i.component === "footer-links")
  const reviewBlock = data.sections.find(i => i.component === "review-block")

  const footerLanguageSwitcher = data.sections.find(
    i => i.component === "footer-language-switcher"
  )
  const footerLogoMain = data.sections.find(
    i => i.component === "footer-logo-main"
  )

  return (

    <>
      <div className={'grid-container'} style={props.isCheckout ? { display: 'none' } : {}}>
        <div className="grid__row">
          <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
            <FooterForm
              heading={form.heading}
              helperText={form.helperText}
              caption={form.caption}
            />
          </div>
        </div>
      </div>
      <div
        className={cx("footer", {
          "footer--no-border": props.noBorder,
          "footer--hide": props.isCheckout,
        })}
        id="footer"
      >
        <div className="grid-container">
          <div className="grid__row">
            <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
              {/* {props.showNewsletter && ( */}

              {/* )} */}
              <div className="footer__content">
                <FooterTextSocialMedia
                  aboutUs={aboutUs}
                  socialMedia={socialMedia}
                  footerLogos={footerLogos}
                />

                {props.showAccordion && (
                  <FooterLinks
                    isTabletOrMobile={isTabletOrMobile}
                    footerLinks={footerLinks}
                  />
                )}
                <FooterLanguage
                  footerLogos={footerLogos}
                  footerLanguageSwitcher={footerLanguageSwitcher}
                  id={props.id}
                />
              </div>
            </div>
          </div>
        </div>
        {!props.mobileMenu &&
          footerLogoMain &&
          (footerLogoMain.largeScreenImage.filename || footerLogoMain.logo) && (
            <div
              className={cx("footer__banner", {
                "footer__banner__white-bg": false,
              })}
            >
              <SvgIcon
                src={
                  footerLogoMain.largeScreenImage
                    ? footerLogoMain.largeScreenImage.filename
                    : undefined
                }
                name={footerLogoMain.logo}
              />
            </div>
          )}
        {reviewBlock && <ReviewBlock blok={reviewBlock} />}
      </div>
    </>

  )
}

export default Footer
