import React from "react"
import useTestFreak from "../../../hooks/useTestFreak"

const TestFreak = props => {
  const componentProps = props.blok ? props.blok : props.props
  useTestFreak({ type: componentProps.type, productId: componentProps.productId })

  return (
    <>
      {componentProps && (
        <div>
          {componentProps.title ? <h3>{componentProps.title}</h3> : null}
          {componentProps.type === 'reviews' ? <div id="testfreaks-reviews" /> : <div id="testfreaks-badge"></div>}
        </div>
      )}
    </>
  )
}
export default TestFreak
