// Dependency
import React from "react";
import { Link } from "gatsby"

// Styles
import './marquee.scss'

const Marquee = (props) => {

    const mapDuplicate = [1, 2, 3, 4, 5, 6];

    return (
        <div className="marquee" style={{ background: props.marquee.background }}>
            <div className="marquee__wrapper">
                <div className="marquee__wrapper__content">
                    {mapDuplicate.map((a, i) => {
                        return (
                            <ul key={i} className="marquee__wrapper__content__list" style={{ color: props.marquee.color }} >
                                {props.marquee.perks && props.marquee.perks.map((perk, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <li>
                                                {perk.link ?
                                                    <Link to={perk.link} >
                                                        {perk.text}
                                                    </Link>
                                                : 
                                                    perk.text
                                                }    
                                            </li>
                                            <li>-</li>
                                        </React.Fragment>
                                    )
                                })}
                            </ul>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Marquee
