//Dependencies
import React, { useState, useEffect } from "react"

//Styles
import "./textfield.scss"
import cx from "classnames"

const Textfield = props => {
  const [isActive, setIsActive] = useState(false)
  const [inputValue, setInputValue] = useState(props.value)

  useEffect(() => {
    setInputValue(inputValue)
    setIsActive(!!inputValue)
  }, [inputValue])

  const checkFilled = value => {
    setInputValue(value)
    setIsActive(!!value)
  }

  return (
    <div
      className={cx("textfield", {
        "textfield--error": props.error,
      })}
    >
      <input
        name={props.name}
        className="textfield__input"
        type={props.type}
        value={inputValue || ""}
        onChange={e => [props.onChange(e), checkFilled(e.target.value)]}
        required={props.required}
        aria-labelledby={props.name}
        disabled={props.disabled || false}
      />
      <label
        id={props.name}
        className={cx("textfield__label", {
          "textfield__label--active": isActive,
          "textfield__label--hide": isActive || props.hideLabel,
        })}
      >
        {props.label}
      </label>
      {props.errorMessage && (
        <div className="textfield__error-message">{props.errorMessage}</div>
      )}
    </div>
  )
}

export default Textfield
