import axios from "axios"
import { useEffect, useState } from "react"

const useTestFreakApi = productId => {
  const [reviewData, setReviewData] = useState(null)

  useEffect(() => {
    const fetchTestFreakApi = async () => {
      const res = await axios.get(
        `https://js.testfreaks.com/onpage/tanrevel.com-sv/reviews.json?key=${productId}`
      )
      setReviewData(res.data)
    }

    fetchTestFreakApi()
  }, [])

  return reviewData
}

export default useTestFreakApi
