// Dependency
import React from "react"
import { Link } from "gatsby"
import SvgIcon from "../../../hooks/svg"

// Styles
import "./link.scss"
import cx from "classnames"

// Template
const RenderLink = props => {
  const componentProps = props.props ? props.props : props
  const iconName =
    componentProps.icon &&
    componentProps.icon.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase()

  return (
    <>
      {!componentProps.linkUrl.includes("https") && (
        <Link
        style={{color: componentProps.color, background: componentProps.backgroundColor}}
          className={cx(
            "link",
            componentProps.className,
            {
              "link--animated-border-1": componentProps.hoverEffect === "anim1",
            },
            {
              "link--animated-border-2": componentProps.hoverEffect === "anim2",
            },
            { "link--shadow": componentProps.hoverEffect === "shadow" },
            { "link--bold": componentProps.textType === "bold" },
            { "link--button-subtle": componentProps.buttonLook === "subtle" },
            { "link--button-green": componentProps.buttonLook === "green" },
            { "link--button-black": componentProps.buttonLook === "black" },
            { "link--icon-left": componentProps.iconLeft }
          )}
          onClick={componentProps.onClick}
          to={componentProps.linkUrl}
        >
          {componentProps.linkText}
          {componentProps.icon && (
            <SvgIcon name={iconName} className="link__icon" />
          )}
          {componentProps.asset && (
            <SvgIcon name={componentProps.asset} className="link__svg" />
          )}
        </Link>
      )}
      {componentProps.linkUrl.includes("https") && (
        <a target="_blank" rel="noreferrer" className={componentProps.className} href={componentProps.linkUrl}>
          {componentProps.linkText}
        </a>
      )}
    </>
  )
}
export default RenderLink
