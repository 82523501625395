import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

// Translations
//import resources from "./translations"

const lang = process.env.GATSBY_BUILD_LANGUAGE === 'ch' ? 'de' : process.env.GATSBY_BUILD_LANGUAGE

export function i18config(resources) {
  //console.log(resources)
  i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      fallbackLng: "en",
      resources: resources,
      saveMissing: false,
      lng: lang, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    })

  // Detect language from URL locale
  //const activeLocale = UtilsService.getLocaleFromUrl().country;
  // Chenage active language tu current locale
}
