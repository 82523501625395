//Dependencies
import React from "react"
import cx from "classnames"
import useMediaQuery from "../../../hooks/useMediaQuery"

// Styles
import "./usp-section.scss"

const UspSection = props => {
  const isMobile = useMediaQuery(768)

  return (
    <div className="usp-section">
      <div className="grid-container usp-section">
        {(props.uspSection || props.blok).items.map((item, index) => (
          <div
            key={index}
            className={cx("usp-section__item", {
              "usp-section__item__hidden": isMobile,
            })}
          >
            <p>{item.title}</p>
          </div>
        ))}
        {(props.uspSection || props.blok).mobileItems.map((item, index) => (
          <div
            key={index}
            className={cx("usp-section__item", {
              "usp-section__item__hidden": !isMobile,
            })}
          >
            <p>{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default UspSection
